import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { forkJoin, lastValueFrom, Observable } from 'rxjs';
import { ImageUploadResponse } from '../../models';
import { base64ToFile } from 'ngx-image-cropper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UtilitiesService } from '@core/services';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  constructor(
    private http: HttpClient,
    private imageCompress: NgxImageCompressService,
    private utilitiesService: UtilitiesService
  ) {}

  /**
   * makes api request and uploads image
   * @param image File
   * @param imageId string
   * @param imageType string
   * @returns
   */
  public upload(
    image: File,
    imageId: string,
    imageType: string
  ): Observable<ImageUploadResponse> {
    const formData = new FormData();
    // The file must be always last in order
    formData.append('image_id', imageId);
    formData.append('image_type', imageType);
    formData.append('image', image);
    return this.http.post<ImageUploadResponse>(
      `${environment.restApiUrl}files/uploadfiletofolder`,
      formData
    );
  }

  /**
   * iterates over question json and when finds image key in object calls uploadImages function otherwise uses recursion for finding any image keys in object
   * @param questionJson object
   * @returns Promise<any>
   */
  public async iterateOverQuestionJSON(questionJson: object): Promise<any> {
    if (!questionJson) {
      return questionJson;
    }

    const json = { ...questionJson };
    for (const key in json) {
      if (Array.isArray(json[key])) {
        json[key] = await this.uploadImagesArray(json[key]);
      } else if (typeof json[key] === 'object') {
        json[key] = await this.iterateOverQuestionJSON(json[key]);
      } else if (
        typeof json[key] === 'string' &&
        (key === 'image' || key === 'answer_image')
      ) {
        json[key] = await this.uploadImages(json[key]);
      }
    }
    return json;
  }

  /**
   * loops through array and either calls iterateOverQuestionJSON function or uploadImages function
   * @param arr any[]
   * @returns Promise<any[]>
   */
  private async uploadImagesArray(arr: any[]): Promise<any[]> {
    const a = arr.map(async (item) => {
      if (typeof item === 'object' && item?.image) {
        item.image = await this.uploadImages(item.image);
      } else {
        item = await this.iterateOverQuestionJSON(item);
      }
      return item;
    });
    return await lastValueFrom(forkJoin(a));
  }

  public base64ToFile(base64: string): File {
    const imageType = this.utilitiesService.extractImageTypeFromBase64(base64);
    const blob = base64ToFile(base64);
    return new File([blob], `${Date.now()}.${imageType}`);
  }

  /**
   * extracts out image type from base64 string then makes file from blob and calls upload image function for api request
   * @param base64 string
   * @returns Promise<string>
   */
  public async uploadImages(base64: string = ''): Promise<string> {
    if (!base64 || !base64.startsWith('data:image/')) {
      return base64;
    }
    const imageType = this.utilitiesService.extractImageTypeFromBase64(base64);
    const file = this.base64ToFile(base64);
    const result = await lastValueFrom(
      this.upload(file, Date.now().toString(), imageType)
    );
    return result.media_url;
  }

  /**
   * compresses base64 string and returns compressed base64 string
   * @param file File
   * @param base64 string
   * @returns Promise<string>
   */
  public async compressFile(file: File, base64: string): Promise<string> {
    const orientation = await this.imageCompress.getOrientation(file);
    return await this.imageCompress.compressFile(base64, orientation, 50, 50); // 50% ratio, 50% quality
  }
}
