import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GameScheduledDateQuery,
  GameThemesQuery,
  Game_Themes,
  Trivia_Game_Templates,
} from '@core/generated-gql/graphql';
import { DoAction, GameByIdResponse } from '@core/models';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetGamesService } from './get-games.service';

@Injectable({ providedIn: 'root' })
export class GameDataService {
  constructor(
    private getGamesService: GetGamesService,
    private http: HttpClient
  ) {}

  public getGameScheduledDate(
    game_id: string
  ): Observable<GameScheduledDateQuery['trivia_events'][0]> {
    const body: DoAction<{ game_id: string }> = {
      do_action: 'f_game_scheduled_date',
      data: { game_id },
    };
    return this.http.post<GameScheduledDateQuery['trivia_events'][0]>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public getGameThemes(): Observable<Game_Themes[]> {
    const body: DoAction<{}> = {
      do_action: 'f_get_game_themes_list',
      data: {},
    };
    return this.http
      .post<Game_Themes[]>(`${environment.dctapiUrl}`, body)
      .pipe(
        map((game_themes) =>
          [...game_themes].sort((a, b) => a.name.localeCompare(b.name))
        )
      );
  }

  public gameTemplates(): Observable<Trivia_Game_Templates[]> {
    const body: DoAction<{}> = {
      data: {},
      do_action: 'f_trivia_game_template_list'
    }
    return this.http.post<Trivia_Game_Templates[]>(`${environment.dctapiUrl}`, body)
  }

  public gameByPk(gameId: string): Observable<GameByIdResponse> {
    return this.getGamesService.getGameById(gameId);
  }
}
