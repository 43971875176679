export class ValidationConstants {
  static readonly EMAIL_REGEXP = /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  static readonly NAME_REGEXP = /\s*(?:[\w\S+\.]\s*){2,}$/;
  static readonly PERCENT_REGEXP = /^(0|0\.\d+|[1-9]{1,3}(\d*)(\.\d+)?)$/;
  static readonly NUMBERS_REGEXP = /^(0|0,[0-9]+[1-9]+)$|(\s*^[1-9][0-9]*(?:[,.]\d+)*?\s*$)$/;
  static readonly NO_WHITE_SPACE = /\S+/;
  static readonly NO_SPACE = /^(?!.*\s).*/;
  static readonly PHONE_NUMBER = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  static readonly TWO_COLUMN_FIELDS_COUNT = 26;
  static readonly questionTextMaxLength = 250;
  static readonly questionExplanationMaxLength = 300;
  static readonly questionHintMaxLength = 300;
  static readonly questionAnswerMaxLength = 75;
  static readonly rangeAnswerMaxLength = 16;
  static readonly formArrayLength = 25;
  static readonly titleLength = 50;
  static readonly textLength = 100;
  static readonly triviaUserFirstNameMaxLength = 40;
  static readonly triviaUserLastNameMaxLength = 40;
  static readonly passwordMaxLength = 20;
  static readonly passwordMinLength = 5;
  static readonly pointDivisionNumer = 20;
  static readonly reportQuestionCommentMaxLength = 250;
  static readonly keywordsMaxLength = 100;
  static readonly gameDescriptionMaxLength = 100;
}
