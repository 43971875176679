export class ObjectHelper {

  public static isObject(o: any): boolean {
    return o !== null && typeof o === 'object' && !Array.isArray(o);
  }

  /**
   * loops through question json object and trims spaces from values
   * @param obj object
   * @returns object
   */
  public static trimObjectValues<T>(obj: any): any {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (this.isObject(obj[key])) {
          obj[key] = this.trimObjectValues(obj[key]);
        } else if (Array.isArray(obj[key])) {
          obj[key] = obj[key].map((e: any) => this.trimObjectValues(e));
        } else if (typeof obj[key] === 'string') {
          obj[key] = obj[key] ? obj[key].toString().trim() : obj[key];;
        }
      }
    }
    return obj;
  }

  public static getEnumKeyByValue<T>(en: T, value: string): any {
    const indexOfS = Object.values(en).indexOf(value);
    return Object.keys(en)[indexOfS];
  }
}
