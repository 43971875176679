import { Injectable } from '@angular/core';
import { RoundQuestionState } from '@models/compiler/round-question-state.interface';
import {
  FillRoundQuestionQuery,
  GenerateWarfareRoundQuestionsQuery,
  GenRound,
  QuestionsData,
} from '@gql/graphql';
import { catchError, map, tap } from 'rxjs/operators';
import { MatSnackbarService } from '@services/mat-snakbar/mat-snakbar.service';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { DoAction, Rounds } from '@core/models';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { WarfareRequestsService } from './warfare';

@Injectable({
  providedIn: 'root',
})
export class FillRoundService {
  constructor(
    private http: HttpClient,
    private matSnackbarService: MatSnackbarService,
    private warfareRequestsService: WarfareRequestsService
  ) {}

  public async fillRoundQuestion(
    roundQuestionState: RoundQuestionState[],
    roundId: string
  ): Promise<any> {
    if (!roundQuestionState) {
      return;
    }

    try {
      return lastValueFrom(
        this.updateRoundQuestions(roundQuestionState, roundId).pipe(
          tap((e) => {
            if (e) {
              this.matSnackbarService.success('Added question to round');
            }
          })
        )
      );
    } catch (error) {
      this.matSnackbarService.error('Failed to add question');
    }
  }

  public autofillBonusRound(
    round: Rounds[]
  ): Observable<Pick<GenRound, 'id' | 'title' | 'type_name'>> {
    return from(
      this.warfareRequestsService.generateTCRoundQuestions(round[0].round_id)
    );
  }

  public autofillRound(params: {
    roundId: string;
    gameId?: string;
    questionTypeId?: number;
    limit?: number;
    isWarfare: boolean;
    questionOrder?: number;
  }): Observable<
    GenerateWarfareRoundQuestionsQuery['generateWarfareRoundQuestions']
  > {
    let requestData;
    if (params.isWarfare) {
      requestData = { roundId: params.roundId, isWarfare: params.isWarfare };
    } else {
      requestData = params;
    }
    return this.warfareRequestsService.generateWarfareRoundQuestions(
      requestData
    );
  }

  public updateRoundQuestions(
    questions: QuestionsData[],
    roundId: string
  ): Observable<FillRoundQuestionQuery | boolean> {
    questions = questions.filter((e) => !!e.questionId);
    const body: DoAction<{ questions: QuestionsData[]; round_id: string }> = {
      data: { questions, round_id: roundId },
      do_action: 'f_fill_default_round_wf',
    };
    return this.http
      .post<FillRoundQuestionQuery | boolean>(`${environment.dctapiUrl}`, body)
      .pipe(
        catchError((e) => {
          console.log(e);
          return of(null);
        })
      );
  }
}
