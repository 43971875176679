import { Injectable } from '@angular/core';
import {
  Trivia_Game_Templates,
  AddRoundsToGameMutation,
  GenRound,
} from '@gql/graphql';
import { concat, forkJoin, lastValueFrom, Observable } from 'rxjs';
import { catchError, map, repeat, tap, toArray } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
  GameRatings,
  GameStatuses,
  GameTemplatesEnum,
  Markets,
  QuestionTypeIds,
  Roles,
} from '@enums/index';
import {
  PointValueCount,
  QuestionTypeConstants,
  QuestionsCountForGame,
} from '@constants/index';
import {
  AvailableQuestionsCountForGameResponse,
  IsQuestionCountEnough,
  QuestionType,
  RoundType,
} from '../models/compiler/available-questions-count-for-game.interface';
import { Rounds } from '@core/models/compiler/rounds/rounds.interface';
import { UserService } from '@services/user.service';
import { GameAvailableQuestionsCountService } from '@core/api-services/game';
import { ActivityLogService } from '@core/api-services/question/change-log.service';
import {
  GenerateBlankRoundService,
  GenerateRoundService,
  WarfareRequestsService,
} from './compiler';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import {
  AddRoundToGame,
  CreateRoundResponse,
  DoAction,
  GameAddAction,
} from '@core/models';
import { RoundQuestionAPIService } from '@core/api-services';

@Injectable({
  providedIn: 'root',
})
export class GenerateGameService {
  constructor(
    private http: HttpClient,
    private warfareRequestsService: WarfareRequestsService,
    private gameAvailableQuestionsCountService: GameAvailableQuestionsCountService,
    private userService: UserService,
    private generateBlankRoundService: GenerateBlankRoundService,
    private changeLogService: ActivityLogService,
    private roundQuestionAPIService: RoundQuestionAPIService,
    private generateRoundService: GenerateRoundService
  ) {}

  private rounds: GenRound[];

  public set setRounds(rounds: GenRound[]) {
    this.rounds = rounds;
  }

  public get getRounds(): GenRound[] {
    return this.rounds;
  }

  // returns if questions are enough for creating a game
  public canCreateGame(
    template: Trivia_Game_Templates
  ): Observable<{ remaining?: number; questionTypeName?: string }[]> {
    const questionTypes = QuestionsCountForGame[
      template.template_id
    ] as RoundType;
    const questionsCounts = this.getQuestionsCounts(template, questionTypes);
    return questionsCounts.pipe(
      tap((response) => {
        console.log(response);
      }),
      map((response) => {
        return response
          .map((e) => {
            const { question_count: availableQuestionsCount, question } = e;
            console.log(
              availableQuestionsCount,
              'availableQuestionsCount',
              question
            );

            const remaining =
              question.questionsCount >= availableQuestionsCount
                ? question.questionsCount - availableQuestionsCount
                : null;
            if (
              this.isQuestionCountEnough({
                availableQuestionsCount: availableQuestionsCount,
                questionTypeId: 7,
                question,
              })
            ) {
              e.remaining = remaining;
            } else if (
              this.isQuestionCountEnough({
                availableQuestionsCount: availableQuestionsCount,
                questionTypeId: 0,
                question,
              })
            ) {
              e.remaining = remaining;
            }
            return {
              remaining: e.remaining,
              questionTypeName: question.questionTypeName,
            };
          })
          .filter((item) => item.remaining);
      })
    );
  }

  private isQuestionCountEnough(params: IsQuestionCountEnough): boolean {
    if (params.questionTypeId !== params.question.questionTypeId) {
      return;
    }
    return params.availableQuestionsCount < params.question.questionsCount;
  }

  private getQuestionsCounts(
    template: Trivia_Game_Templates,
    expectedCounts: RoundType
  ): Observable<AvailableQuestionsCountForGameResponse[]> {
    const questionsCountsObservables: Observable<AvailableQuestionsCountForGameResponse>[] =
      [];
    Object.keys(expectedCounts).map((item: keyof QuestionType | string) => {
      const question = expectedCounts[item] as QuestionType;
      if (item !== 'bonus') {
        const questionCount$ = this.gameAvailableQuestionsCountService
          .getAvailableQuestionsCount(question.questionTypeId as number, null)
          .pipe(
            map((response) => {
              response.question_count = +response.question_count;
              return { ...response, question };
            })
          );
        questionsCountsObservables.push(questionCount$);
      } else {
        const bonusQuestionsCount$ = this.gameAvailableQuestionsCountService
          .getBonusQuestionsCount(0)
          .pipe(
            map((response) => {
              response.question_count = +response.question_count;
              return { ...response, question };
            })
          );
        questionsCountsObservables.push(bonusQuestionsCount$);
      }
      return;
    });
    return forkJoin(questionsCountsObservables);
  }

  public getPointValuesCount(
    gameTemplateId: string
  ): Observable<AvailableQuestionsCountForGameResponse[]> {
    const pointValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const pointValueCount$: Observable<AvailableQuestionsCountForGameResponse>[] =
      pointValues.map((value) => {
        const questionTypeId =
          value < 11 ? QuestionTypeIds.MULTI_CHOICE : QuestionTypeIds.RANGE;
        return this.gameAvailableQuestionsCountService
          .getAvailableQuestionsCount(questionTypeId, null, value)
          .pipe(
            map((response) => {
              const questionTypeName =
                QuestionTypeConstants.QuestionTypeNames[questionTypeId];
              return {
                ...response,
                point_value_count: value,
                questionTypeName,
                point_value:
                  PointValueCount[gameTemplateId][questionTypeName]
                    .pointValueCount,
              } as AvailableQuestionsCountForGameResponse;
            })
          );
      });
    const bonusPointValues =
      this.gameAvailableQuestionsCountService.getBonusQuestionsCount(0);
    pointValueCount$.push(bonusPointValues);
    return forkJoin(pointValueCount$);
  }

  /**
   * Generate rounds for game request promise
   */
  private genRoundsReq = () =>
    lastValueFrom(
      concat(
        this.generateRoundService.genDefaultRound().pipe(repeat(4)),
        this.generateRoundService.genDefaultFinalRound()
      ).pipe(toArray())
    );

  /**
   * Generate rounds for ctm game request promise
   */
  private genRoundsCTMReq = () =>
    lastValueFrom(
      concat(this.generateRoundService.genCTMRound().pipe(repeat(5))).pipe(
        toArray()
      )
    );

  /**
   * Generate rounds for ct game request promise
   */
  private genRoundsCTReq() {
    let data: string[] = [];
    const ctmRoundBody: DoAction<{}> = {
      do_action: 'f_gen_ctm_round',
      data: {},
    };
    const bonusRoundBody: DoAction<{}> = {
      do_action: 'f_gen_bonus_round',
      data: {},
    };
    let response$ = lastValueFrom(
      concat(
        concat(
          this.http
            .post<CreateRoundResponse>(`${environment.dctapiUrl}`, ctmRoundBody)
            .pipe(
              map((response) => {
                data.push(response.id);
                return response;
              })
            ),

          this.http
            .post<CreateRoundResponse>(
              `${environment.dctapiUrl}`,
              bonusRoundBody
            )
            .pipe(
              map((response) => {
                data.push(response.id);
                return response;
              })
            )
        ).pipe(repeat(4)),
        this.http
          .post<CreateRoundResponse>(`${environment.dctapiUrl}`, ctmRoundBody)
          .pipe(
            map((response) => {
              data.push(response.id);
              return response;
            })
          )
      ).pipe(
        toArray(),
        catchError((err) => {
          this.roundQuestionAPIService.clearPartiallyCreatedRounds(data);
          throw new Error(err);
        })
      )
    );

    return response$;
  }

  public async genRoundsWarfareAutoFillReq(roundIds: Rounds[]): Promise<any[]> {
    const result = roundIds.map((round, index) => {
      if (index >= 0 && index <= 7 && index !== 3) {
        return this.warfareRequestsService.generateWarfareRoundQuestions(
          round.round_id
        );
      }
      if (index === 3) {
        return this.warfareRequestsService.generateTCRoundQuestions(
          round.round_id
        );
      }
    });
    try {
      if (result) {
        return await lastValueFrom(forkJoin(result));
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  private async genBlankRoundsWarfareReq(): Promise<any> {
    return lastValueFrom(
      concat(
        concat(
          this.generateBlankRoundService.generateBlankRound(
            'standard',
            'warfare',
            3
          )
        ),
        concat(
          this.generateBlankRoundService.generateBlankRound('bonus', 'warfare')
        ),
        concat(
          this.generateBlankRoundService.generateBlankRound(
            'standard',
            'warfare',
            3
          ),
          this.generateBlankRoundService.generateBlankRound(
            'gauntlet',
            'warfare',
            3
          )
        )
      ).pipe(toArray())
    );
  }

  /**
   * Create draft game request promise
   */
  private createGameReq = (gameTemplate: Trivia_Game_Templates) => {
    const body: DoAction<GameAddAction> = {
      do_action: 'f_game_add',
      data: {
        game_id: uuidv4(),
        game_status: GameStatuses.submitted,
        rating: GameRatings.Standard,
        market: Markets.USA,
        game_template_id: gameTemplate.template_id,
        creator_id: this.userService.user.user_id,
        keywords: null,
        game_difficult_id: null,
        category: null,
        description: null,
        title: null,
      },
    };
    return lastValueFrom(
      this.http
        .post(`${environment.dctapiUrl}`, body)
        .pipe(map((response: { game_id: string }) => response.game_id))
    );
    // return lastValueFrom(
    //   this.createGameGQL
    //     .mutate({
    //       game_id: uuid,
    //       game_status: 2,
    //       game_template_id: gameTemplate.template_id,
    //       creator_id: this.userService.user.user_id,
    //     })
    //     .pipe(map((game) => game.data.insert_trivia_games.returning[0].game_id))
    // );
  };

  private async _roundsByTemplateId(template_id: string): Promise<GenRound[]> {
    let rounds: GenRound[];
    switch (template_id) {
      case GameTemplatesEnum.default:
        rounds = await this.genRoundsReq();
        break;
      case GameTemplatesEnum.ctm:
        rounds = await this.genRoundsCTMReq();
        break;
      case GameTemplatesEnum.ct:
        rounds = await this.genRoundsCTReq();
        break;
      case GameTemplatesEnum.warfare:
        rounds = await this.genBlankRoundsWarfareReq();
    }
    return rounds;
  }

  private blankRoundsByTemplateId(template_id: string): Promise<GenRound[]> {
    let rounds: Promise<GenRound[]>;
    switch (template_id) {
      case GameTemplatesEnum.default:
        rounds = this.generateBlankRoundService.generateBlankDefault();
        break;
      case GameTemplatesEnum.ct:
        rounds = this.generateBlankRoundService.generateBlankCt();
        break;
      case GameTemplatesEnum.default:
        rounds = this.generateBlankRoundService.generateBlankDefault();
        break;
      default:
        break;
    }

    return rounds;
  }

  /**
   * Create game, rounds and connect the rounds to the game
   */
  public async createGame(
    gameTemplate: Trivia_Game_Templates,
    autoFill?: boolean
  ): Promise<any> {
    const game = await this.createGameReq(gameTemplate);
    let rounds: GenRound[];
    if (this.userService.user.role === Roles.triviamaticManager) {
      rounds = await this.blankRoundsByTemplateId(gameTemplate.template_id);
    } else {
      rounds = await this._roundsByTemplateId(gameTemplate.template_id);
    }

    try {
      await lastValueFrom(this.addRoundsToGame(rounds, game));

      // if (this.userService.user.role !== Roles.triviamaticManager) {
      //   await lastValueFrom(
      //     this.changeLogService.modifyRoundQuestionLog(gameRounds)
      //   );
      // }
    } catch (error) {
      console.log(error);
      return;
    }
    if (
      gameTemplate.template_id === GameTemplatesEnum.ctm ||
      gameTemplate.template_id === GameTemplatesEnum.ct
    ) {
      // tslint:disable-next-line:variable-name
      const round_order =
        gameTemplate.template_id === GameTemplatesEnum.ctm ? 5 : 9;
      const body: DoAction<{}> = {
        data: { game_id: game, round_order },
        do_action: 'f_update_ctm_last_round_wager',
      };
      await lastValueFrom(this.http.post(`${environment.dctapiUrl}`, body));
    }

    return game;
  }

  /** connects rounds to the game
   * @param  rounds Partial<GenRound>[]
   * @param  gameId string
   * @returns Observable<AddRoundsToGameMutation>
   */

  public addRoundsToGame(
    rounds: Partial<{ id?: string; round_id?: string }>[],
    gameId: string
  ): Observable<AddRoundsToGameMutation> {
    const gameRounds = rounds.map((round, index) => ({
      round_id: round.id || round.round_id,
      round_order: index + 1,
    }));
    const body: DoAction<{
      game_id: string;
      rounds: AddRoundToGame[];
    }> = {
      data: { game_id: gameId, rounds: gameRounds },
      do_action: 'f_game_rounds_add',
    };

    return this.http.post<AddRoundsToGameMutation>(
      `${environment.dctapiUrl}`,
      body
    );
    //     this.changeLogService.modifyRoundQuestionLog(gameRounds)
  }

  /**
   * Remove rounds from game, add new generated rounds
   */
  public async replaceRounds(
    gameId: string,
    rounds: {
      game_round_id: string;
      round_type: string;
      round_order: number;
      round_id: string;
    }[]
  ): Promise<string> {
    const genRounds = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < rounds.length; i++) {
      const body: DoAction<{ round_id: string }> = {
        data: { round_id: rounds[i].round_id },
        do_action: 'f_game_round_delete',
      };
      await lastValueFrom(
        this.http.post<string>(`${environment.dctapiUrl}`, body)
      );
      let round: GenRound;

      switch (rounds[i].round_type) {
        case 'standard':
          round = await lastValueFrom(
            this.generateRoundService.genDefaultRound()
          );
          break;
        case 'final':
          round = await lastValueFrom(
            this.generateRoundService.genDefaultFinalRound()
          );
          break;
        case 'ctm_standard':
          round = await lastValueFrom(this.generateRoundService.genCTMRound());
          break;
        case 'bonus':
          round = await lastValueFrom(
            this.generateRoundService.genBonusRound()
          );
          break;
      }
      genRounds[i] = round;
    }
    await lastValueFrom(this.addRoundsToGame(genRounds, gameId));
    return gameId;
  }
}
