import {ElementRef} from "@angular/core";
import {QuestionData} from "../models";
import {Questions} from "@gql/graphql";
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";

export class UtilityHelper {
  public static replaceStingNullFields<T>(user: T): T {
    const {...data}: T = user;
    Object.keys(data).map(field => {
      if (data[field] === 'null') {
        data[field] = null
      }
      return field
    })

    return data;
  }

  public static compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public static scrollToElement(elementRef: ElementRef): void {
    elementRef?.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  public static _categorizeQuestionTypes(
    questionTypeId: number,
    selectedQuestions: QuestionData[]
  ): boolean {
    return selectedQuestions.every(
      (question) => question.question_type_id === questionTypeId
    );
  }

  public static chunkArray<T>(array: T[], chunkSize: number): T[][] {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // Initialize chunk array if it doesn't exist
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

  public static YYYYMMDDFormat(date: Date) {
    if (!date) {
      return
    }
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    const formattedDate = date
      .toISOString()
      .replace('T', ' ')
      .replace(/\..*/, '');
    return formattedDate;
  }

  public static replaceUnicodeChars(question: Partial<Questions>, char: string[]) {
    const joinedStr = char.join('|');
    const regexp =  RegExp(joinedStr, 'g');
    for (const key in question) {
      if (Object.prototype.hasOwnProperty.call(question, key)) {
        let item = question[key];
        if (typeof item !== 'string') {
          this.replaceUnicodeChars(item, char);
        } else {
          question[key] = item.replace(regexp, "'");
        }
      }
    }
    return question
  }

  public static makePromise<T>(subscription: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      subscription.subscribe({
        next: (data: T) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  public static bearerToken(token: string):  HttpHeaders  {
    return new HttpHeaders({
      "Authorization": `Bearer ${token}`,
    });
  }
}
