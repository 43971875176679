import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FillFinalRoundQuestionsOutput,
  FillRoundStandardQuestionsQuery,
} from '@core/generated-gql/graphql';
import { DoAction, FillCtmQuestionsRequest, FillDefaultFinalRoundQuestionParams } from '@core/models';
import { environment } from '@env';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StandardRoundService {
  constructor(private http: HttpClient) {}

  public fillStandardRoundQuestions(
    FillRoundFilters: Partial<FillCtmQuestionsRequest>,
    selectedQuestionsLength: number
  ): Observable<FillRoundStandardQuestionsQuery['fillRoundQuestions']> {
    const body: DoAction<Partial<FillCtmQuestionsRequest> & { count: number }> =
      {
        data: { count: selectedQuestionsLength, ...FillRoundFilters },
        do_action: 'f_fill_default_round_search_wf',
      };
    return this.http.post<
      FillRoundStandardQuestionsQuery['fillRoundQuestions']
    >(`${environment.dctapiUrl}`, body);
  }

  public getDefaultGameStandardRoundTypes(): string[] {
    let roundNames = [];
    for (let index = 0; index < 4; index++) {
      roundNames[index] = 'Standard';
    }
    roundNames.push('Final');
    return roundNames;
  }

  public fillFinalRoundQuestion(
    params: FillDefaultFinalRoundQuestionParams
  ): Observable<{ question_id: string }[]> {
    const body: DoAction<any> = {
      do_action: 'f_fill_default_final_round_search_wf',
      data: params,
    };
    return this.http.post<{ question_id: string }[]>(
      `${environment.dctapiUrl}`,
      body
    );
  }
}
