import { FormArray, ValidatorFn } from '@angular/forms';
import { PuzMatchingAnswerFormGroup, QuestionTextImage } from '@models/index';


export interface PuzMatchingFormGroup {
        question_text_image: QuestionTextImage,
        header1: [string, ValidatorFn,
        ],
        header2: [string, ValidatorFn,
        ],
        hint: [string, ValidatorFn],
        items: PuzMatchingAnswerFormGroup[],
        question_type_id: number;
        question_text: string;
        image: string;
 };
