import {Injectable} from '@angular/core';
import {
  CategoriesQuery,
  Markets,
  MarketsQuery,
  QuestionBonusesQuery,
  QuestionDifficultiesQuery,
  Questions,
  QuestionsListFactcheckerQuery,
  QuestionTypesQuery,
} from '@gql/graphql';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {DoActionRequestsService} from '@core/services';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env';
import {DoAction} from '@core/models';
import {QuestionStatuses} from '@core/enums';
import {takeUntil} from 'rxjs';
import {UtilityHelper} from "@core/helpers";

@Injectable({
  providedIn: 'root',
})
export class QuestionFormDataService {
  private readonly unsubscribe$: Subject<void> = new Subject();
  public question: BehaviorSubject<Questions> = new BehaviorSubject(null);
  public questionData: Subject<Questions> = new Subject();
  public readonly _markets$: Observable<Markets[]> = this.doActionRequestsService.getMarkets();

  public readonly bonusValues$ = this.doActionRequestsService.questionBonuses();
  public readonly matureRating$ = this.doActionRequestsService.matureRating();

  constructor(
    private http: HttpClient,
    private doActionRequestsService: DoActionRequestsService
  ) {
  }

  generateRandomNumber(length: number): number {
    return Math.floor(Math.random() * length);
  }

  questionFormData<T>(
    additionalObservables?: Observable<T>
  ): Observable<
    (
      | MarketsQuery['markets']
      | QuestionTypesQuery['question_types']
      | CategoriesQuery['categories']
      | QuestionDifficultiesQuery['question_difficulties']
      | QuestionBonusesQuery['question_bonuses']
      )[]
  > {
    const observables = [
      UtilityHelper.makePromise<MarketsQuery['markets']>(
        this.doActionRequestsService.getMarkets()
      ),
      UtilityHelper.makePromise<
        QuestionTypesQuery['question_types']
      >(this.doActionRequestsService.questionTypes()),
      UtilityHelper.makePromise<CategoriesQuery['categories']>(
        this.doActionRequestsService
          .getCategories()
          .pipe(takeUntil(this.unsubscribe$))
      ),
      UtilityHelper.makePromise<
        QuestionDifficultiesQuery['question_difficulties']
      >(this.doActionRequestsService.questionDifficulties()),
      UtilityHelper.makePromise<
        QuestionBonusesQuery['question_bonuses']
      >(this.doActionRequestsService.questionBonuses()),
    ];
    if (additionalObservables) {
      UtilityHelper.makePromise(additionalObservables);
    }
    return forkJoin(observables);
  }

  questionFormDataAndApprovedUser(): any {
    return this.questionFormData();
  }

  getQuestion(
    count = 10,
    offset = 0
  ): Observable<QuestionsListFactcheckerQuery['questions']> {
    const body: DoAction<any> = {
      do_action: 'f_questions_list_factchecker',
      data: {
        count_val: count,
        offset_val: offset,
        status_id: QuestionStatuses.review,
        approved_user_id: null,
        is_null: 1,
      },
    };
    return this.http.post<QuestionsListFactcheckerQuery['questions']>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
