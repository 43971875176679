import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import {
  FillRoundQuestionQuery,
  QuestionsData,
} from '@core/generated-gql/graphql';
import { RoundFormsWithTypeAndId } from '@core/models';
import { ApolloQueryResult } from '@apollo/client';
import { forkJoin, Observable } from 'rxjs';
import { FillRoundService } from '../compiler/fill-round.service';

@Injectable({ providedIn: 'root' })
export class DraggableTableService {
  constructor(private fillRoundService: FillRoundService) {}

  onDrop<T>(
    event: CdkDragDrop<T[]>,
    dropContainerIndex: number,
    rounds: Partial<RoundFormsWithTypeAndId>[][],
    dragContainerIndex: number
  ): Observable<[boolean | FillRoundQuestionQuery, boolean | FillRoundQuestionQuery]> {
    let { previousIndex, currentIndex } = event;
    if (previousIndex === 3 || currentIndex === 3) {
      previousIndex === 3 ? (previousIndex -= 1) : (currentIndex -= 1);
    }

    const dragData = rounds[dragContainerIndex];
    const dropData = rounds[dropContainerIndex];
    const currentRoundId = dropData[0].round_id;
    const previousRoundId = dragData[0].round_id;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, previousIndex, currentIndex);
    } else {
      transferArrayItem(dragData, dropData, previousIndex, currentIndex);
      transferArrayItem(dropData, dragData, currentIndex + 1, previousIndex);
    }
    rounds[dragContainerIndex] = [...dragData];
    rounds[dropContainerIndex] = [...dropData];

    if (
      dragData[previousIndex].question_id ===
        dropData[currentIndex].question_id &&
      previousRoundId === currentRoundId
    ) {
      return;
    }

    return forkJoin([
      this.fillRoundService.updateRoundQuestions(
        this.formQuestionsData(dropData),
        currentRoundId
      ),
      this.fillRoundService.updateRoundQuestions(
        this.formQuestionsData(dragData),
        previousRoundId
      ),
    ]);
  }

  private formQuestionsData(
    roundData: Partial<RoundFormsWithTypeAndId>[]
  ): QuestionsData[] {
    return roundData.map((e, i) => {
      return {
        questionId: e.question_id || '',
        orderNumber: i + 1,
        answerTypeId: e.answer_type_id,
      };
    });
  }
}
