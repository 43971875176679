import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {MaxLengthConstants} from '@constants/max-length.constants';
import {QuestionTypeIds} from '@core/enums';
import {DynamicQuestionFormOptions} from '@core/models';

@Injectable({providedIn: 'root'})
export class DynamicQuestionFormService {
  constructor() {
  }

  public getFieldMaxLength(number: string): number {
    let maxLength = 0;
    const numbers = number.match(/\d/g);
    const commas = number.match(/\,/g);
    if (numbers && numbers?.length < 16) {
      maxLength = (commas?.length || 0) + 20;
    } else {
      maxLength = MaxLengthConstants.rangeAnswerWithCommasMaxLength;
    }
    return maxLength;
  }

  public getActualNumbersLengthAndUpdateFormValue(params: {
    str: string;
    options: Partial<DynamicQuestionFormOptions>;
    controlName: string;
    control: AbstractControl;
  }): string {
    let {
      str,
      options: { questionTypeId, maxLength },
      controlName,
    } = params;
    let fieldNewValue = str?.toString();
    const replacedValue = str?.trim().replace(/\,+/g, '');
    if (questionTypeId !== QuestionTypeIds.RANGE) {
      fieldNewValue = params.str?.toLocaleString();
      params.control.setValue(fieldNewValue, { emitEvent: false });
      return;
    }
    const isRangeValidControl =
      controlName === 'right_answer_text' && parseInt(replacedValue, 10);
    if (!isRangeValidControl) {
      return;
    }
    if (replacedValue.length > 4) {
      fieldNewValue = parseInt(replacedValue, 10).toLocaleString('en-US');
    } else {
      fieldNewValue = parseInt(replacedValue, 10).toString();
    }
    params.control.setValue(fieldNewValue, { emitEvent: false });
    return fieldNewValue;
  }
}
