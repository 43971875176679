import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenRound } from '@core/generated-gql/graphql';
import { DoAction } from '@core/models';
import { environment } from '@env';
import {
  catchError,
  concat,
  lastValueFrom,
  map,
  Observable,
  repeat,
  toArray,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GenerateBlankRoundService {
  constructor(private http: HttpClient) {}

  public generateBlankRound(
    round_type: string,
    roundTemplateId: string,
    repeatCount?: number
  ): Observable<GenRound> {
    const body: DoAction<{ round_template_id: string; round_type: string }> = {
      data: {
        round_template_id: roundTemplateId,
        round_type,
      },
      do_action: 'f_gen_round',
    };
    try {
      const req = this.http.post(`${environment.dctapiUrl}`, body).pipe(
        catchError((e) => {
          console.log(e);

          return null;
        }),
        map((standard: any) => standard.data.generateRound)
      );
      if (repeatCount) {
        return req.pipe(repeat(repeatCount));
      }
      return req;
    } catch (error) {}
  }

  public generateBlankDefault(): Promise<GenRound[]> {
    return lastValueFrom(
      concat(
        this.generateBlankRound('standard', 'warfare', 4),
        this.generateBlankRound('final', 'warfare')
      ).pipe(toArray())
    );
  }

  public generateBlankCt(): Promise<GenRound[]> {
    return lastValueFrom(
      concat(
        concat(
          this.generateBlankRound('ctm_standard', 'warfare'),
          this.generateBlankRound('bonus', 'warfare')
        ).pipe(repeat(4)),
        this.generateBlankRound('ctm_standard', 'warfare')
      ).pipe(toArray())
    );
  }

  public generateBlankCtm = () =>
    lastValueFrom(
      concat(this.generateBlankRound('standard', 'warfare', 5)).pipe(toArray())
    );
}
