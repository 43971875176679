import { Injectable } from '@angular/core';
import { MatTree } from '@core/models';

interface TreeDataType<T> {
  name: string;
  expandable: boolean;
  children: T;
}

@Injectable({
  providedIn: 'root',
})
export class MatTreeService {
  private treeData = [];

  constructor() {}

  getTreeData<T>(treeData: MatTree<T>[]): TreeDataType<T>[] {
    treeData.forEach((node: MatTree<T>) => {
      this.treeData.push({
        id: node.id,
        name: node.name,
        expandable: node?.expandable,
        children: node?.data,
      });
    });
    return this.treeData;
  }
}
