import { Injectable } from '@angular/core';
import {
  GenerateWarfareRoundQuestionsQuery,
  GenRound,
} from '@core/generated-gql/graphql';
import { DialogService } from '@core/services/dialogs.service.ts';
import { RemoveRoundQuestionService } from '@core/services/question';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DoAction } from '@core/models';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class WarfareRequestsService {
  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private removeRoundQuestionService: RemoveRoundQuestionService
  ) {}
  public autofillBonusRound(
    roundId: string
  ): Observable<Pick<GenRound, 'id' | 'title' | 'type_name'>> {
    return from(this.generateTCRoundQuestions(roundId));
  }

  deleteRoundQuestion(questionId: string, roundId: string): any {
    return this.dialogService
      .callConfirmModalAndReturnObservable({
        message: 'Are you sure you want to delete this question?',
      })
      .pipe(
        switchMap((response) => {
          if (!response) {
            return of(null);
          }
          const res = this.removeRoundQuestionService.deleteRoundQuestion(
            questionId,
            roundId
          );
          return res;
        })
      );
  }

  changeAnswerType(
    question_id: string,
    answer_type_id: number
  ): Observable<any> {
    const body: DoAction<{ question_id: string; answer_type_id: number }> = {
      data: {
        question_id,
        answer_type_id,
      },
      do_action: 'f_change_answer_type',
    };
    return this.http.post(`${environment.dctapiUrl}`, body);
  }

  async generateTCRoundQuestions(
    round_id: string
  ): Promise<Pick<GenRound, 'id' | 'title' | 'type_name'>> {
    const body: DoAction<{ round_id: string }> = {
      do_action: 'f_gen_two_column_round_questions',
      data: { round_id },
    };
    const response = await lastValueFrom(
      this.http
        .post<Pick<GenRound, 'id' | 'title' | 'type_name'>>(
          `${environment.dctapiUrl}`,
          body
        )
        .pipe()
    );
    return response;
  }

  autofillRound(
    roundId: string
  ): Observable<Pick<GenRound, 'id' | 'title' | 'type_name'>> {
    return from(this.generateTCRoundQuestions(roundId));
  }

  public generateWarfareRoundQuestions(
    round_id: string
  ): Observable<
    GenerateWarfareRoundQuestionsQuery['generateWarfareRoundQuestions']
  > {
    const body: DoAction<{ round_id: string }> = {
      data: { round_id },
      do_action: 'f_gen_warfare_round_questions',
    };
    return this.http.post<
      GenerateWarfareRoundQuestionsQuery['generateWarfareRoundQuestions']
    >(`${environment.dctapiUrl}`, body);
  }
}
