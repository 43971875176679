import { Injectable } from '@angular/core';
import { Statistics } from '@models/statistics.interface';

@Injectable({
    providedIn: 'root'
})
export class WriterStatisticsService {
    constructor() { }

    public get getWriterStatistics(): Statistics {
        return this.writerTitlesIcons();
    }
    private writerTitlesIcons(): Statistics {
        const writerTitleAndIcons = [
          {
            key: 'questions_submitted',
            title: 'Questions Submitted',
            icon: 'assets/svg/questions-seved-writer.svg'
          },
          {
            key: 'questions_published',
            title: 'Questions Accepted',
            icon: 'assets/svg/questions-accepted.svg'
          },
          {
            key: 'questions_rejected',
            title: 'Questions Rejected',
            icon: 'assets/svg/questions-rejected-writer.svg'
          },
          {
            key: 'questions_needs_review',
            title: 'Questions in Factchecking Queue',
            icon: 'assets/svg/factchecking-que.svg'
          }
        ];
        return writerTitleAndIcons;
      }

}
