import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalOptions } from '@core/models';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  public callConfirmModalAndReturnObservable(
    callConfirmModalOptions: ConfirmModalOptions
  ): Observable<boolean> {
    return this.openConfirmModal(callConfirmModalOptions);
  }

  openConfirmModal(
    callConfirmModalOptions: ConfirmModalOptions
  ): Observable<boolean> {
    const { title, list, message, onlyConfirm } = callConfirmModalOptions;
    const dialog: MatDialogRef<ConfirmDialogComponent> = this.matDialog.open(
      ConfirmDialogComponent,
      {
        data: {
          title,
          list,
          message,
          onlyConfirm,
        },
      }
    );
    return dialog.componentInstance.confirm.pipe(
      take(1),
      finalize(() => {
        dialog.close();
      })
    );
  }


}
