<div class="image-modal">
  <div class="actions w-100 d-flex justify-content-end">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="image-upload-container d-flex justify-content-center" mat-dialog-content>
    <div>
      <div class="mb-1 row modal-head">
        <p class="modal-title w-100"> {{title}} </p>
        <p class="modal-subtitle col-7 mb-1"> ({{data.width}}x{{data.height}})</p>
      </div>
      <mat-spinner *ngIf="!isImageLoaded" class="spinner"></mat-spinner>
      <div class="image-upload-input-div" *ngIf="!data.imageUrl && !imageChangedEvent">
        <label for="file-upload"
               class="file-upload px-3 cursor-pointer d-flex justify-content-center align-items-center">
          <mat-icon color="primary" class="mr-2">cloud_upload</mat-icon>
          <span> Image Upload</span>
        </label>
        <input
          id="file-upload"
          class="d-none"
          type="file"
          accept=".png, .jpg, .jpeg, .webp, .bmp"
          (change)="fileChangeEvent($event)"
        >
        <span
          *ngIf="!data.imageUrl && isInvalidImage"
          class="text-danger d-flex align-items-center"
        >
          Image upload failed
        </span>
      </div>
      <div [ngClass]="{ 'cropper-container': !isNotImage }">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [imageURL]="data.imageUrl | urlToBase64 | async"
          [canvasRotation]="canvasRotation"
          [aspectRatio]="popupData.aspectRatio"
          [transform]="transform"
          [autoCrop]="false"
          [resizeToWidth]="popupData.cropperStaticWidth"
          [resizeToHeight]="popupData.cropperStaticHeight"
          (imageLoaded)="imageLoaded()"
          [backgroundColor]="'#141414'"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
      <mat-error *ngIf="isNotImage" class="text-danger wrong-image-format position-relative"> Invalid format</mat-error>
      <div *ngIf="!isNotImage && isImageLoaded" class="control-buttons-div">
        <div class="control-buttons" [ngClass]="{'visible': imageChangedEvent || data.imageUrl,
      'invisible': !(imageChangedEvent || data.imageUrl), 'left': scale !== 1 || canvasRotation !== 0}">
          <button class="mr-1"
                  mat-stroked-button color="primary"
                  title="Rotate Left"
                  (click)="rotateLeft()">
            <mat-icon>rotate_left</mat-icon>
          </button>
          <button class="mr-1"
                  mat-stroked-button color="primary"
                  title="Rotate Right"
                  (click)="rotateRight()">
            <mat-icon>rotate_right</mat-icon>
          </button>
          <button class="mr-1"
                  mat-stroked-button color="primary"
                  title="Zoom In"
                  (click)="zoomIn()">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <button class="mr-1"
                  mat-stroked-button color="primary"
                  title="Zoom Out"
                  (click)="zoomOut()">
            <mat-icon>zoom_out</mat-icon>
          </button>
          <button class="mr-1" *ngIf="scale !== 1 || canvasRotation !== 0"
                  mat-stroked-button color="primary"
                  title="Reset Image"
                  (click)="resetImage()">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
        <div class="d-flex align-items-center justify-content-center
        save-button-div mt-3" *ngIf="data.imageUrl || imageChangedEvent">
          <button
            class="mat-focus-indicator button-primary mat-button"
            mat-stroked-button color="primary"
            title="Save"
            [disabled]="!imageChangedEvent && !data.imageUrl || uploadFailed"
            (click)="processFile()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
