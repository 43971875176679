import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })

export class CalendarService {

    constructor() { }

    public date: BehaviorSubject<moment.Moment> = new BehaviorSubject(moment());
    public eventTypeFilters: BehaviorSubject<string[]> = new BehaviorSubject([
        'default',
        'ctm',
        'ct',
        'warfare'
    ]);

    changeMonth(dir: number): void {
        const value = this.date.value.add(dir, 'month');
        this.date.next(value);
    }

}
