import { Injectable } from '@angular/core';
// import { WaveBytesOptions } from '@models/wave-bytes-options.interface';
// import WaveSurfer from 'wavesurfer.js';
// import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';

export interface AudioUploadData {
  blob: Blob;
}

@Injectable({
  providedIn: 'root',
})

export class OpenAudioModalService {
  constructor() {}

  // public initialiseAudioContainer(
  //   audioContainerId: string,
  //   plagins: RegionsPlugin = []
  // ): WaveSurfer {
  //   const wave = WaveSurfer.create({
  //     container: audioContainerId,
  //     waveColor: '#6620AB',
  //     progressColor: '#6620AB',
  //     cursorColor: '#4353FF',
  //     barWidth: 1,
  //     barRadius: 3,
  //     cursorWidth: 1,
  //     height: 100,
  //     minPxPerSec: 5,
  //     barGap: 3,
  //     plugins: plagins,
  //   });
  //   return wave;
  // }

  public createBuffer(originalBuffer: AudioBuffer, duration: number): AudioBuffer {
    const sampleRate = originalBuffer.sampleRate;
    const frameCount = duration * sampleRate;
    const channels = originalBuffer.numberOfChannels;
    return new AudioContext().createBuffer(channels, frameCount, sampleRate);
  }

  public copyBuffer(fromBuffer: AudioBuffer, fromStart: number, fromEnd: number, toBuffer: AudioBuffer, toStart: number): void {
    const sampleRate = fromBuffer.sampleRate;
    const frameCount = (fromEnd - fromStart) * sampleRate;
    for (let i = 0; i < fromBuffer.numberOfChannels; i++) {
      const fromChanData = fromBuffer.getChannelData(i);
      const toChanData = toBuffer.getChannelData(i);
      for (
        let j = 0,
          f = Math.round(fromStart * sampleRate),
          t = Math.round(toStart * sampleRate);
        j < frameCount;
        j++, f++, t++
      ) {
        toChanData[t] = fromChanData[f];
      }
    }
  }

  // // Returns Uint8Array of WAV bytes
  // public getWavBytes(buffer: ArrayBuffer, options: WaveBytesOptions): Uint8Array {
  //   const type = options.isFloat ? Float32Array : Uint16Array;
  //   const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT;
  //   const headerBytes = this.getWavHeader(
  //     Object.assign({}, options, { numFrames })
  //   );
  //   const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);

  //   // prepend header, then add pcmBytes
  //   wavBytes.set(headerBytes, 0);
  //   wavBytes.set(new Uint8Array(buffer), headerBytes.length);

  //   return wavBytes;
  // }

  // adapted from https://gist.github.com/also/900023
  // returns Uint8Array of WAV header bytes
  // public getWavHeader(options: WaveBytesOptions): Uint8Array {
  //   const numFrames: number = options.numFrames;
  //   const numChannels: number = options.numChannels || 2;
  //   const sampleRate: number = options.sampleRate || 44100;
  //   const bytesPerSample: 4 | 2 = options.isFloat ? 4 : 2;
  //   const format: 3 | 1 = options.isFloat ? 3 : 1;
  //   const blockAlign: number = numChannels * bytesPerSample;
  //   const byteRate: number = sampleRate * blockAlign;
  //   const dataSize = numFrames * blockAlign;
  //   const buffer = new ArrayBuffer(44);
  //   const dataView: DataView = new DataView(buffer);
  //   let p = 0;

  //   function writeString(s: string): void {
  //     for (let i = 0; i < s.length; i++) {
  //       dataView.setUint8(p + i, s.charCodeAt(i));
  //     }
  //     p += s.length;
  //   }

  //   function writeUint32(d: number): void {
  //     dataView.setUint32(p, d, true);
  //     p += 4;
  //   }

  //   function writeUint16(d: number): void {
  //     dataView.setUint16(p, d, true);
  //     p += 2;
  //   }

  //   writeString('RIFF'); // ChunkID
  //   writeUint32(dataSize + 36); // ChunkSize
  //   writeString('WAVE'); // Format
  //   writeString('fmt '); // Subchunk1ID
  //   writeUint32(16); // Subchunk1Size
  //   writeUint16(format); // AudioFormat
  //   writeUint16(numChannels); // NumChannels
  //   writeUint32(sampleRate); // SampleRate
  //   writeUint32(byteRate); // ByteRate
  //   writeUint16(blockAlign); // BlockAlign
  //   writeUint16(bytesPerSample * 8); // BitsPerSample
  //   writeString('data'); // Subchunk2ID
  //   writeUint32(dataSize); // Subchunk2Size

  //   return new Uint8Array(buffer);
  // }
}
