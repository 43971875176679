import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DoAction, TriviaGameRounds } from '@core/models';
import { environment } from '@env';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AddGameService {
  constructor(private http: HttpClient) {}

  public gameRounds(
    game_id: string
  ): Observable<TriviaGameRounds['trivia_game']['trivia_game_rounds']> {
    const body: DoAction<any> = {
      data: { game_id },
      do_action: 'f_trivia_game_by_pk',
    };
    return this.http
      .post<TriviaGameRounds>(`${environment.dctapiUrl}`, body)
      .pipe(map((e) => e.trivia_game.trivia_game_rounds));
  }
}
