import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { Profile } from '@core/models';
import { UserService } from '@core/services';
import {UtilityHelper} from "@core/helpers";

@Injectable({ providedIn: 'root' })
export class AddUserService {
  private options = UtilityHelper.bearerToken(
    this.userService.user.token
  );
  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}
  private apiUrl = environment.restApiUrl;

  public addUser(data: any): Observable<Profile> {
    return this.http.post<Profile>(
      `${this.apiUrl}auth/create-user-by-type`,
      data,
      {
        headers: this.options,
      }
    );
  }
}
