import { Injectable } from '@angular/core';
import { Statistics } from '@models/statistics.interface';

@Injectable({
  providedIn: 'root',
})
export class CompilerStatisticsService {
  constructor() {}
  public get getCompilerStatistics(): Statistics {
    return this.compilerStatistics();
  }

  private compilerStatistics(): Statistics {
    return [
      {
        key: 'all_created_games',
        title: 'Total Games Created',
        icon: 'assets/svg/games.svg',
      },
      {
        key: 'ready_for_scheduling',
        title: 'Games Ready for Scheduling',
        icon: 'assets/svg/games-ready.svg',
      },
      {
        key: 'created_standard_games',
        title: 'Standard Games Created',
        icon: 'assets/svg/games-standard.svg',
      },
      {
        key: 'created_spcialty_games',
        title: 'Specialty Games Created',
        icon: 'assets/svg/specialty-games.svg',
      },
    //   {
    //     title: 'Private Games Created',
    //     icon: 'assets/svg/private-games.svg',
    //   },
    ];
  }
}
