import { DateEnum } from "@core/enums";

export class DatePickerConstants {
  static readonly MY_FORMATS = {
    parse: {
      dateInput: DateEnum.dateInputWithTwoYearDigits,
    },
    display: {
      dateInput: DateEnum.dateInputWithTwoYearDigits,
      monthYearLabel: '',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
}
