import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateAfterReportService {
  constructor(private http: HttpClient) {}

  public updateDataAfterReport(
    oldQuestionId: string,
    newQuestionId: string
  ): Observable<any> {
    return this.http.put(
      ` ${environment.restApiUrl}games/replace-reported-question/${oldQuestionId}`,
      { newQuestionId }
    );
  }
}
