import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationConstants } from '@constants/validation.constants';
import { Report_Reasons } from '@gql/graphql';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {ObjectHelper} from "@core/helpers";

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
})
export class ReportModalComponent implements OnInit, OnDestroy {
  public reportForm: FormGroup;
  public validationConstants = ValidationConstants;
  public isSubmitted = false;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ReportModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reportReasons: Report_Reasons[];
      questionId: string;
      pointValue?: number;
    }
  ) {}

  ngOnInit(): void {
    this.reportForm = this.createReportForm();
    this.reportMessageValueChanges();
  }

  private createReportForm(): FormGroup {
    return this.fb.group({
      report_reason_id: ['', Validators.required],
      message: [
        '',
        [
          Validators.required,
          Validators.maxLength(
            ValidationConstants.reportQuestionCommentMaxLength
          ),
          Validators.pattern(ValidationConstants.NO_WHITE_SPACE),
        ],
      ],
    });
  }

  private reportMessageValueChanges(): void {
    this.reportForm
      .get('message')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.isSubmitted = false;
        if (
          this.reportForm.get('message').invalid &&
          !this.reportForm.get('message').hasError('required')
        ) {
          this.reportForm.get('message').markAsTouched();
        }
        if (
          this.reportForm.get('message').hasError('required') &&
          !this.isSubmitted
        ) {
          this.reportForm.get('message').markAsUntouched();
        }
      });
  }

  /**
   * Validates form, closes dialog with its value
   */
  public submit(): void {
    this.isSubmitted = true;
    this.reportForm
      .get('message')
      .setValue(this.reportForm.get('message').value?.trim() || '');
    if (this.reportForm.invalid) {
      this.reportForm.markAllAsTouched();
      return;
    }
    this.dialogRef.close({
      ...ObjectHelper.trimObjectValues(
        this.reportForm.getRawValue()
      ),
      question_id: this.data.questionId,
      point_value: this.data.pointValue,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
