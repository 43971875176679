export class FileModel {
  name: string;
  data: string;
  isReady: boolean;
  safeData: any;
  hasError: boolean;
  isDisabled: boolean;

  constructor() {
    this.name = '';
    this.data = '';
    this.isReady = false;
    this.hasError = false;
    this.isDisabled = true;
  }
}
