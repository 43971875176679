import {Injectable} from '@angular/core';
import {AddRoundQuestionsMutationVariables, Trivia_Round_Types,} from '@core/generated-gql/graphql';
import {
  AddRoundQuestionResponse,
  DoAction,
  InsertRoundQuestionObject,
  RandomRoundQuestionRequest,
  RandomRoundQuestionResponse,
} from '@core/models';
import {Observable, of} from 'rxjs';
import {concatMap, map, tap} from 'rxjs/operators';
import {MatSnackbarService} from '../mat-snakbar/mat-snakbar.service';
import {AnswerTypeIdEnum, QuestionTypeIds} from '@core/enums';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env';

@Injectable({providedIn: 'root'})
export class MutateTriviaRoundQuestionService {
  constructor(
    private matSnackbarService: MatSnackbarService,
    private http: HttpClient
  ) {
  }

  public updateRoundQuestion(params: {
    question_id: string;
    id: string;
  }): Observable<{ id: string }> {
    const body: DoAction<{ question_id: string; id: string }> = {
      data: {...params},
      do_action: 'f_update_trivia_round_question',
    };
    return this.http
      .post<{ id: string }>(`${environment.dctapiUrl}`, body)
      .pipe(
        tap((e) => {
          console.log(e);
        })
      );
  }

  public formatRoundQuestionRequestParameters(
    round_id: string,
    question: { question_order: number; question_type_id: number },
    newQuestionId: string
  ): InsertRoundQuestionObject {
    const objects: InsertRoundQuestionObject = {
      round_id,
      question_order: question.question_order,
      question_id: newQuestionId,
    };

    if (question.question_type_id === QuestionTypeIds.RANGE) {
      objects.answer_type_id = AnswerTypeIdEnum.input;
      objects.wager = true;
    }
    return objects;
  }

  public addRoundQuestions(
    objects: AddRoundQuestionsMutationVariables['objects']
  ): Observable<AddRoundQuestionResponse> {
    const body: DoAction<AddRoundQuestionsMutationVariables['objects']> = {
      do_action: 'f_trivia_round_question_add',
      data: objects,
    };
    return this.http.post<{ round_id: string; id: string }>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public createChangeQuestionQuery(params: {
    is_random: boolean;
    question: any;
    game_template_id: string;
    roundType?: Trivia_Round_Types;
    game_id: string;
  }): RandomRoundQuestionRequest {
    const {question, game_template_id, game_id, is_random} = params;
    const {
      category: category_id,
      sub_category: sub_category_id,
      question_type_id,
      point_value,
    } = question;
    let query: RandomRoundQuestionRequest = {
      point_value,
      game_template_id,
      question_type_id: [question_type_id],
      game_id,
    };
    if (!is_random) {
      query = {
        ...query,
        category_id,
        sub_category_id,
      };
    }

    return query;
  }

  public randomQuestionForRound(params: {
    roundQuestionId?: string;
    queryData: RandomRoundQuestionRequest;
    showLast?: boolean
  }): Observable<RandomRoundQuestionResponse> {
    let body: DoAction<RandomRoundQuestionRequest> = {
      data: {
        ...params.queryData,
      },
      do_action: 'f_get_random_question_for_round',
    };
    if (params.showLast) {
      body.do_action = 'f_get_random_last_question_for_round'
    }
    return this.http
      .post<RandomRoundQuestionResponse>(`${environment.dctapiUrl}`, body)
      .pipe(
        concatMap((random_question) => {
          if (!random_question) {
            this.matSnackbarService.error(`Question not found`);
            return of(null);
          }
          return this.updateRoundQuestion({
            question_id: random_question.question_id,
            id: params.roundQuestionId,
          })
            .pipe(
              map((response) => {
                return {
                  round_id: response.id,
                  ...random_question,
                };
              })
            );
        })
      );
  }
}
