import { Injectable } from '@angular/core';
import {
  DateRange,
  MatDateRangeSelectionStrategy,
} from '@angular/material/datepicker';
import { RangeDatePickerTab } from '@core/enums';
import * as moment from 'moment';
import { DatepickerCalendarService } from './datepicker-calendar.service';

@Injectable({ providedIn: 'root' })
export class MatRangeDatePickerService<D>
  implements MatDateRangeSelectionStrategy<D>
{
  constructor(private datepickerCalendarService: DatepickerCalendarService) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this.dateRangesByCalendarHeaderMode(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this.dateRangesByCalendarHeaderMode(activeDate);
  }

  private _createSixDayRange(date: D | null): DateRange<D> {
    if (date) {
      const weekStart = moment(date).startOf('week');
      const weekEnd = moment(date).endOf('week');
      return new DateRange<D>(
        weekStart.toDate() as any,
        weekEnd.toDate() as any
      );
    }
    return new DateRange<D>(null, null);
  }

  private dateRangesByCalendarHeaderMode(activeDate: D | null): DateRange<D> {
    const calendarMode =
      this.datepickerCalendarService.currentMode ||
      this.datepickerCalendarService.getPrevMode;
    if (calendarMode === RangeDatePickerTab.week) {
      return this._createSixDayRange(activeDate);
    } else {
      return new DateRange<D>(null, null);
    }
  }
}
