import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { RangeDatePickerTab } from '@core/enums';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { DateRangeService } from '../datepicker/date-range.service';

@Injectable({ providedIn: 'root' })
export class DatepickerCalendarService {
  constructor(private dateRangeService: DateRangeService) {}

  public get getPrevMode(): string {
    return this.calendarMode.value.prevMode;
  }

  public get currentMode(): string {
    return this.calendarMode.value.currentMode;
  }

  public calendarMode: BehaviorSubject<{
    prevMode: string;
    currentMode: string;
  }> = new BehaviorSubject({ prevMode: null, currentMode: 'day' });
  public months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  /**
   * disables dialog from being closed by clicking outside of it
   */
  public disableDialog(
    selectedMode: string,
    dialogRef: MatDialogRef<any>
  ): void {
    dialogRef.disableClose = selectedMode === RangeDatePickerTab.range ||
      selectedMode === RangeDatePickerTab.week;
  }

  /**
   * @param selectedMode: string,
   * @param date: Date,
   * @param fact-check-range: DateRange<Date>
   * @returns DateRange<Date>
   */
  public datepickerClose(
    selectedMode: string,
    date: Date,
    range: DateRange<Date>
  ): DateRange<Date> {
    switch (selectedMode) {
      case RangeDatePickerTab.day:
        return new DateRange(date, date);
      case RangeDatePickerTab.week:
        return range;
      case RangeDatePickerTab.year:
        return range;
      case RangeDatePickerTab.range:
        return range;
    }
  }

  /**
   * gets calendar tab index
   * @param selectedMode string
   * @returns
   */
  public getTabIndex(selectedMode: string): number {
    return Object.keys(RangeDatePickerTab).indexOf(selectedMode);
  }

  /**
   * returns one of two calendar modes, based on which one exists
   * @returns string
   */
  public getCurrentMode(): string {
    return (
      this.calendarMode.value.prevMode || this.calendarMode.value.currentMode
    );
  }

  /**
   * emits calendarMode behaviorSubject's next value
   * @param prevMode string
   * @param currentMode string
   */
  public updateSelectedModes(prevMode: string, currentMode: string): void {
    this.calendarMode.next({
      prevMode,
      currentMode,
    });
  }

  /**
   * returns calendar's prevMode or current mode based on a condition
   * @param modalData  DateRange<moment.Moment>
   * @returns string
   */
  public getCalendarLastMode(modalData: DateRange<moment.Moment>): string {
    if (modalData.start && modalData.end) {
      return this.calendarMode.value.prevMode;
    } else {
      return this.calendarMode.value.currentMode;
    }
  }

  /**
   * checks if dates are equal, without time
   */
  public isSameDate(): any {
    const moment1 = this.dateRangeService.currentDate.format('MM/D/YYYY');
    const moment2 = moment().format('MM/D/YYYY');
    return moment1 === moment2;
  }
}
