import { ElementRef, Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  public unsubscribe$: Subject<void> = new Subject();
  constructor() {}

  /**
   * extracts the data:image/ part of base64 string
   * @param base64 string
   * @returns string
   */
  public extractImageTypeFromBase64(base64: string): string {
    return base64.substring('data:'.length, base64.indexOf(';base64'));
  }

  public returnDate(obj: { date?: string; created_at?: string }): Date {
    if (obj?.date) {
      return new Date(obj.date);
    } else {
      return new Date(obj.created_at);
    }
  }

  public sortArrays<
    T extends { date: string },
    G extends { date?: string; created_at?: string }
  >(array1: T[], array2: G[]): (T | G)[] {
    const mergedArray = [...array1, ...array2];
    return mergedArray.sort(
      (a, b) => this.returnDate(a).getTime() - this.returnDate(b).getTime()
    );
  }

  isNullOrUndefined(value: any): boolean {
    return Object.is(value, null) || Object.is(value, undefined);
  }
}
