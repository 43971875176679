import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class RoundQuestionFormService {
  constructor() {}

  public removeFormControls(controlName: string, formGroup: FormGroup): void {
    if (formGroup.get(controlName)) {
      formGroup.removeControl(controlName);
    }
  }
}
