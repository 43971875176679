import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageCropperComponent, ImageTransform} from 'ngx-image-cropper';
import {ImageModalData} from '@models/modals-datas/image-modal-data/image-modal-data.interface';
import {PopupData} from '@constants/popup-data.constants';
import {ImageUploadService} from '@core/api-services';

@Component({
  selector: 'app-image-upload-modal',
  templateUrl: './image-upload-modal.component.html',
  styleUrls: ['./image-upload-modal.component.scss'],
})
export class ImageUploadModalComponent implements OnInit {
  @ViewChild(ImageCropperComponent) private imageCropper: ImageCropperComponent;

  public imageChangedEvent!: Event;
  public isImageLoaded: boolean = !this.data.imageUrl;
  public canvasRotation: number = 0;
  public scale: number = 1;
  public transform: ImageTransform = {};
  public uploadFailed: boolean = false;
  public isNotImage: boolean = false;
  public isInvalidImage: boolean = false;
  public popupData = PopupData;

  private _preview = false;

  constructor(
    private imageUploadService: ImageUploadService,
    private dialogRef: MatDialogRef<ImageUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageModalData
  ) {
  }

  ngOnInit(): void {
  }

  private resetCanvasRotation(fn: () => void): void {
    if (Math.abs(this.canvasRotation) === 3) {
      this.canvasRotation = 0;
      return;
    }
    fn();
  }

  public async fileChangeEvent(event: Event): Promise<void> {
    this.imageChangedEvent = event;
    const {type} = (event.target as HTMLInputElement).files[0];
    if (!type.match('image')) {
      this.isNotImage = true;
      return
    }

    this.isNotImage = false;
    this.imageChangedEvent = event;
    this.uploadFailed = false;
    this._preview = true;
  }

  public async compressFile(base64: string): Promise<string> {
    let file: File;
    const files: FileList = (this.imageChangedEvent?.target as HTMLInputElement)?.files
    if (!!files?.length) {
      file = files[0];
    }
    return this.imageUploadService.compressFile(
      file || this.imageUploadService.base64ToFile(base64),
      base64
    );
  }

  public loadImageFailed(): void {
    this.data.imageUrl = null;
    this.isInvalidImage = true;
    this.uploadFailed = true;
    this.isImageLoaded = true;
  }

  /**
   * Passes image to upload service
   */
  public async processFile(): Promise<void> {
    const croppedImage = this.imageCropper.crop();
    const result = await this.compressFile(croppedImage.base64);
    this.dialogRef.close(result);
  }

  public imageLoaded(): void {
    this.isImageLoaded = true;
  }

  public resetImage(): void {
    this.scale = 1;
    this.canvasRotation = 0;
    this.transform = {};
  }

  public rotateLeft(): void {
    this.resetCanvasRotation(() => this.canvasRotation--);
  }

  public rotateRight(): void {
    this.resetCanvasRotation(() => this.canvasRotation++);
  }

  public zoomOut(): void {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  public zoomIn(): void {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  public close(): void {
    this.dialogRef.close(this.data.imageUrl);
  }

  get title(): string {
    let title = this.data.imageType.replace(/_/g, ' ');
    title = title.charAt(0).toUpperCase() + title.slice(1);
    title += ' Image';
    return title;
  }
}
