export enum RoundTypes {
  default = 'default',
  ct = 'ct',
}

export type RoundType = {
  [key in 'multiChoice' | 'range' | 'bonus']?: QuestionType;
};

export interface QuestionType {
  questionTypeId: number | number[];
  questionsCount: number;
  questionTypeName: string;
};

export interface IsQuestionCountEnough {
  availableQuestionsCount: number;
  questionTypeId: number;
  question: QuestionType;
}

export interface AvailableQuestionsCountForGameResponse {
  question_count?: number;
  questionTypeId?: number;
  question?: QuestionType;
  remaining?: number;
  questionTypeName?: string;
  point_value?: number;
  point_value_count?: number;
}
