import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { QuestionDefaultAnswersCount, QuestionTypeIds } from '@core/enums';

@Injectable({ providedIn: 'root' })
export class QuestionTypeUtilityService {
  constructor(private fb: FormBuilder) {}

  public patchValuesToFormArray<T>(
    formArray: FormArray,
    items: T[],
    fn: (item: T | FormGroup) => T | FormGroup
  ): FormArray {
    items.forEach((item) => {
      const groupValue = fn(item);
      formArray.push(
        groupValue instanceof FormGroup ? groupValue : this.fb.group(groupValue)
      );
    });
    return formArray;
  }

  public getAnswerCountByQuestionId(question_type_id: number): number {    
    switch (question_type_id) {
      case QuestionTypeIds.TWO_COLUMN:
        return QuestionDefaultAnswersCount.TWO_COLUMN_ANSWERS_COUNT;
      case QuestionTypeIds.PUZ_MATCHING:
        return QuestionDefaultAnswersCount.PUZ_MATCHING_ANSWERS_COUNT;
      case QuestionTypeIds.PICTURE_ROUND:
        return QuestionDefaultAnswersCount.PICTURE_ROUND_ANSWERS_COUNT;
      case QuestionTypeIds.CHOOSE_TEN:
        return QuestionDefaultAnswersCount.CHOOSE_TEN_ANSWERS_COUNT;
    }
  }
}
