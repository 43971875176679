import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DoAction, UpdateGameRequest } from '@core/models';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateGameService {
  constructor(private http: HttpClient) {}

  public updateGame(game_data: UpdateGameRequest): Observable<any> {
    const body: DoAction<UpdateGameRequest> = {
      do_action: 'f_game_update',
      data: game_data,
    };
    return this.http.post(`${environment.dctapiUrl}`, body)
  }
}
