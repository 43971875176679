import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Categories } from '@gql/graphql';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { DoActionRequestsService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class QuestionCategoriesService {
  private unsubscribe$: Subject<void> = new Subject();
  private _mainCategories: Categories[] = [];
  private _subCategories: Categories[] = [];
  public mainCategories$: BehaviorSubject<Categories[]> = new BehaviorSubject(
    []
  );
  public subCategories$: BehaviorSubject<Categories[]> = new BehaviorSubject(
    []
  );

  public readonly _categories$ = this.doActionRequestsService
    .getCategories()

  get mainCategories() {
    return this._mainCategories;
  }
  set mainCategories(value: Categories[]) {
    this._mainCategories = value;
    this.mainCategories$.next(value);
  }

  get subCategories() {
    return this._subCategories;
  }
  set subCategories(value: Categories[]) {
    this._subCategories = value;
    this.subCategories$.next(value);
  }

  constructor(private doActionRequestsService: DoActionRequestsService) {}

  public filterCategories(response: Categories[]): void {
    this.mainCategories = response.filter(
      (category) => !category.parent_category_id
    );
    this.subCategories = response.filter(
      (category) => category.parent_category_id
    );
  }

  public filterCategoryAndSubcategory(
    categories: Categories[],
    AllSubCategories: { [key: string]: Categories[] }
  ): {
    categories: Categories[];
    AllSubCategories: { [key: string]: Categories[] };
  } {
    const categoriesList: Categories[] = [];
    AllSubCategories = {};
    for (const category of categories) {
      if (category.parent_category_id) {
        if (!AllSubCategories[category.parent_category_id]) {
          AllSubCategories[category.parent_category_id] = [];
        }
        AllSubCategories[category.parent_category_id].push(category);
      } else {
        categoriesList.push(category);
      }
    }
    return { categories: categoriesList, AllSubCategories };
  }

  public filterSubcategories(
    catCtrl: AbstractControl,
    subCateCtrl: AbstractControl
  ): Observable<Categories[]> {
    return catCtrl.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
      switchMap((id) => {
        subCateCtrl.setValue('');
        subCateCtrl.markAsUntouched();
        return this._categories$.pipe(
          map((categories: Categories[]) => {
            return categories.filter(
              (category) => category.parent_category_id === id
            );
          })
        );
      })
    );
  }

  /**
   * Fetches categories from db
   * Creates array and map for categories and subcategories to use in question form selects
   */
  public fetchCategories(result: Categories[]): [
    Categories[],
    Categories[],
    {
      string: Categories[];
    }
  ] {
    const mainCategories = result.filter(
      (category) => !category.parent_category_id
    );
    const subCategories = result.filter(
      (category) => category.parent_category_id
    );
    const subCategoriesMap = subCategories.reduce((acc, item) => {
      // tslint:disable-next-line:no-unused-expression
      acc[item.parent_category_id] || (acc[item.parent_category_id] = []);
      acc[item.parent_category_id].push(item);
      return acc;
    }, {} as { string: Categories[] });
    return [mainCategories, subCategories, subCategoriesMap];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
