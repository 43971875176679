import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { DoAction } from '@core/models';

@Injectable({ providedIn: 'root' })
export class DeleteGameService {
  constructor(private http: HttpClient) {}

  deleteGame(game_id: string): Observable<any> {
    const body: DoAction<{ game_id: string }> = {
      do_action: 'f_delete_game_by_id',
      data: {
        game_id,
      },
    };

    return this.http.post(`${environment.dctapiUrl}`, body);
  }
}
