import { FormArray, ValidatorFn } from '@angular/forms';
import { QuestionTextImage, TwoColumnAnswerFormGroup } from '@models/index';

export interface TwoColumnFormGroup {
  question_text_image: QuestionTextImage;
  items: TwoColumnAnswerFormGroup[];
  hint: [string, ValidatorFn];
  question_text?: string,
  image?: string;
  question_type_id: number;
};
