export enum UserTypes {
  'admin',
  'manager',
  'compiler',
  'factchecker',
  'writer',
  'financial-admin',
  'warfare compiler',
  'triviamatic_manager',
}
