import { Injectable } from '@angular/core';
import { GameTemplatesQuery } from '@core/generated-gql/graphql';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { GameDataService } from '../compiler';

@Injectable({ providedIn: 'root' })
export class TriviaMaticTemplateOptionsService {
  public templateTypes: Observable<GameTemplatesQuery['trivia_game_templates']>;
  public popupOptionValue: BehaviorSubject<{
    value: string;
    stateIndex: number;
  }> = new BehaviorSubject({ value: '', stateIndex: 0 });
  constructor(private gameDataService: GameDataService) {}

  public getTemplateTypes(): Observable<
    GameTemplatesQuery['trivia_game_templates']
  > {
    const templates = this.gameDataService.gameTemplates().pipe(
      map((trivia_game_templates) => {
        return trivia_game_templates.filter(
          (item) =>
            item.template_id !== 'warfare' &&
            item.template_id !== 'automated' &&
            item.template_id !== 'ctb'
        );
      })
    );
    this.templateTypes = templates;
    return templates;
  }

  public addPopupIndex(): void {
    this.popupOptionValue.next({
      stateIndex: this.popupOptionValue.value.stateIndex + 1,
      value: this.popupOptionValue.value.value,
    });
  }

  public back(): void {
    this.popupOptionValue.next({
      stateIndex: this.popupOptionValue.value.stateIndex - 1,
      value: this.popupOptionValue.value.value,
    });
  }

  public changePopupOptionValue(value: string): void {
    this.popupOptionValue.next({
      stateIndex: this.popupOptionValue.value.stateIndex,
      value,
    });
  }
  public updatePopupOption(index: number, value: string): void {
    this.popupOptionValue.next({
      stateIndex: index,
      value,
    });
  }
}
