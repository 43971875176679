import {Injectable} from '@angular/core';
import {UserService} from '@services/user.service';
import {
  GetUserByIdQuery,
  GetUsersQuery,
  Trivia_Users_Mutation_Response,
  Trivia_Users_Set_Input,
} from '@gql/graphql';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CurrentUser, DoAction, UsersSearchRequest} from '@core/models';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env';
import {UtilityHelper} from "@core/helpers";

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  public userData: BehaviorSubject<CurrentUser> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {
  }

  getLoggedUserData(): Observable<CurrentUser> {
    return this.formUserDataById(true, this.userService.user.user_id);
  }

  formUserDataById(updateNext: boolean, userId: string): Observable<CurrentUser> {
    return this.getUserDataById(userId).pipe(
      catchError((err) => {
        console.log(err);

        return of(null);
      }),
      map((e: GetUserByIdQuery['trivia_users'][0]) => {
        if (!e) {
          return this.formUserServiceUser();
        }
        return UtilityHelper.replaceStingNullFields(e);
      })
    );
  }

  public getUserDataById(user_id: string): Observable<any> {
    const body: DoAction<{ user_id: string }> = {
      do_action: 'f_user_by_id',
      data: {
        user_id,
      },
    };
    return this.http.post(`${environment.dctapiUrl}`, body)
      .pipe(map(user => UtilityHelper.replaceStingNullFields(user)));
  }

  private formUserServiceUser(): any {
    const {firstname, lastname, user_id} = this.userService.user;
    return {
      firstname,
      user_id,
      lastname,
      user_type: {
        user_type_name: this.userService.user.role,
      },
    };
  }

  public getUsersByTypeIdAndName(
    queryObject: UsersSearchRequest
  ): Observable<GetUsersQuery['trivia_users']> {
    const body: DoAction<UsersSearchRequest> = {
      data: queryObject,
      do_action: 'f_get_user_filter',
    };
    return this.http.post<GetUsersQuery['trivia_users']>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public updateTriviaUser(user_data: CurrentUser): Observable<any> {
    const body: DoAction<Trivia_Users_Set_Input> = {
      do_action: 'f_update_trivia_user',
      data: user_data,
    };
    return this.http.post(`${environment.dctapiUrl}`, body);
  }

  public changeUserStatus(status_id: number, user_id: string): Observable<Trivia_Users_Mutation_Response['returning']> {
    const body: DoAction<{ status_id: number, user_id: string }> = {
      data: {status_id, user_id},
      do_action: 'f_change_user_status'
    }
    return this.http.post<Trivia_Users_Mutation_Response['returning']>(`${environment.dctapiUrl}`, body)
  }
}
