import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export class CustomValidator {
  constructor() { }
  /**
   * whether the field value is not equal to the field by fieldName value
   * otherwise null
   *
   *
   * @return ValidatorFn
   * @param firstControlName
   * @param secondControlName
   */
  public static equal(
    firstControlName: string,
    secondControlName: string,
  ): null | ValidatorFn {
    return (group: FormGroup) => {
      const password = group.get(firstControlName);
      const confirmPassword = group.get(secondControlName);
      const areValuesEqual = confirmPassword.value === password.value;
      if ((confirmPassword.value.length > 0 && password.value.length > 0) || confirmPassword.dirty && !password.dirty) {
        if (areValuesEqual) {
            confirmPassword.setErrors(null);
        } else {
          confirmPassword.setErrors({ not_equal: true });
          return { not_equal: true };
        }
      }
    };
  }

  static required(control: AbstractControl): ValidationErrors | null {
    const value = (control.value || '').toString().trim();
    if (!value && control.value !== 0 && control.value !== false) {
      return {required: true};
    }
    return null;
  }

  static formRequire(firstControlName: string, secondControlName: string) {
    return (group: AbstractControl): null | ValidationErrors => {
      const firstControl = group.get(firstControlName);
      const secondControl = group.get(secondControlName);

      if (firstControl?.value && !secondControl?.value) {
        secondControl?.setErrors({required: true});
        secondControl?.markAsTouched();
        return {required: true};
      }
      if (!firstControl?.value && secondControl?.value) {
        firstControl?.setErrors({required: true});
        firstControl?.markAsTouched();
        return {required: true};
      }

      secondControl?.setErrors(null);
      firstControl?.setErrors(null);
      return null;
    }
  }
}
