import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  private addToken(request: HttpRequest<any>): HttpRequest<any> {    
    const isTriviaLogin = request.url.includes('trivia-login');    
    
    if (
      this.userService?.user?.token &&
      request.url.startsWith(environment.dctapiUrl) && !isTriviaLogin
    ) {      
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.userService.user.token}`,
        },
      });
    }
    return request;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addToken(request);
    return next.handle(request);
  }
}
