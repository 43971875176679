import { Injectable } from '@angular/core';
import { Question_Types } from '@core/generated-gql/graphql';
import {
  DoAction,
  FillCtmQuestionsRequest,
  QuestionData,
  RoundByPk,
  RoundByPkResponse,
  StandardRoundQuestionInterface,
  UpdateCtmWagerRequestParams,
  UpdateCtmWagerResult,
} from '@core/models';
import { lastValueFrom, Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GameRoundCount, QuestionTypeIds } from '@core/enums';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

type UpdateCtmRoundReturnType =
  | { round_id: string; question_order: number; question_id: string }[];

@Injectable({ providedIn: 'root' })
export class CtmRoundService {
  public readonly roundQuestionIds: Map<number, string> = new Map();
  public roundId: string;
  public wagerRanges = ['0 - 10', '0 - 20', '0 - 30', '0 - 40'];

  constructor(private fb: FormBuilder, private http: HttpClient) {}

  private isQuestionLastWagerInRound(round_order: number): boolean {
    return round_order === GameRoundCount.ctm;
  }

  public updateCtmRound(params: {
    selectedQuestions: QuestionData[];
    roundId: string;
    questions: {
      question_id?: string;
    }[];
    questionData: { data: QuestionData[] };
  }): UpdateCtmRoundReturnType {
    if (params?.questions?.length !== params.selectedQuestions.length) {
      return;
    }

    const data = params.questions.map((q, i) => ({
      question_id: q?.question_id,
      round_id: params.roundId,
      question_order: params.questionData.data.filter((question) =>
        params.selectedQuestions
          .map((sq) => sq.round_question_id)
          .includes(question.round_question_id)
      )[i].position, //TODO: check this code
    }));
    return data;
  }

  fillCtmRoundQuestionsHandler(
    $event: FillCtmQuestionsRequest,
    question: QuestionData,
    questionTypesMap: {
      [key: string]: Question_Types;
    }
  ) {

    const body: DoAction<FillCtmQuestionsRequest> = {
      data: {
        ...$event,
        point_value: question.point_value,
        question_type_id: question.question_type_id,
        count: 1,
        round_id: this.roundId,
      },
      do_action: 'f_fill_ctm_round_search_wf',
    };
    return this.http.post<{ result: { question_id: string }[] }>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  /**
   * Gets round Questions by filter
   * @param $event - fill round filter params
   * @param selectedQuestions - questions from checkbox selection
   */
  public async getQuestionsByFilter(
    $event: FillCtmQuestionsRequest,
    selectedQuestions: QuestionData[],
    questionTypesMap: {
      [key: string]: Question_Types;
    }
  ): Promise<{ question_id?: string }[]> {
    const questionsFromDB = [];
    for await (const question of selectedQuestions) {
      const fillCtmRoundQuestions = await lastValueFrom(
        this.fillCtmRoundQuestionsHandler($event, question, questionTypesMap)
      );
      if (fillCtmRoundQuestions.result.length) {
        questionsFromDB.push(fillCtmRoundQuestions.result[0]);
      }
    }

    return questionsFromDB;
  }

  public fillRoundFormArray(params: {
    round_question: RoundByPkResponse['trivia_round_questions'][0];
    qTypeShortName: string;
    answer_type: number;
  }): FormGroup {
    const { round_question, qTypeShortName, answer_type } = params;
    this.roundQuestionIds.set(
      round_question.question_order,
      round_question?.question?.question_id
    );
    return this.fb.group({
      question_id: round_question?.question?.question_id,
      round_question_id: round_question.id,
      answer_type: new FormControl({
        value: answer_type,
        disabled: qTypeShortName !== 'MC',
      }),
      point_value: round_question.question.point_value,
      question_type: qTypeShortName,
      category: round_question.question.category.category_id,
      sub_category: round_question.question.sub_category.category_id,
      question_text: round_question.question.question_text,
      question_order: round_question.question_order,
      answer: round_question.question.answer,
      question_type_id:
        round_question.question?.question_type?.question_type_id,
    });
  }

  public updateCtmWager(
    params: UpdateCtmWagerRequestParams
  ): Observable<UpdateCtmWagerResult> {
    const { id, wager, wager_range_value, question_id } = params;
    const body: DoAction<UpdateCtmWagerRequestParams> = {
      data: {
        wager,
        id,
        wager_range_value,
        question_id,
      },
      do_action: 'f_update_trivia_round_question',
    };
    return this.http.post<UpdateCtmWagerResult>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public populateRoundsTable(
    data: RoundByPk['trivia_round_questions']
  ): StandardRoundQuestionInterface[] {
    return data.map((q) => {
      return {
        round_question_id: q.id,
        position: q.question_order,
        question_id: q.question.question_id,
        question_type: q.question.question_type,
        point_value: q.question.point_value,
        category: q.question.category.category_id,
        sub_category: q.question.sub_category.category_name,
        questions: q.question.question_text,
        answer: q.question.answer,
      };
    });
  }

  // public updateSelectedWagerRange(res: RoundByPk, selectedWagerRange: number, round_order: number): number {
  //   const lastQuestion = res.trivia_round_questions[10];
  //   if (lastQuestion) {
  //     selectedWagerRange = this.wagerRanges.findIndex(
  //       (val) => val === lastQuestion.wager_range_value
  //     );
  //     if (selectedWagerRange < 0) {
  //       selectedWagerRange = this.setWagerRangeValue(round_order);
  //     }
  //   }
  //   return selectedWagerRange;
  // }

  public updateSelectedWagerRange(
    round_order: number,
    question_type_id: number
  ): number {
    const isRange = question_type_id === QuestionTypeIds.RANGE;
    if (isRange) {
      return this.setWagerRangeValue(round_order);
    }
  }

  public setWagerRangeValue(roundOrder: number): number {
    return this.isQuestionLastWagerInRound(roundOrder) ? 1 : 0;
  }
}
