import { I } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationConstants } from '@constants/form-validation.constants';
import { ValidationConstants } from '@constants/validation.constants';
import { QuestionDefaultAnswersCount } from '@core/enums';
import {
  PuzMatchingFormGroup,
  QuestionTextImage,
  TwoColumnAnswerFormGroup,
  TwoColumnFormGroup,
} from '@core/models';
import { QuestionTypeUtilityService } from './question-type.utility.service';

@Injectable({ providedIn: 'root' })
export class TwoColumnService {
  constructor(private fb: FormBuilder) {}

  public baseForm(questionData: TwoColumnFormGroup): FormGroup {
    const question_text =
      questionData?.question_text_image?.question_text ||
      questionData?.question_text;
    const formGroup = this.fb.group({
      question_text_image: this.fb.group({
        question_text: [
          '' || question_text,
          FormValidationConstants.questionTextValidator,
        ],
      }),
      hint: [
        questionData.hint,
        [Validators.maxLength(ValidationConstants.questionHintMaxLength)],
      ],
      items: this.generateAnswersFormArray(questionData),
    });
    return formGroup;
  }

  public generateAnswersFormArray(question: TwoColumnFormGroup): FormArray {
    const answersFormArray = this.fb.array([]);
    let items = question?.items;
    if (!items) {
      items = new Array(
        items?.length || QuestionDefaultAnswersCount.TWO_COLUMN_ANSWERS_COUNT
      );
      items.fill(null);
    }
    items.forEach((item) => {
      answersFormArray.push(this.generateItem(item));
    });
    return answersFormArray;
  }

  public fillAnswersFormArray(
    formArray: FormArray,
    items: TwoColumnAnswerFormGroup[]
  ): void {
    if (!items.length) {
      return;
    }
    items.map((e) => formArray.push(this.generateItem(e)));
  }

  generateItem(item?: TwoColumnAnswerFormGroup): FormGroup {
    return this.fb.group({
      firstMatch: this.generateMatchItem(item?.firstMatch?.itemtext),
      secondMatch: this.generateMatchItem(item?.secondMatch?.itemtext),
    });
  }

  generateMatchItem(itemText: string): FormGroup {
    const validators = FormValidationConstants.answerValidator;
    return this.fb.group({
      itemtext: [itemText, validators],
    });
  }

  // public createPuzMatchingFormGroup(
  //   question: PuzMatchingFormGroup
  // ): FormGroup {}
}
