import { TwoColumnFormGroup } from '@core/models/questions-form-groups';

export type TwoColumnRound = { firstMatch: string; secondMatch: string } & {
  questionTypeId: number;
  question_id: string;
};

export interface TwoColumnRoundQuestion {
  question_json: TwoColumnFormGroup;
  question_type_id: number;
  question_id: string;
};
