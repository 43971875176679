import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DoAction, RejectedQuestionsList } from '@core/models';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuestionListService {
  constructor(private http: HttpClient) {}

  public rejectedQuestionList(
    writer_user_id: string
  ): Observable<{ data: RejectedQuestionsList[]; count: number }> {
    const body: DoAction<{ writer_user_id: string }> = {
      do_action: 'f_questions_list_rejected',
      data: { writer_user_id },
    };
    return this.http.post<{ data: RejectedQuestionsList[]; count: number }>(
      `${environment.dctapiUrl}`,
      body
    );
  }
}
