import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ImageUploadService } from '@core/api-services';
import {
  Questions_Set_Input,
  ChangeQuestionTagsMutation,
  UpdateQuestionDataMutation,
  UpdateAnswerTypesMutation,
} from '@core/generated-gql/graphql';
import {
  AddQuestionsToRoundFnReturnType,
  OnAddToRound,
  BonusRoundData,
  StandardBlankRoundData,
  InsertQuestionToRoundArgs,
  UpdateCtmWagerResult,
} from '@core/models';
import { DoActionRequestsService } from '@core/services/do-action-requests.service';
import { MutationResult } from 'apollo-angular';
import { Observable, of, lastValueFrom, map, switchMap } from 'rxjs';
import {InsertAndUpdateQuestionService, MutateTriviaRoundQuestionService} from '@core/services';
import { RoundRequestsService } from '@core/services';
import { FormQuestionJsonTMService } from '@core/services/question';
import {ObjectHelper} from "@core/helpers";

@Injectable({ providedIn: 'root' })
export class MutateRoundQuestionService {
  constructor(
    private imageUploadService: ImageUploadService,
    private roundRequestsService: RoundRequestsService,
    private doActionRequestsService: DoActionRequestsService,
    private formQuestionJsonTMService: FormQuestionJsonTMService,
    private mutateTriviaRoundQuestionService: MutateTriviaRoundQuestionService,
    private insertAndUpdateQuestionService: InsertAndUpdateQuestionService
  ) {}

  public insertQuestions<T>(
    questions: T
  ): Promise<AddQuestionsToRoundFnReturnType | UpdateCtmWagerResult>[] {
    if (questions instanceof FormArray) {
      return questions.controls.map((group) => {
        if (group.untouched) {
          return;
        }
        const data = this.formQuestionJsonTMService.commonQuestionFormControls(
          group.value,
          true
        );
        return this.onAddToRound({
          questionData: data,
          gameTemplateId: 'ct',
          roundData: group.value,
        });
      });
    }
  }

  /**
   *
   * @returns
   * @param insertQuestionData
   */
  public insertNewQuestion(
    insertQuestionData: InsertQuestionToRoundArgs
  ): Observable<MutationResult<ChangeQuestionTagsMutation>> {
    return this.insertAndUpdateQuestionService.insertNewQuestion({
      newQuestionData: insertQuestionData.newQuestionData,
      tags: null,
      only_insert: true,
    });
  }

  private mutateRoundQuestion(params: {
    newForm: Questions_Set_Input;
    question_id: string;
    isQuestionInRound: boolean;
    round_question_id?: string;
    answer_type_id: number;
  }): Observable<
    MutationResult<ChangeQuestionTagsMutation> | UpdateQuestionDataMutation
  > {
    const { newForm, question_id, isQuestionInRound } = { ...params };
    // set question status to published
    newForm.question_status_id = 3;
    if (newForm.question_type_id === null) {
      return of(null);
    }

    if (!isQuestionInRound && question_id) {
      return this.insertNewQuestion({
        newQuestionData: newForm,
        tags: null,
        only_insert: true,
      });
    } else {
      return this.updateRoundQuestionData({ newForm, question_id });
    }
  }

  public updateRoundQuestionData(params: {
    newForm: any;
    question_id: string;
  }): Observable<ChangeQuestionTagsMutation> {
    return this.doActionRequestsService.updateQuestion({
      data: params.newForm,
      question_id: params.question_id,
    });
  }

  private async questionJsonApiREquests(
    params: OnAddToRound
  ): Promise<Questions_Set_Input> {
    const { questionData: question } = params;
    const question_json = ObjectHelper.trimObjectValues(
      params.questionData.question_json
    );
    question.question_status_id = 3;
    question.question_json =
      await this.imageUploadService.iterateOverQuestionJSON(question_json);
    question.question_image = await this.imageUploadService.uploadImages(
      question.question_image
    );
    return question;
  }

  public async onAddToRound(
    params: OnAddToRound
  ): Promise<AddQuestionsToRoundFnReturnType> {
    const question = await this.questionJsonApiREquests(params);
    const { roundData } = { ...params };
    const roundQuestions = await lastValueFrom(
      this.roundRequestsService
        .roundByPk(roundData.round_id)
        .pipe(map((e) => e.trivia_round_questions))
    );
    let isQuestionInRound = false;
    if (roundQuestions.length > 0) {
      isQuestionInRound = !!roundQuestions.find(
        (e) => e.question.question_id === question.question_id
      );
    }
    return lastValueFrom(
      this.mutateRoundQuestion({
        newForm: question,
        question_id: question.question_id,
        isQuestionInRound: isQuestionInRound,
        answer_type_id: params.answer_type_id,
      }).pipe(
        switchMap((data) => {
          const canUpdateAnswerTypes =
            roundData.round_question_id &&
            question.question_id &&
            roundData.answer_type_id;
          if (!data) {
            return of(null);
          }
          if (canUpdateAnswerTypes) {
            return this._updateAnswerTypes(roundData, question);
          } else if (!isQuestionInRound) {
            return this.mutateTriviaRoundQuestionService
              .addRoundQuestions([
                {
                  question_order: roundData.position || 1,
                  question_id: question.question_id,
                  round_id: roundData.round_id,
                },
              ])
              .pipe(
                switchMap((v) => {
                  return this._updateAnswerTypes(
                    { ...roundData, round_question_id: v.id },
                    question
                  ).pipe(
                    map(() => {
                      return { ...v, ...roundData };
                    })
                  );
                })
              );
          }
          return of(null);
        })
      )
    );
  }

  private _updateAnswerTypes(
    roundData: BonusRoundData | StandardBlankRoundData,
    question: Questions_Set_Input
  ): Observable<UpdateAnswerTypesMutation> {
    return this.doActionRequestsService.updateAnswerTypes({
      round_question_id: roundData.round_question_id,
      question_id: question.question_id,
      answer_type: roundData.answer_type_id,
    });
  }
}
