import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationConstants } from '@constants/form-validation.constants';
import { ValidationConstants } from '@constants/validation.constants';
import { RangeFormGroup } from '@core/models';
import {CustomValidator} from "@core/validators";

@Injectable({ providedIn: 'root' })
export class RangeService {
  constructor(private fb: FormBuilder) {}

  public rangeForm(questionData: RangeFormGroup): FormGroup {
    const formGroup = this.fb.group({
      question_text_image: this.fb.group({
        question_text: ['', FormValidationConstants.questionTextValidator],
        image: [''],
      }),
      right_answer: this.fb.group({
        right_answer_text: [
          '',
          [
            CustomValidator.required,
            Validators.pattern(ValidationConstants.NUMBERS_REGEXP),
          ],
        ],
        image: '',
      }),
      range: [
        '',
        [
          Validators.min(0),
          CustomValidator.required,
          Validators.pattern(ValidationConstants.NUMBERS_REGEXP),
        ],
      ],
      explanation: [
        '',
        [
          CustomValidator.required,
          Validators.maxLength(
            ValidationConstants.questionExplanationMaxLength
          ),
        ],
      ],
      hint: [
        '',
        [Validators.maxLength(ValidationConstants.questionHintMaxLength)],
      ],
    });
    if (questionData) {
      const { question_text, image } = questionData?.question_text_image || {};
      formGroup.patchValue({
        ...questionData,
        question_text_image: {
          question_text: questionData.question_text || question_text,
          image,
        },
      });
    }
    return formGroup;
  }
}
