import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DoAction } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class QuestionOrderOfQueueService {
  constructor(private http: HttpClient) {}

  public getQuestionOrder(questionId: string): Observable<{ RowNum: string }> {
    const body: DoAction<{ question_id: string }> = {
      do_action: 'f_question_get_row_number',
      data: { question_id: questionId },
    };
    return this.http.post<{ RowNum: string }>(`${environment.dctapiUrl}`, body);
  }
}
