import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportedQuestionLogData } from '@core/models';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReportedQuestionService {
  constructor(private http: HttpClient) {}

  public getReportedQuestionData(
    questionId: string
  ): Observable<ReportedQuestionLogData[]> {
    const url = environment.triviaMaticBaseApiUrl;
    return this.http.get<ReportedQuestionLogData[]>(
      `${url}gameplay/reports
    `,
      { headers: { question_id: questionId } }
    );
  }
}
