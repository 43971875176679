<div class="confirm-modal p-4">
  <h3> {{data?.title}} </h3>
  <img alt="message svg" *ngIf="data?.status" [src]="isSuccess ? 'assets/svg/success.svg' : 'assets/svg/error.svg'" />
  <span class="dialog-header" *ngIf="data?.message">
    {{data?.message}}
  </span>
  <ul *ngFor="let item of data?.list">
    <li>{{item}}</li>
  </ul>
  <div class="buttons d-flex justify-content-center">
    <button [ngClass]="{'radius-button': data?.onlyConfirm}" class="round-button button-text main m-2 mt-5"
      mat-flat-button color="primary" (click)="answer(true)">{{data?.onlyConfirm ? 'OK': 'Yes'}}</button>
    <button *ngIf="!data?.onlyConfirm" class="round-button button-text m-2 mt-5" mat-stroked-button
      (click)="answer(false)">No</button>
  </div>
</div>
