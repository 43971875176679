import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DoAction, GameByIdResponse, GameDataType } from '@core/models';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { MatSnackbarService } from '../../mat-snakbar/mat-snakbar.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { GetGamesService } from './get-games.service';
@Injectable({
  providedIn: 'root',
})
export class SaveGameService {
  public gameSaveEmitter: Subject<() => void> = new Subject();
  constructor(
    private http: HttpClient,
    private getGameService: GetGamesService,
    private matSnackbarService: MatSnackbarService
  ) {}

  private gameId: string;

  get getGameId(): string {
    return this.gameId;
  }

  set setGameId(value: string) {
    this.gameId = value;
  }

  /** Validates game data and saves with active status */

  public async saveGame(
    submit: boolean = false,
    game_data: {
      gameForm: FormGroup;
      gameId?: string;
      gameStatus?: number;
      gameData?: GameDataType;
    },
    showMessage: boolean = true
  ): Promise<boolean> {
    const gameData = {
      ...game_data.gameForm.getRawValue(),
      game_status: game_data.gameStatus,
      ...game_data.gameData,
    };

    gameData.game_status = game_data.gameStatus;
    gameData.description = gameData.description?.trim();
    gameData.title = gameData.title?.trim();
    const actName = submit ? 'submitted' : 'saved';
    const actNameN = submit ? 'submit' : 'save';
    const body: DoAction<any> = {
      do_action: 'f_game_update',
      data: { id: game_data.gameId, game_data: gameData },
    };

    if (game_data.gameForm.invalid) {
      game_data.gameForm.markAllAsTouched();
      this.matSnackbarService.error(`Failed to ${actNameN} the game!`);
      return false;
    }
    try {
      await lastValueFrom(this.http.post(`${environment.dctapiUrl}`, body));
      if (showMessage) {
        this.matSnackbarService.success(`Game ${actName}!`);
      }
      return true;
    } catch (e) {
      this.matSnackbarService.error(`Failed to ${actNameN} the game!`);
      return false;
    }
  }

  /** gets trivia game by game id
   * @param gameId string
   * @returns Observable<GameByPkQuery['trivia_games_by_pk']>
   */
  public fetchGame(gameId: string): Observable<GameByIdResponse> {
    return this.getGameService.getGameById(gameId);
  }
}
