<div class="container text-center" [formGroup]="reportForm">
  <h2 class="text-center">Report Question</h2>
  <mat-form-field class="w-100">
    <mat-label> Report Reason </mat-label>
    <mat-select formControlName="report_reason_id" required>
      <mat-option *ngFor="let reportReason of data.reportReasons" [value]="reportReason.report_reason_id">
        {{ reportReason.report_reason_name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="reportForm.get('report_reason_id').hasError('required')">This field is required</mat-error>
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label> Add Comment </mat-label>
    <textarea class="no-resize" matInput rows="5" formControlName="message" required
      [attr.maxlength]="validationConstants.reportQuestionCommentMaxLength+1"></textarea>
    <mat-error *ngIf="reportForm.get('message').hasError('required')">This field is required</mat-error>
    <mat-error *ngIf="reportForm.get('message').hasError('pattern')">This field shouldn't be empty</mat-error>
    <mat-error *ngIf="reportForm.get('message').hasError('maxlength')">Maximum characters length is
      {{ validationConstants.reportQuestionCommentMaxLength}}</mat-error>
  </mat-form-field>
  <button mat-button [disabled]="reportForm.invalid" type="submit" class="m-2 button-primary mat-button-base add-btn mx-auto" (click)="submit()">
    Submit
  </button>
</div>
