import { Injectable } from '@angular/core';
import { ValidationConstants } from '@constants/validation.constants';
import { MidpointRoundTwoColumn } from '@core/models';

@Injectable({ providedIn: 'root' })
export class WarfareDataService {
  constructor() {}

  public isMidpointRoundValid(
    midpointRound: MidpointRoundTwoColumn[]
  ): boolean {
    return ValidationConstants.pointDivisionNumer % midpointRound.length === 0;
  }


  
}
