import { Injectable } from '@angular/core';
import { Categories } from '@core/generated-gql/graphql';

@Injectable({ providedIn: 'root' })
export class RoundDataService {
  public gameId: string
  public readonly displayColumns = [
    'position',
    'question_type',
    'point_value',
    'category',
    'sub_category',
    'questions',
    'answer',
    'answer_type',
    'flag',
  ];
  constructor() {}
}
