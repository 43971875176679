import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import {
  ConfirmPasswordResponse,
  Credentials,
  DoAction,
  EmailVerificationResponse,
  UserLogin,
} from '@models/index';
import { Observable } from 'rxjs';
import { UserService } from '@services/user.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;
  constructor(private http: HttpClient, private userService: UserService) {}

  get getToken(): string {
    return this.token;
  }

  set setToken(token: string) {
    this.token = token;
  }

  public login(credentials: Credentials): Observable<UserLogin> {
    const body: DoAction<Credentials> = {
      data: credentials,
      do_action: 'f_user_login',
    };
    return this.http
      .post<UserLogin>(`${environment.loginUrl}`, body)
      .pipe(tap((res) => this.persistToStorage(res, credentials)));
  }

  public persistToStorage(res: UserLogin, credentials: Credentials): void {
    this.userService.setStorage(credentials.remember);
    this.userService.user = {
      token: res.token_id,
      user_id: res.user_id,
      role: res.user_role,
    };
  }

  public sendEmailForRecoveryCode(
    email: string,
    userTypeId: number
  ): Observable<EmailVerificationResponse> {
    const url = 'auth/forgot-password';
    return this.http.post<EmailVerificationResponse>(
      `${environment.restApiUrl}${url}`,
      { email, userTypeId }
    );
  }

  public sendRecoverKey(userKey: string): Observable<ConfirmPasswordResponse> {
    const url = 'auth/forgot-password-get-by-key';
    return this.http.post<ConfirmPasswordResponse>(
      `${environment.restApiUrl}${url}`,
      { userKey }
    );
  }

  public confirmPassword(password: string, token: string): Observable<any> {
    const url = 'auth/forgot-password-change';
    return this.http.post<any>(`${environment.restApiUrl}${url}`, {
      password,
      token,
    });
  }

  public changePassword(
    password: string,
    newPassword: string
  ): Observable<any> {
    const url = 'auth/change-password';
    return this.http.post<any>(`${environment.restApiUrl}${url}`, {
      password,
      newPassword,
    });
  }

  public checkPassword(password: string): Observable<{ response: boolean }> {
    const url = `auth/check-password`;
    return this.http.post<{ response: boolean }>(
      `${environment.restApiUrl}${url}`,
      { password }
    );
  }
}
