export const TwoColumnRoundConst = {
    round_id: '',
    round_type: '',
    firstMatch: {
        itemtext: ''
    },
    secondMatch: {
        itemtext: ''
    }
};

