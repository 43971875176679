import { Injectable } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ValidationConstants } from '@constants/validation.constants';
import { QuestionTypeIds } from '@core/enums';

@Injectable({ providedIn: 'root' })
export class QuestionTypeValidatorsService {
  constructor() {}

  public addCommonFieldValidators(validation: {
    validators: ValidatorFn | ValidationErrors;
  }): void {
    if (validation?.validators) {
      validation.validators['questions'] = [
        Validators.required,
        Validators.maxLength(ValidationConstants.questionTextMaxLength),
        Validators.pattern(ValidationConstants.NO_WHITE_SPACE),
      ];
      validation.validators['question_text'] = [
        Validators.required,
        Validators.maxLength(ValidationConstants.questionTextMaxLength),
        Validators.pattern(ValidationConstants.NO_WHITE_SPACE),
      ];
    }
  }
}
