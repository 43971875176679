import { Injectable } from '@angular/core';
import { Trivia_Game_Category } from '@gql/graphql';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';
import { TriviaGameCategory } from '@core/enums';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { DoAction } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class GameRoundCategoryService {
  constructor(private http: HttpClient) {}

  private mainCategories$: Observable<Trivia_Game_Category[]> =
    this._getGameCategories();

  get getMainCategories(): Observable<Trivia_Game_Category[]> {
    return this.mainCategories$;
  }

  private _getGameCategories(): Observable<any> {
    const body: DoAction<{}> = {
      do_action: 'f_trivia_game_category_list',
      data: {},
    };
    return this.http
      .post<Trivia_Game_Category[]>(`${environment.dctapiUrl}`, body)
      .pipe(
        map((result) => {
          const filteredResult = result.filter(
            (e) => e.id !== TriviaGameCategory.TriviaMatic_Manager_Custom
          );
          return filteredResult;
        })
      );
  }
}
