import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TwoColumnRoundConst } from '@constants/compiler-games-questions/two-column-round-form.constants';
import { RoundByPkQuery, RoundQuestionByRoundIdQuery } from '@gql/graphql';
import {
  StandardRoundData,
  TwoColumnRound,
  RoundForms,
  TwoColumnRoundQuestion,
  FormRoundQuestionsArgs,
  GetRoundsDataArgs,
  RoundByPkResponse,
} from '@models/index';
import { forkJoin, lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AnswerTypeIdEnum,
  DefaultGameEnum,
  GameTemplatesEnum,
  QuestionTypeIds,
  Roles,
} from '@core/enums';
import { UserService } from '../user.service';
import {
  standardGameForm,
  tmStandardRoundForm,
} from '@constants/round-forms.constants';
import { RoundQuestionTableService, RoundRequestsService } from './round';
import { QuestionTypeConstants } from '@constants/question-types.constants';
import { FormQuestionJsonTMService } from '../question';

type RoundByPk = RoundByPkQuery['trivia_rounds_by_pk'];

@Injectable({
  providedIn: 'root',
})
export class PopulateRoundsDataService {
  constructor(
    private userService: UserService,
    private roundRequestsService: RoundRequestsService,
    private roundQuestionTableService: RoundQuestionTableService,
    private formQuestionJsonTMService: FormQuestionJsonTMService
  ) {}
  public roundQuestionIds: Map<number, any> = new Map();
  private tableData: MatTableDataSource<any> = new MatTableDataSource([]);

  get getRoundQuestionIds(): Map<number, any[]> {
    return this.roundQuestionIds;
  }

  set setRoundQuestionIds(questionId: Map<number, any[]>) {
    if (questionId.size >= 0) {
      this.roundQuestionIds = questionId;
    }
  }

  /**
   * maps through trivia_round_questions array gets round_order values and
   * fills roundQuestionIds Map object with round_number as a key and question_id as a value and
   * then returns the result of each round formatted data
   * @param data - questions list
   */
  public populateStandardRoundsTable(
    data: RoundByPkResponse & { gameTemplateId: string; round_type?: string }
  ): StandardRoundData[] {
    const { round_id, trivia_round_type } = data;
    const questions = data.trivia_round_questions.map(
      (q: RoundByPk['trivia_round_questions'][0]) => {
        const { question: roundQuestion } = q;
        const questionTypeId =
          roundQuestion?.question_type_id ||
          roundQuestion?.question_type.question_type_id;
        const roundOrder = data.trivia_rounds.round_order;
        const hasRound = this.roundQuestionIds.has(roundOrder); // checks if that question's round_order exists in an roundQuestionIds map
        if (hasRound) {
          this.roundQuestionIds.get(roundOrder).push(roundQuestion.question_id);
        }

        if (questionTypeId !== QuestionTypeIds.TWO_COLUMN) {
          if (!hasRound && data.gameTemplateId === 'warfare') {
            this.roundQuestionIds.set(roundOrder, [roundQuestion.question_id]);
          }
        }
        const d = this.formatTableQuestion({
          question: { ...q } as RoundByPk['trivia_round_questions'][0],
          roundId: round_id,
          roundType: trivia_round_type.id,
          round_order: roundOrder,
        });
        return null;
      }
    );

    this.roundQuestionIds = new Map(
      [...this.roundQuestionIds.entries()].sort()
    );
    return questions;
  }

  public decideBonusRoundForm(questionTypeId: number): RoundForms {
    switch (questionTypeId) {
      case 13:
        return TwoColumnRoundConst;

      default:
        return standardGameForm;
    }
  }
  /**
   * formats trivia round questions; each question for displaying in mat table
   * @param question RoundByPk['trivia_round_questions'][i]
   * @param roundId string
   * @param roundType string
   * @returns
   */
  public formatTableQuestion(params: {
    question: Partial<
      | RoundByPk['trivia_round_questions'][0]
      | RoundQuestionByRoundIdQuery['trivia_round_questions'][0]
    >;
    roundId: string;
    roundType: string;
    round_order: number;
    gameTemplateId?: string;
  }): StandardRoundData {
    const { question: roundQuestion } = { ...params.question };
    const formattedQuestion = this.roundQuestionTableService.formCommonData(
      params.question,
      params.roundId,
      params.roundType,
      params.round_order
    );
    this.roundQuestionTableService.addStandardRoundFields(
      roundQuestion,
      params.roundType,
      formattedQuestion
    );
    this.roundQuestionTableService.addTMRoundFields(
      roundQuestion,
      formattedQuestion,
      params.roundType
    );
    const hasRound = this.roundQuestionIds.has(params.round_order); // checks if that question's round_order exists in an roundQuestionIds map
    if (roundQuestion.question_type_id !== 13) {
      if (!hasRound && params.gameTemplateId === 'warfare') {
        this.roundQuestionIds.set(params.round_order, [
          roundQuestion.question_id,
        ]);
      }
    }
    return formattedQuestion as StandardRoundData;
  }

  /**
   * @param  params GetRoundsDataArgs
   */
  public async getRoundsData(params: GetRoundsDataArgs): Promise<any> {
    let blankRoundData: RoundForms;
    const observables = params.rounds.map((round) => {
      blankRoundData = this.getBlankRoundForm(
        params.gameTemplateId,
        round.round_type
      );

      // if (this.userService.user.role === Roles.triviamaticManager) {
      //   standardGameForm['image'] = '';
      //   standardGameForm['answer_type_id'] = AnswerTypeIdEnum.input;
      // }
      return this.roundRequestsService
        .roundByPk(round.round_id, params.include_json)
        .pipe(
          tap((response) => {
            return response;
          }),
          map((response) => response),
          map((result) => {
            const questions = result;
            // return this.generateRoundQuestionsForm({
            //   trivia_round_data: questions,
            //   round,
            //   gameTemplateId: params.gameTemplateId,
            //   blankRoundData,
            //   bonusRoundQuestionsCount: params.bonusRoundQuestionsCount,
            //   standardRoundQuestionsCount: params.standardRoundQuestionsCount,
            // });
            return null
          })
        );
    });
    return await lastValueFrom(forkJoin(observables));
  }

  private getBlankRoundForm(gameTemplateId: string, roundType: string): any {
    switch (gameTemplateId) {
      case GameTemplatesEnum.trivialWarfare:
        if (roundType !== 'bonus') {
          return standardGameForm;
        }
        break;
      case GameTemplatesEnum.ct:
        if (roundType === 'bonus') {
          return null;
        }
        if (this.userService.user.role === Roles.triviamaticManager) {
          return tmStandardRoundForm;
        }
      default:
        return null;
    }
  }

  /**
   * based on round type calls round questions formatting functions and then returns formatted round data
   * @param roundbypk  RoundByPkQuery['trivia_rounds_by_pk'],
   * @param round Rounds
   * @param gameTemplateId string
   * @param blankRoundData RoundForms
   * @returns RoundForms[]
   */
  generateRoundQuestionsForm<T>(params: FormRoundQuestionsArgs): RoundForms[] {
    if (!params?.gameTemplateId) {
      return;
    }
    const { round_type, round_id, round_order } = params?.round;
    let roundQuestionsLimit = params.standardRoundQuestionsCount;
    if (round_type === 'bonus') {
      roundQuestionsLimit = params.bonusRoundQuestionsCount;
    }
    const questionTypeId = this.setQuestionTypeOnRound({
      gameTemplateId: params?.gameTemplateId,
      roundType: round_type,
      round_order,
    });
    const roundQuestions = this.generateRoundQuestionsArray({
      roundQuestionsLimit,
      roundTypeInfo: {
        round_type,
        round_id,
        round_order,
        gameTemplateId: params?.gameTemplateId,
        question_type_id: questionTypeId,
        answer_type_id: AnswerTypeIdEnum.input,
      },
      filledQuestions: params.trivia_round_data,
    });
    return roundQuestions;
  }

  /**
   * checks if there is filled questions formats that question and pushes
   *  in local array otherwise calls a function which populates objects for blank
   *  question and result pushes in the same local array then returns array
   * @param roundQuestionsLimit number
   * @param roundTypeInfo RoundForms
   * @param filledQuestions RoundByPkQuery['trivia_rounds_by_pk']
   * @returns  RoundForms[]
   */
  public generateRoundQuestionsArray(params: {
    roundQuestionsLimit: number;
    roundTypeInfo: RoundForms;
    filledQuestions?: RoundByPkResponse;
  }): RoundForms[] {
    const { gameTemplateId, round_order, round_type } = params.roundTypeInfo;
    const { trivia_round_questions: round_questions = [] } = {
      ...params.filledQuestions,
    };
    const { round_id, trivia_round_type } = params.filledQuestions;
    let filledArray = Array(params.roundQuestionsLimit);
    filledArray.fill(null);
    filledArray = filledArray.map((e, i) => {
      const questionData = round_questions.find((question) => {
        return question?.question_order === i + 1;
      });
      const questionTypeId = this.setQuestionTypeOnRound({
        gameTemplateId,
        roundType: round_type,
        round_order,
        question_order: i + 1,
      });

      e = this.formatTableQuestion({
        question:
          questionData ||
          ({
            question_order: i + 1,
            answer_type_id: this.setAnswerTypeOnRoundQuestion(),
            question: {
              question_type_id: questionTypeId,
            },
          } as RoundByPkQuery['trivia_rounds_by_pk']['trivia_round_questions'][0]),
        roundId: round_id,
        roundType: trivia_round_type.name,
        round_order,
        gameTemplateId,
      });
      return e;
    });
    const questionTypeId = round_questions[0]?.question?.question_type_id;
    if (QuestionTypeConstants.bonusQuestionTypeIds.includes(questionTypeId)) {
      filledArray = this.formBonusRoundQuestions({
        ...params.filledQuestions,
        gameTemplateId: params.roundTypeInfo.gameTemplateId,
      });
      return filledArray;
    }

    return filledArray;
  }

  /**
   * formats two column round question
   * @param question TwoColumnRoundQuestion
   * @returns TwoColumnRound[]
   */
  private formTwoColumnRoundQuestion(
    question: TwoColumnRoundQuestion
  ): TwoColumnRound[] {
    if (!question?.question_json?.items) {
      return;
    }
    return question.question_json.items.map((item) => {
      const newItem = {
        firstMatch: item.firstMatch.itemtext,
        secondMatch: item.secondMatch.itemtext,
      };

      return {
        questionTypeId: question.question_type_id,
        question_id: question.question_id,
        ...newItem,
      };
    });
  }

  /**
   * decides which formatting function to call for which case then forms two objects for bonus
   *  question one in a standard format and one for actual bonus format
   * @param round RoundByPkQuery['trivia_rounds_by_pk'] & { gameTemplateId: string }
   * @returns RoundForms[]
   */
  private formBonusRoundQuestions(
    round: RoundByPkResponse & { gameTemplateId: string }
  ): RoundForms[] {
    let bonusRound: RoundForms[] = [];
    let bonusRoundTable = [];
    if (round.gameTemplateId === 'warfare') {
      round.trivia_round_questions.map((question) => {
        const { question: bonusQuestion } = question;
        switch (bonusQuestion.question_type.question_type_id) {
          case QuestionTypeIds.TWO_COLUMN:
            bonusRound = this.formTwoColumnRoundQuestion({
              question_json: bonusQuestion.question_json,
              question_type_id: bonusQuestion.question_type.question_type_id,
              question_id: bonusQuestion.question_id,
            });
        }
      });
    }
    const tableQuestions = this.populateStandardRoundsTable(round);
    if (bonusRound.length) {
      bonusRoundTable = [tableQuestions[0], bonusRound];
    } else {
      bonusRoundTable = [tableQuestions[0]];
    }
    return bonusRoundTable as RoundForms[];
  }

  /**
   * gets round data
   * @param roundId string
   * @returns Promise<RoundByPkQuery['trivia_rounds_by_pk']>
   */
  public async getRoundData(
    roundId: string
  ): Promise<RoundByPkResponse> {
    return await lastValueFrom(
      this.roundRequestsService.roundByPk(roundId, 1).pipe(
        map((result) => {
          return result;
        })
      )
    );
  }

  setQuestionTypeOnRound(params: {
    gameTemplateId: string;
    roundType: string;
    round_order?: number;
    question_order?: number;
  }): number {
    let questionTypeNameId = 0;
    if (params?.roundType === 'bonus') {
      if (params?.gameTemplateId === 'warfare') {
        questionTypeNameId = 13;
      } else {
        questionTypeNameId = null;
      }
    }

    if (
      params?.gameTemplateId === GameTemplatesEnum.default &&
      params?.round_order === DefaultGameEnum.final_round_order
    ) {
      questionTypeNameId = 7;
    }
    if (
      params?.gameTemplateId === GameTemplatesEnum.ct &&
      params.question_order === 11
    ) {
      questionTypeNameId = 7;
    }

    return questionTypeNameId;
  }

  private setAnswerTypeOnRoundQuestion(): number {
    if (this.userService.user.role === Roles.triviamaticManager) {
      return AnswerTypeIdEnum.input;
    }
  }
}
