import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DoAction } from '@core/models';
import { environment } from '@env';
import { AvailableQuestionsCountForGameResponse } from '@models/compiler/available-questions-count-for-game.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GameAvailableQuestionsCountService {
  constructor(private http: HttpClient) {}

  public getAvailableQuestionsCount(
    questionTypeId: number,
    for_round_type: string,
    point_value?: number
  ): Observable<AvailableQuestionsCountForGameResponse> {
    const body: DoAction<{
      question_type_id: number;
      point_value?: number;
      for_round_type: string;
    }> = {
      do_action: 'f_get_cmt_questions_count_type',
      data: { question_type_id: questionTypeId, for_round_type },
    };
    if (point_value >= 0) {
      body.data.point_value = point_value;
    }
    return this.http
      .post<AvailableQuestionsCountForGameResponse>(
        `${environment.dctapiUrl}`,
        body
      )
      .pipe(
        map((response) => {
          return { ...response, questionTypeId };
        })
      );
  }

  public getBonusQuestionsCount(
    point_value: number
  ): Observable<AvailableQuestionsCountForGameResponse> {
    const url = `/hasura-actions/get-bonus-questions-count`;
    const body: DoAction<{ point_value: number }> = {
      do_action: 'f_get_bonus_questions_count',
      data: { point_value },
    };
    return this.http.post<AvailableQuestionsCountForGameResponse>(
      `${environment.dctapiUrl}`,
      body
    );
  }
}
