import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { UserService } from '../services';
import {
  FileChangeResponse,
  FileResponse,
  FileUpload,
  PrizeInterface,
  RushWinnersInterface,
  WinnerUpdate,
} from '@core/models';
import {UtilityHelper} from "@core/helpers";

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private options = {
    headers: UtilityHelper.bearerToken(
      this.userService.user.token
    ),
  };

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}

  public getWinners(
    limit: number,
    page: number,
    text: string,
    sortBy = '',
    direction = 'desc'
  ): Observable<RushWinnersInterface> {
    text = encodeURIComponent(text);
    const url = `prizes/winners-list?limit=${limit}&page=${page}&searchText=${text}&sortBy=${sortBy}&direction=${direction}`;
    return this.http.get<RushWinnersInterface>(
      `${environment.triviaRushApiUrl}${url}`,
      this.options
    );
  }

  public getWinnerById(prizeId: string): Observable<PrizeInterface> {
    const url = `prizes/prize?prizeId=${prizeId}`;
    return this.http.get<PrizeInterface>(
      `${environment.triviaRushApiUrl}${url}`,
      this.options
    );
  }

  public getFile(prizeId: string, fileType: string): Observable<FileResponse> {
    const url = `prizes/file?prizeId=${prizeId}&fileType=${fileType}`;
    return this.http.get<FileResponse>(
      `${environment.triviaRushApiUrl}${url}`,
      this.options
    );
  }

  public saveFile(data: FileUpload): Observable<FileChangeResponse> {
    const url = `prizes/file`;
    const formData = new FormData();
    // The file must be always last in order
    formData.append('prizeId', data.prizeId);
    formData.append('fileType', data.fileType);
    formData.append('file', data.file);

    return this.http.post<FileChangeResponse>(
      `${environment.triviaRushApiUrl}${url}`,
      formData,
      this.options
    );
  }

  public updateStatus(data: WinnerUpdate): Observable<FileChangeResponse> {
    const url = `prizes/prize`;
    return this.http.patch<FileChangeResponse>(
      `${environment.triviaRushApiUrl}${url}`,
      data,
      this.options
    );
  }

  public removeFile(
    prizeId: string,
    fileType: string
  ): Observable<FileChangeResponse> {
    const url = `prizes/file?prizeId=${prizeId}&fileType=${fileType}`;
    return this.http.delete<FileChangeResponse>(
      `${environment.triviaRushApiUrl}${url}`,
      this.options
    );
  }

  public getPrizeStatusList(): Observable<any> {
    const url = `prizes/payment-statuses`;
    return this.http.get<any>(`${environment.triviaRushApiUrl}${url}`);
  }
}
