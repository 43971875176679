import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Questions } from '@core/generated-gql/graphql';
import { DoAction, QuestionSearch, QuestionSearchByFilter } from '@core/models';
import { environment } from '@env';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuestionSearchService {
  constructor(private http: HttpClient) {}

  public searchQuestion(data: any): Observable<QuestionSearch> {
    const body: DoAction<any> = {
      do_action: 'f_question_search',
      data,
    };
    console.log(data);

    return this.http.post<QuestionSearch>(`${environment.dctapiUrl}`, body);
  }

  public searchQuestionsByFilter(
    filterData: Partial<QuestionSearchByFilter> & {
      limit: number;
      offset: number;
    }
  ): Observable<{ data: Questions[]; count: number }> {
    const body: DoAction<
      Partial<QuestionSearchByFilter> & { limit: number; offset: number }
    > = {
      do_action: 'f_question_filter',
      data: filterData,
    };
    return this.http.post<{ data: Questions[]; count: number }>(
      `${environment.dctapiUrl}`,
      body
    );
  }
}
