import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangePasswordStatus } from '@core/enums';
import { ConfirmModalOptions } from '@models/modals-datas/confirm-modal-data/confirm-modal-data-options.interface';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  public isSuccess: boolean;
  public isFail: boolean;
  @Output() confirm = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalOptions,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  ngOnInit(): void {
    this.getStatus();
  }

  public answer(choice: boolean): void {
    this.confirm.emit(choice);
    this.dialogRef.close({ choice });
  }

  private getStatus(): void {
    this.isSuccess = this.data.status === ChangePasswordStatus.success;
    this.isFail = this.data.status === ChangePasswordStatus.fail;
  }
}
