import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Trivia_Event_Types, Trivia_Events } from '@core/generated-gql/graphql';
import { DoAction, TriviaEventRequest } from '@core/models';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ManagerService {
  constructor(private http: HttpClient) {}

  public triviaEventTypes(): Observable<Trivia_Event_Types[]> {
    const body: DoAction<{}> = {
      do_action: 'f_trivia_event_types_list',
      data: { },
    };
    return this.http.post<Trivia_Event_Types[]>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public triviaEvents(
    params: TriviaEventRequest
  ): Observable<Trivia_Events[]> {
    const body: DoAction<TriviaEventRequest> = {
      data: params,
      do_action: 'f_get_trivia_events',
    };

    return this.http.post<Trivia_Events[]>(`${environment.dctapiUrl}`, body);
  }
}
