import { Injectable } from '@angular/core';
import { Statistics } from '@models/statistics.interface';

@Injectable({
    providedIn: 'root'
})
export class FactcheckerStatisticsService {
    constructor() { }

    public get getFactcheckerStatistics(): Statistics {
        return this.factcheckerTitlesIcons();
    }

    private factcheckerTitlesIcons(): Statistics {
        return [
            {
                key: 'questions_published',
                title: 'Questions Accepted',
                icon: 'assets/svg/questions-accepted.svg'
            },
            {
                key: 'questions_rejected',
                title: 'Questions Rejected',
                icon: 'assets/svg/questions-rejected-writer.svg'
            },
            {
                key: 'questions_needs_review',
                title: 'Questions in Factchecking Queue',
                icon: 'assets/svg/factchecking-que.svg'
            }
        ];
    }

}
