export interface RandomRoundQuestionRequest {
  category_id?: string;
  question_type_short_name?: string;
  point_value?: number;
  sub_category_id?: string;
  market_id?: string;
  question_bonus_id?: string;
  question_difficulty_id?: string;
  is_mature?: boolean;
  tags?: string;
  game_template_id: string;
  question_text?: string;
  question_type_id?: number[] | number;
  for_round_type?: string;
  game_id: string;
  for_used_status?: string;
  include_json?: number;
  show_questions?:string[]
}
