import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserService } from '../services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const acceptedRoles: string[] = route.data.acceptedRole;
    return acceptedRoles.includes(this.userService.user.role);
  }
}
