import { Injectable } from '@angular/core';
import { QuestionTypeIds } from '@core/enums';
import { Questions } from '@core/generated-gql/graphql';
import { map, take } from 'rxjs/operators';
import { DoActionRequestsService } from '../do-action-requests.service';
import { DoAction } from '@core/models';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class UpdateQuestionJsonService {
  constructor(
      private http: HttpClient,
      private doActionRequestsService: DoActionRequestsService
  ) {}

  public updateRangeQuestionJson(): void {
    const body: DoAction<{question_type_id: number}> = {
      data: {
        question_type_id: QuestionTypeIds.RANGE,
      },
      do_action: 'f_question_json_by_type_id'
    }
    
    this.http.post(`${environment.dctapiUrl}`, body)
      .subscribe((e: Questions[]) => {
        return e.map((data) => {
          data.question_json.right_answer.right_answer_text = parseInt(
            data.question_json.right_answer.right_answer_text
              .toString()
              .replace(/\,/g, ''),
            10
          ).toLocaleString();
          if (data?.question_json?.answer) {
            data.question_json.answer = parseInt(
              data.question_json.answer.toString().replace(/\,/g, ''),
              10
            ).toLocaleString();
          }

          return this.doActionRequestsService
            .updateQuestion({
              data: {
                question_json: data.question_json,
                question_text: data.question_json.question_text,
              },
              question_id: data.question_id,
            })
            .pipe(take(1))
            .subscribe((e) => {
              console.log(e);
            });
        });
      });
  }
}
