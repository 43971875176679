import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {UserLogin} from '@core/models';
import {CheckTriviaUserService} from '@core/services';
import {environment} from '@env';
import {catchError, map, Observable} from 'rxjs';
import {AuthService} from '@core/api-services';
import {UtilityHelper} from "@core/helpers";

@Injectable({
  providedIn: 'root',
})
export class TriviaMaticManagerGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private checkTriviaUserService: CheckTriviaUserService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const token = route.queryParams.triviaUser;
    const options = UtilityHelper.bearerToken(token);
    return this.checkTriviaUserService.checkUser(options).pipe(
      catchError(() => {
        this.document.location.href = environment.triviaMaticBaseUrl;
        return null;
      }),
      map((e) => {
        this.authService.persistToStorage(e as UserLogin, {remember: false});
        this.router.navigate(['./triviamatic-manager/game/games']);
        return true;
      })
    );
  }
}
