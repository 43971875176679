import { Injectable } from '@angular/core';
import { ImageModalData } from '../models/modals-datas/image-modal-data/image-modal-data.interface';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ImageUploadModalComponent } from '@components/image-upload-modal/image-upload-modal.component';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpenImageModalService {
  constructor(private matDialog: MatDialog) {}

  public generateId(): number {
    return Math.floor(Math.random() * 1000000) + Date.now();
  }

  /**
   * Calls image upload modal
   * Sets form field value uploaded url
   * @param field - Image control field
   * @param width - Image width
   * @param height - Image height
   * @param imageType - Image type id
   * @param id - id
   */
  public openImageModal(
    { field, height, width, imageType }: ImageModalData,
    id
  ): Observable<any> {
    let oldSrc = field?.value;
    if (oldSrc) {
      oldSrc.concat(`?${Date.now()}`);
    }
    return this.matDialog
      .open(ImageUploadModalComponent, {
        height: '600px',
        disableClose: true,
        data: {
          height,
          width,
          imageId: id,
          imageType,
          imageUrl: oldSrc,
        },
      })
      .afterClosed()
      .pipe(take(1));
  }

  addUniqueIdToEndOfImageUrl(value: string): string {
    return value + '?=' + Math.floor(Math.random() * 100000) + Date.now();
  }
  /**
   * Removes image
   * @param imageControl - Image control field
   */
  public removeImage(imageControl: AbstractControl): void {
    imageControl.setValue(null);
  }
}
