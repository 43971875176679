import { Injectable } from '@angular/core';
import { QuestionTypeIds, Roles } from '@core/enums';
import { Questions } from '@core/generated-gql/graphql';
import {
  RoundByPk,
  StandardBlankRoundData,
  StandardRoundData,
  StandardRoundDataTM,
} from '@core/models';
import { UserService } from '@core/services/user.service';

@Injectable({ providedIn: 'root' })
export class RoundQuestionTableService {
  private _userRole = this.userService.user.role;
  private bonusPointQuestionTypeIds: number[] = [
    QuestionTypeIds.PUZ_MATCHING,
    QuestionTypeIds.CHOOSE_TEN,
    QuestionTypeIds.PICTURE_ROUND,
    QuestionTypeIds.CHOOSE_FROM_LIST,
    QuestionTypeIds.TWO_COLUMN,
    QuestionTypeIds.ONE_COLUMN,
    QuestionTypeIds.FILL_IN_BLANK,
  ];
  constructor(private userService: UserService) {}

  public formCommonData(
    question: Partial<RoundByPk['trivia_round_questions'][0]>,
    roundId: string,
    roundType: string,
    roundOrder: number
  ): any {
    const { question: roundQuestion } = { ...question };
    const answerTypeName = question?.answer_type?.answer_type_name;
    const data: StandardRoundDataTM = {
      question_id: roundQuestion?.question_id || '',
      question_type: roundQuestion?.question_type,
      round_id: roundId,
      round_type: roundType.toLocaleLowerCase(),
      round_order: roundOrder,
      round_question_id: question.id,
      wager: question.wager,
      wager_range_value: question?.wager_range_value,
      answer_type: answerTypeName === 'Same' ? 'MC' : answerTypeName,
      answer_type_id: question?.answer_type_id,
      position: question?.question_order,
      point_value: roundQuestion?.point_value,
      answer: (roundQuestion as Questions & { answer: string })?.answer,
      question_type_id: isNaN(roundQuestion?.question_type?.question_type_id)
        ? roundQuestion?.question_type_id
        : roundQuestion?.question_type?.question_type_id,
    };

    if (
      !this.bonusPointQuestionTypeIds.includes(data.question_type_id) &&
      typeof data.question_type_id === 'number'
    ) {
      data.questions = roundQuestion?.question_text || '';
    } else {
      data.question_text = roundQuestion?.question_text || '';
    }
    return data;
  }

  public addStandardRoundFields(
    roundQuestion: RoundByPk['trivia_round_questions'][0]['question'],
    roundType: string,
    formattedQuestion: StandardRoundData
  ): StandardRoundData {
    if (this._userRole === Roles.triviamaticManager) {
      return;
    }
    if (roundType === 'bonus') {
      formattedQuestion['bonus'] = roundQuestion.question_bonus_id;
    }

    formattedQuestion.category = roundQuestion?.category?.category_name;
    formattedQuestion.sub_category = roundQuestion?.sub_category?.category_name;

    return formattedQuestion;
  }

  public addTMRoundFields(
    question: RoundByPk['trivia_round_questions'][0]['question'],
    formattedQuestion: StandardRoundData,
    roundType: string
  ): StandardBlankRoundData {
    if (this._userRole !== Roles.triviamaticManager) {
      return;
    }
    if (roundType.toLowerCase() === 'bonus') {
      (formattedQuestion.category = question?.category?.category_id),
        (formattedQuestion.sub_category = question?.sub_category?.category_id);
    }
    if (question.question_json) {
      formattedQuestion['question_json'] = question.question_json;
    }
    formattedQuestion['image'] = question.question_image;
  }
}
