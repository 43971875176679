import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DoAction } from '@core/models';
import { environment } from '@env';
import { RoundQuestionState } from '@models/compiler/round-question-state.interface';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoundQuestionStateService {
  private roundQuestionState = new Map();
  private currentFillableQuestionOreder: number;

  get getCurrentFillableQuestionOreder(): number {
    return this.currentFillableQuestionOreder;
  }

  set setCurrentFillableQuestionOreder(order: number) {
    this.currentFillableQuestionOreder = order;
  }

  constructor(private http: HttpClient) {}

  getRoundQuestionState(roundId: string): RoundQuestionState[] {
    return this.roundQuestionState.get(roundId);
  }
  setRoundQuestionState<T>(value: RoundQuestionState, key: string): void {
    this.setCurrentFillableQuestionOreder = value.orderNumber;
    if (this.roundQuestionState.get(key)) {
      this.roundQuestionState.get(key).push(value);
    } else {
      this.roundQuestionState.set(key, [value]);
    }
  }

  public getFormedTriviaRoundQuestions(
    round_id: string
  ): Observable<
    { questionId: string; orderNumber: number; answerTypeId?: number }[]
  > {
    const body: DoAction<{ round_id: string }> = {
      data: { round_id },
      do_action: 'f_get_round_question_id_order',
    };
    return this.http.post<any>(`${environment.dctapiUrl}`, body).pipe(
      map((trivia_round_questions) => {
        const result = trivia_round_questions.map((value, index) => {
          const { questionId, orderNumber, answerTypeId } = value;
          return {
            questionId,
            orderNumber,
            answerTypeId,
          } as RoundQuestionState;
        });
        return result;
      })
    );
  }
}
