import { RoundTypePointValues } from '@core/models';

export class PointValueCount {
  static readonly ct: RoundTypePointValues = {
    range: {
      questionTypeId: 7,
      pointValueCount: 5,
      questionTypeName: 'Range',
    },
    mc: {
      questionTypeId: 0,
      pointValueCount: 5,
      questionTypeName: 'Multi-Choice',
    },
  };
}
