import { Injectable } from '@angular/core';
import {
  AnswerTypesQuery,
  CategoriesQuery,
  GameDifficultiesQuery,
  MarketsQuery,
  QuestionBonusesQuery,
  QuestionDifficultiesQuery,
  Questions_Set_Input,
  QuestionTypesQuery,
  RejectReasonsQuery,
  UpdateAnswerTypesMutation,
  UpdateQuestionDataMutation,
  Categories,
  ChangeQuestionTagsMutation,
  Question_Ratings,
  Question_Types,
} from '@core/generated-gql/graphql';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DoAction, UpdateAnswerTypesMutationArgs } from '@core/models';
import { QuestionTypeShortName } from '@core/enums';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class DoActionRequestsService {
  constructor(
    private http: HttpClient,
  ) {}

  public questionTypes(): Observable<Question_Types[]> {
    const body: DoAction<{}> = {
      do_action: 'f_question_types',
      data: {},
    };
    return this.http
      .post<Question_Types[]>(`${environment.dctapiUrl}`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public questionTypesWithTypeShortName(): Observable<
    QuestionTypesQuery['question_types'][0][]
  > {
    return this.questionTypes().pipe(
      map((qt) =>
        qt.filter(
          (t) =>
            t.question_type_short_name ===
              QuestionTypeShortName.MULTIPLE_CHOICE ||
            t.question_type_short_name === QuestionTypeShortName.RANGE
        )
      )
    );
  }

  public updateAnswerTypes(
    question: UpdateAnswerTypesMutationArgs
  ): Observable<UpdateAnswerTypesMutation> {
    const body: DoAction<{
      id: string;
      question_id: string;
      answer_type_id: number;
    }> = {
      do_action: 'f_update_answer_type',
      data: {
        id: question.round_question_id,
        question_id: question.question_id,
        answer_type_id: question.answer_type,
      },
    };
    return this.http.post(`${environment.dctapiUrl}`, body);
  }

  public matureRating(): Observable<Question_Ratings[]> {
    const body: DoAction<{}> = {
      do_action: 'f_question_ratings',
      data: {},
    };
    return this.http.post<Question_Ratings[]>(`${environment.dctapiUrl}`, body);
  }

  public getMarkets(): Observable<MarketsQuery['markets']> {
    const body: DoAction<{}> = {
      do_action: 'f_markets',
      data: {},
    };
    return this.http.post<MarketsQuery['markets']>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public getCategories(): Observable<CategoriesQuery['categories']> {
    const body: DoAction<{}> = {
      do_action: 'f_categories',
      data: {},
    };
    return this.http.post<Categories[]>(`${environment.dctapiUrl}`, body);
  }

  public questionDifficulties(): Observable<
    QuestionDifficultiesQuery['question_difficulties']
  > {
    const body: DoAction<{}> = {
      do_action: 'f_question_difficulties',
      data: {},
    };
    return this.http.post<QuestionDifficultiesQuery['question_difficulties']>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public questionBonuses(): Observable<
    QuestionBonusesQuery['question_bonuses']
  > {
    const body: DoAction<{}> = {
      do_action: 'f_question_bonuses',
      data: {},
    };
    return this.http.post<QuestionBonusesQuery['question_bonuses']>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public getRejectReasons(): Observable<
    RejectReasonsQuery['rejection_reasons']
  > {
    const body: DoAction<{}> = {
      data: {},
      do_action: 'f_rejection_reasons',
    };
    return this.http.post<RejectReasonsQuery['rejection_reasons']>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public updateQuestion(params: {
    data: Questions_Set_Input;
    question_id: string;
  }): Observable<UpdateQuestionDataMutation | null> {
    if (!params?.data || !params?.question_id) {
      return of(null);
    }

    const data = { ...params.data, question_id: params.question_id };

    const body: DoAction<Questions_Set_Input> = {
      do_action: 'f_update_question_data',
      data,
    };

    // body.data = this.jsonUtilities.replaceQuotes(data);
    return this.http.post<UpdateQuestionDataMutation | null>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public answerTypes(): Observable<AnswerTypesQuery['answer_types']> {
    const body: DoAction<{}> = {
      data: {},
      do_action: 'f_answer_type_list',
    };
    return this.http
      .post<AnswerTypesQuery['answer_types']>(`${environment.dctapiUrl}`, body)
      .pipe(
        map((answer_types) => {
          return answer_types;
        })
      );
  }

  public gameDifficulties(): Observable<
    GameDifficultiesQuery['game_difficulties']
  > {
    const body: DoAction<{}> = {
      do_action: 'f_game_difficulties',
      data: {},
    };
    return this.http.post<GameDifficultiesQuery['game_difficulties']>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public changeQuestionTags(params: {
    question_id: string;
    tags: Set<string>;
  }): Observable<any> {
    const body: DoAction<any> = {
      do_action: 'f_change_question_tags',
      data: {
        question_id: params.question_id,
        tags: Array.from(params.tags).map((tag) => ({
          question_id: params.question_id,
          tag,
        })),
      },
    };
    return this.http.post<ChangeQuestionTagsMutation>(
      `${environment.dctapiUrl}`,
      body
    );
  }
}
