export enum Roles {
  writer = 'writer',
  factchecker = 'factchecker',
  manager = 'manager',
  financialAdmin = 'financial admin',
  compiler = 'compiler',
  warfareCompiler = 'warfare compiler',
  admin = 'admin',
  triviamaticManager = 'TriviaMatic Manager',
}
