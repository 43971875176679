import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateAfterReportService } from '@core/api-services/game';
import { QuestionTypeIds, RoundQuestionStatusFilter, RoundTypeIds } from '@core/enums';
import {
  Report_Reasons,
  RoundQuestionByQuestionIdQuery,
  Trivia_Round_Questions,
  Trivia_Rounds_Set_Input,
} from '@core/generated-gql/graphql';
import {
  DoAction,
  FlagQuestionModalData,
  RandomRoundQuestionRequest,
  RoundByPkResponse,
  RoundQuestionReportRequest,
} from '@core/models';
import { UserService } from '@core/services/user.service';
import { environment } from '@env';
import { lastValueFrom, Observable } from 'rxjs';
import { map, concatMap, tap } from 'rxjs/operators';
import { MutateTriviaRoundQuestionService } from '../mutate-trivia-round-question.service';
import { RoundDataService } from './round-data.service';

@Injectable({ providedIn: 'root' })
export class RoundRequestsService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private updateAfterReportService: UpdateAfterReportService,
    private roundDataService: RoundDataService,
    private mutateTriviaRoundQuestionService: MutateTriviaRoundQuestionService
  ) {}

  public reportReasons(): Observable<Report_Reasons[]> {
    const body: DoAction<{}> = {
      do_action: 'f_report_reasons',
      data: {},
    };
    return this.http.post<Report_Reasons[]>(`${environment.dctapiUrl}`, body);
  }

  public roundByPk(
    round_id: string,
    include_json: 0 | 1 = 1
  ): Observable<RoundByPkResponse> {
    const body: DoAction<{ round_id: string; include_json: 0 | 1 }> = {
      do_action: 'f_round_by_pk',
      data: {
        round_id,
        include_json,
      },
    };
    return this.http
      .post<{ trivia_rounds_by_pk: RoundByPkResponse }>(
        `${environment.dctapiUrl}`,
        body
      )
      .pipe(
        map((response) => {
          return response.trivia_rounds_by_pk;
        })
      );
  }

  public reportQuestionReq(params: FlagQuestionModalData): Observable<any> {
    const body: DoAction<FlagQuestionModalData> = {
      do_action: 'f_report_question',
      data: { ...params, user_id: this.userService.user.user_id },
    };
    return this.http.post(`${environment.dctapiUrl}`, body);
  }

  public async reportAndReplaceRoundQuestion(
    params: RoundQuestionReportRequest
  ): Promise<any> {
    const { reports_data, element } = params;
    await lastValueFrom(this.reportQuestionReq(reports_data));
    const {
      question_type: { question_type_id: q_type_id },
    } = element;
    const question_type_id =
      q_type_id | params.question_type_id | QuestionTypeIds.MULTI_CHOICE;
    const randomQuestionQuery: RandomRoundQuestionRequest = {
      point_value: params.point_value,
      game_template_id: params.template_id,
      question_type_id,
      game_id: this.roundDataService.gameId,
      for_used_status: RoundQuestionStatusFilter.unused
    };

    if (
      (params.index === 11 &&
        params.round_type?.id === RoundTypeIds.ctm_standard) ||
      params.round_type?.id === RoundTypeIds.final
    ) {
      randomQuestionQuery.question_type_id = [QuestionTypeIds.RANGE];
    }

    return await lastValueFrom(
      this.mutateTriviaRoundQuestionService
        .randomQuestionForRound({
          queryData: randomQuestionQuery,
          roundQuestionId: params.element.question_id,
        })
        .pipe(
          tap((random_question) => {
            lastValueFrom(
              this.updateAfterReportService.updateDataAfterReport(
                params.element.question_id,
                random_question.question_id
              )
            );
          }),
          concatMap((random_question) =>
            lastValueFrom(
              this.mutateTriviaRoundQuestionService.updateRoundQuestion({
                question_id: random_question.question_id,
                id: params.element.id || params.element.round_question_id,
              })
            )
          )
        )
    );
  }

  public roundQuestionByQuestionId(
    question_id: string,
    include_json: 1 | 0 = 0
  ): Observable<RoundQuestionByQuestionIdQuery['trivia_round_questions']> {
    const body: DoAction<{ question_id: string; include_json: 1 | 0 }> = {
      do_action: 'f_round_question_by_question_id',
      data: {
        question_id,
        include_json,
      },
    };
    return this.http.post<
      RoundQuestionByQuestionIdQuery['trivia_round_questions']
    >(`${environment.dctapiUrl}`, body);
  }

  public roundQuestionByRoundId(
    roundId: string,
    questionOrder?: number
  ): Observable<Trivia_Round_Questions[]> {
    const body: DoAction<{ round_id: string; question_order?: number }> = {
      data: {
        round_id: roundId,
      },
      do_action: 'f_round_question_by_round_id',
    };
    if (questionOrder) {
      body.data.question_order = questionOrder;
    }
    return this.http.post<Trivia_Round_Questions[]>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public updateRoundData(params: {
    id: string;
    round_data: Trivia_Rounds_Set_Input;
  }): Observable<any> {
    let { id, round_data } = params;
    round_data = {
      round_keywords: round_data?.round_keywords?.trim() || '',
      name: round_data?.name?.trim() || '',
    };
    const body: DoAction<Trivia_Rounds_Set_Input> = {
      do_action: 'f_update_round',
      data: { round_id: id, ...round_data },
    };
    return this.http.post(`${environment.dctapiUrl}`, body);
  }
}
