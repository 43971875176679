import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client';
import { HttpClient } from '@angular/common/http';
import { DoAction } from '@core/models';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class TagsService {
  constructor(private http: HttpClient) {}

  public filteredTags(observable: Observable<any>, debounce_time: number): any {
    const body: DoAction<{ limit: number; tag: string }> = {
      do_action: 'f_question_tags_by_tag',
      data: {
        limit: 5,
        tag: '',
      },
    };
    return observable.pipe(
      startWith(''),
      debounceTime(debounce_time),
      distinctUntilChanged(),
      switchMap((searchText: string) => {
        body.data.tag = searchText;
        return searchText && searchText?.length > 1
          ? this.http.post<{ tag: string }[]>(`${environment.dctapiUrl}`, body)
          : (of([]) as Observable<[]>);
      }),
      map((res: { tag: string }[]) => {
        if (!res) {
          return [];
        }
        return [...new Set(res.map((tags) => tags?.tag))];
      })
    );
  }
}
