import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenRound } from '@core/generated-gql/graphql';
import { DoAction } from '@core/models';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GenerateRoundService {
  constructor(private http: HttpClient) {}

  public genCTMRound(): Observable<GenRound> {
    const body: DoAction<{}> = {
      do_action: 'f_gen_ctm_round',
      data: {},
    };
    return this.http.post<GenRound>(`${environment.dctapiUrl}`, body);
  }

  public genBonusRound(): Observable<GenRound> {
    const body: DoAction<{}> = {
      do_action: 'f_gen_bonus_round',
      data: {},
    };
    return this.http.post<GenRound>(`${environment.dctapiUrl}`, body);
  }

  public genDefaultRound(): Observable<GenRound> {
    const body: DoAction<{}> = { data: {}, do_action: 'f_gen_default_round' };
    return this.http.post<GenRound>(`${environment.dctapiUrl}`, body);
  }

  public genDefaultFinalRound(): Observable<GenRound> {
    const body: DoAction<{}> = {
      do_action: 'f_gen_default_final_round',
      data: {},
    };
    return this.http.post<GenRound>(`${environment.dctapiUrl}`, body);
  }
}
