import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormValidationConstants } from '@constants/form-validation.constants';
import { ValidationConstants } from '@constants/validation.constants';
import { MultipleChoiceFormGroup } from '@core/models';
import { v4 as uuid } from 'uuid';
import {CustomValidator} from "@core/validators";

type MCFormUUIds<T> = {
  [key in keyof T as T[key] extends T ? never : key]: string;
};
@Injectable({ providedIn: 'root' })
export class MultiChoiceService {
  public formUUIds: MCFormUUIds<MultipleChoiceFormGroup> = {
    question_text: uuid(),
    answer: uuid(),
    wrongAnswer1: uuid(),
    wrongAnswer2: uuid(),
    wrongAnswer3: uuid(),
    explanation: uuid(),
    hint: uuid(),
  };
  constructor(private fb: FormBuilder) {}

  public baseForm(questionData: MultipleChoiceFormGroup): FormGroup {
    const answerValidator = FormValidationConstants.answerValidator;

    const formGroup = this.fb.group({
      question_text: [
        questionData?.question_text,
        [
          CustomValidator.required,
          Validators.maxLength(ValidationConstants.questionTextMaxLength),
        ],
      ],
      image: [questionData?.image],
      answer_image: [questionData?.answer_image],
      answer: [questionData?.answer, answerValidator],
      wrongAnswer1: [questionData?.wrongAnswer1, answerValidator],
      wrongAnswer2: [questionData?.wrongAnswer2, answerValidator],
      wrongAnswer3: [questionData?.wrongAnswer3, answerValidator],
      explanation: [
        questionData?.explanation,
        [
          CustomValidator.required,
          Validators.maxLength(
            ValidationConstants.questionExplanationMaxLength
          ),
        ],
      ],
      hint: [
        questionData?.hint,
        [Validators.maxLength(ValidationConstants.questionHintMaxLength)],
      ],
    });
    if (questionData) {
      formGroup.patchValue(questionData);
    }
    return formGroup;
  }

  public getInvalidFormControls(form: FormGroup): string[] {
    const invalidCtrlIds = [];
    for (const ctrl in form.controls) {
      if (form.get(ctrl) instanceof FormControl && form.get(ctrl).invalid) {
        invalidCtrlIds.push(this.formUUIds[ctrl]);
      }
    }
    return invalidCtrlIds;
  }
}
