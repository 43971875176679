import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationConstants } from '@constants/form-validation.constants';
import { ValidationConstants } from '@constants/validation.constants';
import { QuestionDefaultAnswersCount } from '@core/enums';
import {
  PictureRoundAnswerGroup,
  PictureRoundAnswerType,
  PictureRoundFormType, Question,
} from '@core/models';

@Injectable({ providedIn: 'root' })
export class PictureRoundService {
  constructor(private fb: FormBuilder) {}

  public pictureRoundForm(questionData: PictureRoundFormType | Question): FormGroup {
    questionData = questionData as PictureRoundFormType
    const formGroup = this.fb.group({
      question_text_image: this.fb.group({
        question_text: ['', FormValidationConstants.questionTextValidator],
      }),
      itemsNumber: [
        10,
        [
          Validators.min(1),
          Validators.max(ValidationConstants.formArrayLength),
        ],
      ],
      items: this.generateFormArray(questionData),
      hint: [
        '',
        [Validators.maxLength(ValidationConstants.questionHintMaxLength)],
      ],
    });

    if (questionData) {
      const question_text =
        questionData.question_text ||
        questionData.question_text_image.question_text;
      formGroup.patchValue({
        ...questionData,
        question_text_image: { question_text },
      });
    }
    return formGroup;
  }

  public generateFormArray(questionData: PictureRoundFormType): FormArray {
    const answersFormArray = this.fb.array([]);
    let items = questionData?.items;
    if (!items) {
      items = new Array(
        QuestionDefaultAnswersCount.PICTURE_ROUND_ANSWERS_COUNT
      );
      items.fill(null);
    }
    items.forEach((e) => {
      answersFormArray.push(this.fb.group(this.createFormAnswer(e)));
    });
    return answersFormArray;
  }

  public createFormAnswer(
    item?: PictureRoundAnswerType
  ): PictureRoundAnswerGroup {
    return {
      itemtext: [item?.itemtext, FormValidationConstants.answerValidator],
      image: [item?.image, Validators.required],
    };
  }
}
