import {Validators} from '@angular/forms';
import {ValidationConstants} from './validation.constants';
import {CustomValidator} from "@core/validators";

export class FormValidationConstants {
  static questionTextValidator = [
    CustomValidator.required,
    Validators.maxLength(ValidationConstants.questionTextMaxLength),
  ];

  static answerValidator = [
    CustomValidator.required,
    Validators.maxLength(ValidationConstants.questionAnswerMaxLength),
  ];
}
