import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Question_Types } from '@core/generated-gql/graphql';
import {
  BaseFormGroup,
  DoAction,
  QuestionFromGroupsUnion,
  RangeFormGroup,
} from '@core/models';
import { ChooseTenService } from '@core/services/question-types';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuestionJsonService {
  constructor(
    private http: HttpClient,
    private chooseTenService: ChooseTenService
  ) {}

  public getQuestionJson(question_id: string): Observable<any> {
    const body: DoAction<{ question_id: string }> = {
      do_action: 'f_question_json',
      data: { question_id },
    };
    return this.http.post(`${environment.dctapiUrl}`, body);
  }

  /**
   * Changes question json to standard
   * @param json - form raw value
   */
  public prepareQuestionJson(
    formValue: BaseFormGroup & QuestionFromGroupsUnion,
    questionTypeToShow: Question_Types
  ): any {
    const { image, question_text } = formValue;
    const form = {
      answers: this.chooseTenService.convertJsonAnswersObject(formValue),
      image: formValue?.question_text_image?.image || image,
      question_text:
        formValue?.question_text_image?.question_text || question_text,
      ...formValue,
    };
    if (questionTypeToShow?.question_type_id === 7) {
      const rangeForm = form as RangeFormGroup;
      rangeForm.answer =
        rangeForm?.right_answer?.right_answer_text || rangeForm?.answer;
    }
    return form;
  }
}
