export const environment = {
  production: false,
  apiUrl: 'https://gqldev.trebektrivia.com/v1/graphql',
  restApiUrl: 'https://apidev.trebektrivia.com/',
  dctapiUrl: 'https://dctapi.trebektrivia.com/cloud/trebek/do-dev-action',
  loginUrl: 'https://dctapi.trebektrivia.com/cloud/trebek/do-dev-app-action',
  triviaRushApiUrl: 'https://apidev.trivialrush.com/',
  triviaMaticBaseApiUrl: 'http://preview.triviamatic.com:5000/',
  triviaMaticBaseUrl: 'http://preview.triviamatic.com',
  // restApiUrl: 'http://admindev.trebektrivia.com:5000/',
  gameDefaultThemeId: 'aa3808ee-aacf-4e8f-bd03-8365849fd013',
};
