import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ReportQuestionMutation,
  Report_Reasons,
  UpdateRoundQuestionMutation,
} from '@gql/graphql';
import { FlagQuestionModalData } from '@models/flag-question-modal-data.interface';
import { UserService } from '@services/user.service';
import { concatMap } from 'rxjs/operators';
import { ReportModalComponent } from 'src/app/view/dashboard/games/components/report-modal/report-modal.component';
import { FetchResult } from 'apollo-link';
import { lastValueFrom, of } from 'rxjs';
import { MatSnackbarService } from '@core/services/mat-snakbar/mat-snakbar.service';
import { DoAction, RoundForms } from '@core/models';
import { MutateTriviaRoundQuestionService } from '../../mutate-trivia-round-question.service';
import { RoundRequestsService } from '../round-requests.service';
import { QuestionTypeIds } from '@core/enums';
import { StandardRoundService } from '../standard-round.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ReportRoundQuestionService {
  public reportReasons: Report_Reasons[];

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private matDialog: MatDialog,
    private standardRoundService: StandardRoundService,
    private matSnackbarService: MatSnackbarService,
    private roundRequestsService: RoundRequestsService,
    private mutateTriviaRoundQuestionService: MutateTriviaRoundQuestionService
  ) {
    this.setReportReasons();
  }

  setReportReasons(): void {
    this.roundRequestsService
      .reportReasons()
      .subscribe((res) => (this.reportReasons = res));
  }

  /**
   * Mark question as reported and replace with new random one
   * @param element - RoundForms
   */
  public async flag(params: {
    element: RoundForms;
    forRoundType?: string;
    templateId?: any;
    game_id: string;
  }): Promise<FetchResult<UpdateRoundQuestionMutation>> {
    const result = await this.report(params.element);
    if (result) {
      let updatedQuestion;
      if (
        params.element.question_type_id >= QuestionTypeIds.MULTI_CHOICE &&
        params.forRoundType
      ) {
        updatedQuestion = await this.replaceAndUpdateQuestionByTypeId(
          [params.element.question_type_id],
          params.element.round_question_id,
          1,
          params.forRoundType
        );
      } else {
        const randomQuestionQuery = {
          question_type_id: 0,
          game_template_id: params.templateId,
          game_id: params.game_id,
        };
        if (params?.element.round_type === 'final') {
          randomQuestionQuery.question_type_id = 7;
        }
        updatedQuestion = await lastValueFrom(
          this.mutateTriviaRoundQuestionService.randomQuestionForRound({
            queryData: randomQuestionQuery,
            roundQuestionId: params.element.round_question_id,
          })
        );
      }
      return updatedQuestion;
    }
  }

  private async report(element): Promise<ReportQuestionMutation> {
    const dialogRef: MatDialogRef<ReportModalComponent, FlagQuestionModalData> =
      this.matDialog.open(ReportModalComponent, {
        data: {
          reportReasons: this.reportReasons,
          questionId: element.question_id,
        },
      });
    const result = await lastValueFrom(dialogRef.afterClosed());
    if (result) {
      const body: DoAction<FlagQuestionModalData> = {
        do_action: 'f_report_question',
        data: {
          ...result,
          user_id: this.userService.user.user_id,
        },
      };
      return await lastValueFrom(
        this.http.post<ReportQuestionMutation>(`${environment.dctapiUrl}`, {
          ...result,
          userId: this.userService.user.user_id,
        })
      );
    }
  }

  private async replaceAndUpdateQuestionByTypeId(
    question_type_id: number[],
    roundQuestionId: string,
    count: number = 1,
    for_round_type: string = null
  ): Promise<
    UpdateRoundQuestionMutation['update_trivia_round_questions_by_pk']
  > {
    const result = await lastValueFrom(
      this.standardRoundService
        .fillStandardRoundQuestions(
          { question_type_id, count, for_round_type },
          count
        )
        .pipe(
          concatMap((q) => {
            if (q.length === 0) {
              this.matSnackbarService.error('Question not found');
              return of(null);
            }
            return this.mutateTriviaRoundQuestionService.updateRoundQuestion({
              question_id: q[0].question_id,
              id: roundQuestionId,
            });
          })
        )
    );
    return result;
  }
}
