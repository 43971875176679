import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionTypeIds } from '@core/enums';

@Injectable({ providedIn: 'root' })
export class PointValueService {
  #_pointValues = [QuestionTypeIds.MULTI_CHOICE, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  public pointValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  public bonusPointQuestionTypeIds: number[] = [
    QuestionTypeIds.PUZ_MATCHING,
    QuestionTypeIds.CHOOSE_TEN,
    QuestionTypeIds.PICTURE_ROUND,
    QuestionTypeIds.CHOOSE_FROM_LIST,
    QuestionTypeIds.ONE_COLUMN,
    QuestionTypeIds.TWO_COLUMN,
  ];

  constructor() {}

  public disablePointValueByQuestionTypeId(questionTypeId: number): number[] {
    if (questionTypeId > QuestionTypeIds.RANGE) {
      return this.#_pointValues.filter((e) => e !== 0);
    } else if (questionTypeId === QuestionTypeIds.MULTI_CHOICE) {
      return [0, 11];
    } else if (questionTypeId === QuestionTypeIds.RANGE) {
      return this.#_pointValues.filter((e) => e !== 11);
    }
  }

  /**
   * Checks question type
   * Sets point value 11 on fact-check-range type
   *  @param questionType - question type id
   */
  public changePointValue(params: {
    questionTypeId: number;
    questionForm: FormGroup;
    point_value?: number;
    question_bonus_id?: number;
  }): boolean {
    const pointValueDropdown = params.questionForm.get('point_value');
    if (params.questionTypeId === QuestionTypeIds.RANGE) {
      pointValueDropdown.setValue(11);
    } else if (this.bonusPointQuestionTypeIds.includes(params.questionTypeId)) {
      pointValueDropdown.setValue(0);
    } else {
      pointValueDropdown.setValue(params.point_value ?? null);
      pointValueDropdown.markAsUntouched();
    }
    return this.questionBonusIdChange(
      pointValueDropdown.value,
      params.questionForm,
      params.question_bonus_id
    );
  }

  /**
   * Checks point value
   * opens bonus field and sets validator
   * @param select - changed point value
   */
  public questionBonusIdChange(
    select: number,
    questionForm: FormGroup,
    bonusId?: number
  ): boolean {
    if (select === 0) {
      questionForm.setControl(
        'question_bonus_id',
        new FormControl(bonusId, Validators.required)
      );
      return true;
    } else {
      questionForm.removeControl('question_bonus_id');
      return false;
    }
  }
}
