import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RoleIds, Roles, UserStatisticsActionName, UserTypes, UsersStatisticsActionName} from '@core/enums';
import {
  BaseUserStatisticsRequest,
  DoAction,
  Manager_WriterStatsResponse,
  UserListResponse,
  UserStatisticsResponse,
  UsersStatisticsRequest,
  WriterStatsResponse,
  WriterUserStatisticsRequest,
} from '@core/models';
import {environment} from '@env';
import {Observable, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserStatisticsService {
  constructor(private http: HttpClient) {
  }

  public getUsersStatistics(
    params: UsersStatisticsRequest,
    user_role_name: string
  ): Observable<any> {
    const data: UsersStatisticsRequest = {...params};
    let userStatisticsActionName = UsersStatisticsActionName[user_role_name];
    if (user_role_name === Roles.manager) {
      data.user_type_id = RoleIds.writer;
    }
    const body: DoAction<UsersStatisticsRequest> = {
      do_action: `${userStatisticsActionName}`,
      data: {...data},
    };
    return this.http
      .post<UserListResponse>(`${environment.dctapiUrl}`, body)
      .pipe(
        map((response) => {
          if (params.user_type_id === UserTypes.manager) {
            return this.formManagerStats(response);
          }
          return response;
        })
      );
  }

  private formManagerStats(
    response: UserListResponse
  ): Partial<WriterStatsResponse | Manager_WriterStatsResponse> {
    const writersList = response.writers;
    const summedObj = writersList.reduce((accumulator, currentValue) => {
      const published_count = currentValue.published.count;
      const submitted_count = currentValue.submitted.count;
      const rejected_count = currentValue.rejected.count;
      accumulator.published.count += published_count;
      accumulator.submitted.count += submitted_count;
      accumulator.rejected.count += rejected_count;
      return accumulator;
    });

    const {
      published: {count: published_count},
      rejected: {count: rejected_count},
      submitted: {count: submitted_count},
    } = summedObj;
    return {
      questions_published: published_count,
      questions_rejected: rejected_count,
      questions_submitted: submitted_count,
    } as Manager_WriterStatsResponse;
  }

  public getUserStatistics<T extends WriterUserStatisticsRequest>(
    params: T,
    user_role_name: string
  ): Observable<UserStatisticsResponse> {
    // const user_type_id = Roles[user_role_name]
    const {start_date, user_id, end_date} = params;
    let userStatisticsActionName = UserStatisticsActionName[user_role_name];
    const body: DoAction<
      BaseUserStatisticsRequest & { question_type_id?: number }
    > = {
      do_action: userStatisticsActionName,
      data: {
        user_id,
        start_date,
        end_date,
        question_type_id: params.question_type_id,
      },
    };
    return this.http.post<UserStatisticsResponse>(
      `${environment.dctapiUrl}`,
      body
    );
  }
}
