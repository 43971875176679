import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DoAction } from '@core/models';
import { environment } from '@env';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RoundQuestionAPIService {
  constructor(private http: HttpClient) {}

  public async clearPartiallyCreatedRounds(roundIds: string[]): Promise<void> {
    const body: DoAction<{ round_ids: string[] }> = {
      do_action: 'f_make_questions_unused',
      data: {
        round_ids: roundIds || ['d5ac4068-90b5-427a-b9ea-1f3abf75340e'],
      },
    };
    let result = await lastValueFrom(
      this.http.post(`${environment.dctapiUrl}`, body)
    );
  }
}
