import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationConstants } from '@constants/validation.constants';

@Injectable({ providedIn: 'root' })
export class GameHeaderService {
  constructor(private fb: FormBuilder) {}
  createGameHeaderFormGroup(lengths: {
    titleLength: number;
    textLength: number;
  }): FormGroup {
    return this.fb.group({
      title: ['', Validators.maxLength(lengths.titleLength)],
      category: '',
      market: [
        '',
        [
          Validators.required,
          Validators.pattern(ValidationConstants.NO_WHITE_SPACE),
        ],
      ],
      rating: ['standard', Validators.required],
      keywords: ['', Validators.maxLength(lengths.textLength)],
      description: ['', Validators.maxLength(lengths.textLength)],
      game_difficult_id: 2,
    });
  }
}
