import {Injectable} from '@angular/core';
import {User_Types} from '@gql/graphql';
import {environment} from '@env';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Roles} from '@core/enums';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserTypesService {
  public selectedRoleState: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private disabledUserRoles: string[] = [Roles.admin];

  constructor(private http: HttpClient) {
  }

  private updateUserRolesDisabledProps(e: (User_Types & { disabled?: true })[]) {
    this.disabledUserRoles.map((role) => {
      const index = e.findIndex((r) => r.user_type_name === role);
      e[index].disabled = true;
    });
  }

  getUserTypes(): Observable<(User_Types & { disabled?: true })[]> {
    const url = 'auth/user-types';
    return this.http
      .get<(User_Types & { disabled?: true })[]>(
        `${environment.restApiUrl}${url}`
      )
      .pipe(
        map((e) => {
          this.updateUserRolesDisabledProps(e);
          return e;
        })
      );
  }
}
