export enum QuestionTypeIds {
  MULTI_CHOICE = 0,
  TRUE_FALSE = 1,
  Pick_3 = 3,
  ANSWER_FAST = 4,
  PICK_FROM_IMAGE = 5,
  EITHER_OR = 6,
  RANGE = 7,
  PUZ_MATCHING = 8,
  CHOOSE_TEN = 9,
  PICTURE_ROUND = 10,
  CHOOSE_FROM_LIST = 11,
  ONE_COLUMN = 12,
  TWO_COLUMN = 13,
  MUSIC_ROUND = 14,
  FILL_IN_BLANK = 15,
}
