import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FileModel} from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class FileInteractionsService {
  readonly imageTypes = ['png', 'jpg', 'jpeg'];
  pdfData: FileModel = new FileModel();
  imgData: FileModel = new FileModel();

  private static createFavicon(type: string): HTMLLinkElement {
    const favicon = document.createElement('link');
    favicon.href = `https://admindev.trebektrivia.com/assets/images/${type}.png`;
    favicon.rel = 'icon';
    favicon.type = 'image/x-icon';
    return favicon;
  }

  constructor(private sanitizer: DomSanitizer) {
  }

  checkDataType(type: string, fileType: string): boolean {
    fileType = fileType.toLowerCase();
    if (type === 'pdfData') {
      return fileType === 'pdf';
    } else {
      return this.imageTypes.includes(fileType);
    }
  }

  viewFile(data: FileModel, type: string): void {
    if (data.isReady) {
      const newWindow = window.open();
      if (type === 'pdf') {
        newWindow.document.write(`<iframe width='100%' height='100%' src='${data.data}'></iframe>`);
      } else {
        newWindow.document.write(`<img alt='image' style='width: 50%' src='${data.data}'/>`);
      }
      newWindow.document.head.appendChild(FileInteractionsService.createFavicon(type));
      newWindow.document.title = data.name;
    }
  }

  setFileValue(fileData): FileModel {
    return {
      data: fileData.data,
      name: fileData.name,
      safeData: this.sanitizer.bypassSecurityTrustResourceUrl(fileData.data),
      isReady: true,
      hasError: false,
      isDisabled: false
    };
  }

  async base64ImageFromUrl(url:string): Promise<string> {
    const data = await fetch(url, {
      cache: 'no-cache',
    });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data as string);
      }
    });
  }
}
