import {FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import {ChangeQuestionTagsMutation, Questions} from '@gql/graphql';
import {UserService} from '@services/user.service';
import {BehaviorSubject, lastValueFrom, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {QuestionRating, QuestionStatuses} from '@core/enums';
import {MutationResult} from 'apollo-angular';
import {HttpClient} from '@angular/common/http';
import {AddQuestionFormInput, AddQuestionFormOutput, DoAction} from '@core/models';
import {environment} from '@env';
import {DoActionRequestsService} from '@core/services';
import * as moment from "moment";
import {UtilityHelper} from "@core/helpers";

@Injectable({
  providedIn: 'root',
})
export class InsertAndUpdateQuestionService {
  public formIsSubmitted: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private doActionRequestsService: DoActionRequestsService
  ) {
  }

  formDataToInsertAndUpdate(
    json: Questions['question_json'],
    form: AddQuestionFormInput & {
      difficulty: number;
      market: string;
      question_type: number;
    },
    questionStatusId: number,
    rejectForm: FormGroup,
    isInsert = false,
    forRoundType?: string
  ): Partial<Questions> & {
    rejection_note?: string;
    question_image: string;
    for_round_type?: string;
    answer_type_id?: number;
  } {
    const {question_text_image} = json;
    const {
      question_type,
      question_type_id,
      use_end_date,
      use_start_date,
      difficulty,
      question_bonus_id,
      point_value
    } = form;
    const questionTypeId =
      question_type >= 0 ? question_type : question_type_id;

    let startDate = null
    let endDate = null
    if (use_start_date) {
      startDate = moment(use_start_date)
    }
    if (use_end_date) {
      endDate = moment(use_end_date)
    }

    const newForm: AddQuestionFormOutput & {
      question_image: string;
      rejection_note?: string;
      for_round_type?: string;
      answer_type_id?: number;
      rejector_user_id?: string;
    } = {
      category_id: form.category || null,
      question_rating: form.question_rating || QuestionRating['all-ages'],
      market_id: form.market?.market_id || form.market,
      question_json: json || null,
      question_status_id: questionStatusId,
      question_text: question_text_image?.question_text ?? json?.question_text,
      question_image: question_text_image?.question_image ?? json?.image,
      question_type_id: questionTypeId >= 0 ? questionTypeId : null,
      source_primary: form.source_primary || null,
      source_secondary: form.source_secondary || null,
      sub_category_id: form.sub_category || null,
      use_end_date: UtilityHelper.YYYYMMDDFormat(endDate?.utc()?.toDate() || null),
      use_start_date: UtilityHelper.YYYYMMDDFormat(startDate?.utc()?.toDate() || null),
      question_difficulty_id: difficulty || null,
      question_bonus_id,
      point_value,
    };
    if (
      rejectForm &&
      !isInsert &&
      questionStatusId === QuestionStatuses.rejected
    ) {
      newForm.rejection_note = rejectForm.value.rejection_note || '';
      newForm.rejection_reason_id =
        rejectForm.value.reason || rejectForm.value.reason === 0
          ? rejectForm.value.reason
          : null;
      newForm.rejector_user_id = this.userService.user.user_id;
    }

    if (isInsert) {
      newForm.writer_user_id = this.userService.user.user_id;
      newForm.question_id = form.question_id
      newForm.created_datetime = new Date().toISOString()
    }
    if (!isInsert) {
      newForm.approved_datetime = UtilityHelper.YYYYMMDDFormat(
        new Date()
      );
      newForm.source_tertiary = form.source_tertiary || null;

      if (questionStatusId === QuestionStatuses.factchecked) {
        newForm.approved_user_id = this.userService.user.user_id;
      }
    }

    if (forRoundType) {
      newForm.for_round_type = forRoundType;
    } else {
      newForm.editor_user_id = this.userService.user.user_id;
    }
    return newForm;
  }

  public insertNewQuestion(params: {
    newQuestionData: Partial<Questions>;
    tags?: Set<string>;
    only_insert?: boolean;
  }): Observable<MutationResult<ChangeQuestionTagsMutation>> {
    const {question_id} = params.newQuestionData;
    // this.jsonUtilityService.replaceQuotes(params.newQuestionData);

    const body: DoAction<Partial<Questions>> = {
      do_action: 'f_question_add',
      data: params.newQuestionData,
    };
    return this.http.post(`${environment.dctapiUrl}`, body).pipe(
      switchMap((v: any) => {
        if (!params.only_insert) {
          lastValueFrom(
            this.doActionRequestsService.changeQuestionTags({
              question_id,
              tags: params.tags,
            })
          );
        }
        return of(v);
      })
    );
  }
}
