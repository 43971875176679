import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  updateLocalStorageData(data: any, key: string, index?: number): void {
    let oldData = this.getData(key);
    this.removeData(key);
    if (index >= 0) {
      oldData[index] = data;
    } else {
      oldData = data;
    }
    localStorage.setItem(key, JSON.stringify(oldData));
  }

  setArrayItem<T>(data: T, key: string): void {
    if (this.getData(key)) {
      const localeData = JSON.parse(localStorage.getItem(key));
      localeData.push(data);
      localStorage.setItem(key, JSON.stringify(localeData));
    } else {
      const jsonData = JSON.stringify([data]);
      localStorage.setItem(key, jsonData);
    }
  }

  getData<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key));
  }

  removeData(key: string): void {
    localStorage.removeItem(key);
  }
}
