export const BonusRoundColumns = [
  { header: 'none', columnDef: 'editInMobileView' },
  { header: 'Question Type', columnDef: 'question_type' },
  { header: 'Bonus', columnDef: 'bonus' },
  { header: 'Category', columnDef: 'category' },
  { header: 'Sub Category', columnDef: 'sub_category' },
  { header: 'Questions', columnDef: 'questions' },
  { header: 'Answer Type', columnDef: 'answer_type' },
  { header: 'none', columnDef: 'actions' },
  { header: 'Flag Question', columnDef: 'flag' },
];
