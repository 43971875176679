import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { UserService } from '../user.service';
import { HttpClient } from '@angular/common/http';
import { DoAction } from '@core/models';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class QuestionCountsService {
  constructor(private userService: UserService, private http: HttpClient) {}

  public getQuestionsCountByRejectedReason(
    rejection_reason_name: string
  ): Observable<number> {
    const body: DoAction<{
      writer_user_id: string;
      rejection_reason_name: string;
    }> = {
      do_action: 'f_rejected_questions_count',
      data: {
        writer_user_id: this.userService.user.user_id,
        rejection_reason_name,
      },
    };
    return this.http
      .post<{ count: number }>(`${environment.dctapiUrl}`, body)

      .pipe(map((e) => e.count));
  }

  public getQuestionCountInQueue(
    question_status_id: number
  ): Observable<number> {
    const body: DoAction<{ question_status_id: number }> = {
      do_action: 'f_question_count_in_queue',
      data: {
        question_status_id,
      },
    };
    return this.http
      .post<{ count: number }>(`${environment.dctapiUrl}`, body)
      .pipe(map((response) => response.count));
  }
}
