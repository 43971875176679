import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '@env';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { UserService } from '@services/user.service';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';

const uri = environment.apiUrl;
export function createApollo(
  httpLink: HttpLink,
  userService: UserService
): any {
  const errorLink = onError(({ graphQLErrors, networkError }) => {});
  const auth = setContext(() => ({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userService.user.token}`,
    },
  }));
  const link = ApolloLink.from([errorLink, auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache();
  return {
    link,
    auth,
    cache,
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, UserService],
    },
  ],
})
export class GraphQLModule {}
