import { Trivia_Game_Rounds, Trivia_Rounds } from '@core/generated-gql/graphql';

export interface TriviaGames {
  title: string;
  market: string;
  rating: string;
  game_id: string;
  category: null;
  keywords: null;
  created_at: string;
  creator_id: string;
  description: null;
  game_template_id: string;
  game_difficult_id: null;
  trivia_game_rounds: TriviaGameRounds[];
  trivia_game_themes: null;
  trivia_game_template: {
    template_id: string;
    template_name: string;
  };
  gameStatusByGameStatus: {
    id: number;
    name: string;
  };
}

export interface TriviaGameRounds {
  created_at: string;
  game_id: string;
  id: string;
  round_id: string;
  round_order: number;
  trivia_game: TriviaGames;
  trivia_round: TriviaRound;
}
[];

export interface TriviaRound {
  name: null;
  round_id: string;
  created_at: string;
  round_order: number;
  round_type: string;
  trivia_round_type: {
    id: string;
    name: string;
  };
}
