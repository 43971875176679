import { Injectable } from '@angular/core';
import { DynamicTableDate } from '@core/models';

@Injectable({ providedIn: 'root' })
export class DynamicListService {
  constructor() {}

  public formDynamicListDate(date: string): DynamicTableDate {
    return {
      date_format: 'MM/dd/YYYY',
      date: date,
      no_date: 'Not Used',
    };
  }
}
