import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Roles } from '@core/enums';
import { User } from '../models';

enum StoragePaths {
  role = 'role',
  loginType = 'login_type',
  token = 'token',
  firstname = 'firstname',
  lastname = 'lastname',
  id = 'user_id',
  selectedGameTemplate = 'selectedGameTemplate',
}

enum LoginType {
  permanent = 'permanent',
  session = 'session',
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // tslint:disable-next-line:variable-name
  private _user: User;
  private storage: Storage;

  constructor(private router: Router) {
    const loginType = localStorage.getItem(StoragePaths.loginType);
    if (loginType === LoginType.permanent) {
      this.storage = localStorage;
    } else if (loginType === LoginType.session) {
      this.storage = sessionStorage;
    }
    if (this.storage) {
      this._user = {
        user_id: this.storage.getItem(StoragePaths.id) || null,
        token: this.storage.getItem(StoragePaths.token) || null,
        role: this.storage.getItem(StoragePaths.role) || null,
        firstname: this.storage.getItem(StoragePaths.firstname) || null,
        lastname: this.storage.getItem(StoragePaths.lastname) || null,
      };
    }
  }

  get user(): User {
    return this._user;
  }

  get role(): string {
    switch (this._user.role) {
      case Roles.triviamaticManager:
        return 'TM Manager';

      default:
        return this._user.role;
    }
  }

  set user(user: User) {
    // tslint:disable-next-line:no-unused-expression
    user.token &&
      this.storage &&
      this.storage.setItem(StoragePaths.token, user.token);
    // tslint:disable-next-line:no-unused-expression
    user.user_id &&
      this.storage &&
      this.storage.setItem(StoragePaths.id, user.user_id);
    // tslint:disable-next-line:no-unused-expression
    user.role &&
      this.storage &&
      this.storage.setItem(StoragePaths.role, user.role);
    // tslint:disable-next-line:no-unused-expression
    user.firstname &&
      this.storage &&
      this.storage.setItem(StoragePaths.firstname, user.firstname);
    // tslint:disable-next-line:no-unused-expression
    user.lastname &&
      this.storage &&
      this.storage.setItem(StoragePaths.lastname, user.lastname);
    this._user = user;
  }

  public isLoggedIn(): boolean {    
    return Boolean(this.user?.role && this.user?.token && this.user?.user_id);
  }

  public setStorage(remember: boolean = true): void {
    localStorage.setItem(
      StoragePaths.loginType,
      remember ? LoginType.permanent : LoginType.session
    );
    this.storage = remember ? localStorage : sessionStorage;
  }

  public clearStorage(): void {    
    this.storage.clear();
  }

  public logout(): void {
    if (this.storage) {
      this.storage.removeItem(StoragePaths.token);
      this.storage.removeItem(StoragePaths.id);
      this.storage.removeItem(StoragePaths.role);
      this.storage.removeItem(StoragePaths.firstname);
      this.storage.removeItem(StoragePaths.lastname);
    }
    localStorage.removeItem(StoragePaths.loginType);
    localStorage.removeItem(StoragePaths.selectedGameTemplate);
    this.storage = null;
    this._user = null;
    this.router.navigate(['auth/login']);
  }
}
