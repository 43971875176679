import { Injectable } from '@angular/core';
import {
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Category, ChangedCategories } from '@models/categories.interface';
import { ValidationConstants } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  readonly percentRegexp = ValidationConstants.PERCENT_REGEXP;

  constructor() {}

  filterCategoryAndSubcategory(categories: Category[]): {categoriesList: Category[], allSubCategories: {}} {
    const categoriesList: Category[] = [];
    const allSubCategories: Category[] = [];
    for (const category of categories) {
      if (category.parent_category_id) {
        if (!allSubCategories[category.parent_category_id]) {
          allSubCategories[category.parent_category_id] = [];
        }
        allSubCategories[category.parent_category_id].push(category);
      } else {
        categoriesList.push(category);
      }
    }

    return { categoriesList, allSubCategories };
  }

  filterCategoriesAndSubcategories(categories: Category[]): {
    categoriesList: Category[];
    subCategoriesList: Category[][];
  } {
    const categoriesList: Category[] = [];
    const subCategoriesList = [];

    for (const category of categories) {
      if (category.parent_category_id) {
        const parentCat = categories.find(
          (cat) => cat.id === category.parent_category_id
        );

        if (!subCategoriesList[parentCat.orderNum]) {
          subCategoriesList[parentCat.orderNum] = [];
        }
        category.parentOrderNum = parentCat.orderNum;
        subCategoriesList[parentCat.orderNum].push(category);
      } else {
        categoriesList.push(category);
      }
    }

    return { categoriesList, subCategoriesList };
  }

  getDirtyValues(form: FormArray | FormGroup, changed: {id: string, weight: number}[]): ChangedCategories {
    const changedValues = changed;

    Object.keys(form.controls).forEach((key) => {
      const currentControl:
        | (FormArray & { cat_id: string })
        | (FormGroup & { cat_id: string }) = form.controls[key];
      if (currentControl.dirty) {
        if (currentControl.controls) {
          this.getDirtyValues(currentControl, changedValues);
        } else {
          changedValues.push({
            id: currentControl.cat_id,
            weight: +currentControl.value,
          });
        }
      }
    });

    return { categories: changedValues };
  }

  comparisonValidator(): ValidatorFn {
    return (array: FormArray): ValidationErrors => {
      this.setError(array);
      return;
    };
  }

  private setError(array: FormArray): void {
    let sumOfPercents = 0;
    let formatErr = false;
    const sum = array.getRawValue().reduce((a, b) => +a + +b, 0);

    for (const control of array.controls) {
      if (control.value.toString().trim() === '') {
        control.setErrors({ required: true });
        formatErr = true;
        continue;
      }

      if (
        isNaN(control.value) ||
        !this.percentRegexp.test(control.value.toString())
      ) {
        control.setErrors({ notNumber: true });
        formatErr = true;
        continue;
      }

      sumOfPercents += +control.value;
      if (sumOfPercents <= 100) {
        control.setErrors(null);
      } else {
        const errors = { overHundred: true };
        control.markAsTouched();
        control.setErrors(errors);
      }

      if (sum < 100 && sumOfPercents <= 100 && !formatErr) {
        const errors = { lessHundred: true };
        control.markAsTouched();
        control.setErrors(errors);
      }
    }
  }

  public sortByOrder(array: string[], key = 'orderNum'): any {
    return array.sort((a: any, b: any) => a[key] - b[key]);
  }
}
