import { QuestionTypeIds } from '@core/enums';

export class QuestionTypeConstants {
  static readonly bonusQuestionTypeIds = [
    QuestionTypeIds.CHOOSE_TEN,
    QuestionTypeIds.PICTURE_ROUND,
    QuestionTypeIds.PUZ_MATCHING,
    QuestionTypeIds.TWO_COLUMN,
  ];

  static readonly disabledQuestionTypeIds = [
    QuestionTypeIds.ANSWER_FAST,
    QuestionTypeIds.CHOOSE_FROM_LIST,
    QuestionTypeIds.EITHER_OR,
    QuestionTypeIds.MUSIC_ROUND,
    QuestionTypeIds.FILL_IN_BLANK,
    QuestionTypeIds.MUSIC_ROUND,
    QuestionTypeIds.Pick_3,
    QuestionTypeIds.ONE_COLUMN,
    QuestionTypeIds.PICK_FROM_IMAGE,
    QuestionTypeIds.TRUE_FALSE,
  ];

  static readonly QuestionTypeNames = {
    0: 'mc',
    7: 'range'
  }
}
