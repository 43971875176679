export const standardGameForm = {
  answer: '',
  category: '',
  point_value: null,
  position: null,
  question_id: '',
  question_type: {
    question_type_id: null,
    question_type_name: '',
    question_type_short_name: ''
  },
  questions: '',
  round_question_id: '',
  sub_category: '',
  answer_type_id: 2,
};
