import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavBarToggleService {
    private toggle = new BehaviorSubject(true);

    get getToggle(): BehaviorSubject<boolean> {
        return this.toggle;
    }

   public setToggle(): BehaviorSubject<boolean> {
        this.toggle.next(!this.toggle.value);
        return this.toggle;
    }
}
