import { RoundType } from '@models/compiler/available-questions-count-for-game.interface';
import { QuestionTypeConstants } from '../question-types.constants';

export class QuestionsCountForGame {
  static readonly default: RoundType = {
    range: {
      questionTypeId: 7,
      questionsCount: 1,
      questionTypeName: 'Range',
    },
    multiChoice: {
      questionTypeId: 0,
      questionsCount: 32,
      questionTypeName: 'Multi-Choice',
    },
  };
  static readonly ct: RoundType = {
    range: {
      questionTypeId: 7,
      questionsCount: 5,
      questionTypeName: 'Range',
    },
    multiChoice: {
      questionTypeId: 0,
      questionsCount: 50,
      questionTypeName: 'Multi-Choice',
    },
    bonus: {
      questionTypeId: QuestionTypeConstants.bonusQuestionTypeIds,
      questionsCount: 4,
      questionTypeName: 'Bonus',
    },
  };

  static ctm: RoundType = {
    range: {
      questionTypeId: 7,
      questionsCount: 1,
      questionTypeName: 'Range',
    },
    multiChoice: {
      questionTypeId: 0,
      questionsCount: 50,
      questionTypeName: 'Multi-Choice',
    },
  };

  static warfare: RoundType = {
    multiChoice: {
      questionTypeId: 0,
      questionsCount: 21,
      questionTypeName: 'Multi-Choice',
    },
    bonus: {
      questionTypeId: 13,
      questionsCount: 1,
      questionTypeName: 'Bonus',
    },
  };
}
