import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Trivia_Games } from '@core/generated-gql/graphql';
import {
  DoAction,
  GameByIdResponse,
  GameList,
  GameListResponse,
  GameListRequestResponse,
  RequiredTriviaGameDates,
  TriviaGamesRequestParams,
  TriviaGamesResponse,
  TriviaGamesObj,
} from '@core/models';
import { UserService } from '@core/services/user.service';
import { environment } from '@env';
import { Observable, map } from 'rxjs';
import {UtilityHelper} from "@core/helpers";

@Injectable({ providedIn: 'root' })
export class GetGamesService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {}

  public getGames(params: {
    status: number;
    offset: number;
    limit: number;
    template: string;
  }): Observable<GameListResponse> {
    const { status, offset, limit, template } = params;
    const body: DoAction<GameList> = {
      do_action: 'f_game_list_by_status_temp',
      data: {
        status,
        offset,
        limit,
        template,
        creator_id: this.userService.user.user_id,
      },
    };

    return this.http
      .post<GameListRequestResponse>(`${environment.dctapiUrl}`, body)
      .pipe(
        map((game_list) => {
          const sortedGames = this.sortTriviaGames<TriviaGamesObj>(
            game_list.trivia_games
          );
          const trivia_games = UtilityHelper.chunkArray<
            GameListRequestResponse['trivia_games'][0]
          >(sortedGames, limit);
          return {
            trivia_games,
            trivia_games_aggregate: game_list.trivia_games_aggregate,
          };
        })
      );
  }

  public getGameById(game_id: string): Observable<GameByIdResponse> {
    const body: DoAction<{ game_id: string }> = {
      do_action: 'f_game',
      data: { game_id },
    };
    return this.http.post<GameByIdResponse>(`${environment.dctapiUrl}`, body);
  }

  public getTriviaGames(
    params: TriviaGamesRequestParams
  ): Observable<Trivia_Games[]> {
    const body: DoAction<TriviaGamesRequestParams> = {
      do_action: 'f_trivia_games',
      data: params,
    };
    return this.http
      .post<TriviaGamesResponse[]>(`${environment.dctapiUrl}`, body)
      .pipe(
        map((games_list) => {
          return this.sortTriviaGames<TriviaGamesResponse>(games_list);
        })
      );
  }

  public getGamesByTitle(title: string): Observable<{ data: null }> {
    const body: DoAction<{ title: string }> = {
      data: { title },
      do_action: 'f_get_game_by_title',
    };
    return this.http.post<{ data: null }>(`${environment.dctapiUrl}`, body);
  }

  public gamesCustomSort<T extends { created_at: string; updated_at: string }>(
    a: T,
    b: T
  ): number {
    if (a.updated_at > b.created_at) {
      return -1; // a should come before b
    } else if (b.updated_at > a.created_at) {
      return 1; // b should come before a
    } else {
      return 0; // no preference in ordering
    }
  }

  private sortTriviaGames<
    T extends { created_at: string; updated_at?: string }
  >(games_list: T[]): T[] {
    games_list.sort((a, b) => {
      if (!('updated_at' in a)) {
        a.updated_at = null;
      }
      if (!('updated_at' in b)) {
        b.updated_at = null;
      }
      return this.gamesCustomSort(
        a as RequiredTriviaGameDates,
        b as RequiredTriviaGameDates
      );
    });
    return games_list;
  }
}
