import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserTypes } from '@core/enums';
import { DoAction, UserListRequest, UserListResponse } from '@core/models';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserListService {
  constructor(private http: HttpClient) {}

  public getCompilersList(params: UserListRequest): Observable<any> {
    const body: DoAction<UserListRequest> = {
      do_action: 'f_get_compilers_list',
      data: params,
    };
    return this.http.post<any>(`${environment.dctapiUrl}`, body);
  }

  public getFactCheckerList(params: UserListRequest): Observable<any> {
    const body: DoAction<UserListRequest> = {
      do_action: 'f_get_factcheckers_list',
      data: params,
    };
    return this.http.post<any>(`${environment.dctapiUrl}`, body);
  }

  public getWriterList(params: UserListRequest): Observable<any> {
    const body: DoAction<UserListRequest> = {
      do_action: 'f_get_writers_list',
      data: params,
    };
    return this.http.post<any>(`${environment.dctapiUrl}`, body);
  }

  public getUserList(params: UserListRequest): Observable<UserListResponse> {
    switch (params.user_type_id) {
      case UserTypes.writer:
        return this.getWriterList(params);
      case UserTypes.factchecker:
        return this.getFactCheckerList(params);
      case UserTypes.compiler:
        return this.getCompilersList(params);
    }
  }
}
