import {Categories, Markets, Question_Bonuses, Question_Types} from "@gql/graphql";
import {Category} from "../models";
import {AbstractControl} from "@angular/forms";

export class FormHelper {
  public static decideFormValues(
    formItemList:
      | Question_Types[]
      | Markets[]
      | Category[]
      | Categories[]
      | Question_Bonuses[],
    formItemKey: string,
    value: string | number
  ): number {
    for (let i = 0; i < formItemList.length; i++) {
      if (value === formItemList[i][formItemKey]) {
        return i;
      }
    }
  }

  public static getControlWithStatus(
    controls: AbstractControl[],
    status: 'invalid' | 'touched' | 'valid'
  ): AbstractControl {
    return controls.find((control) => control[status]);
  }

}
