import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { UserService } from '@services/user.service';
import { CategoryResponse, ChangedCategories } from '@core/models';
import {UtilityHelper} from "@core/helpers";

@Injectable({
  providedIn: 'root',
})
export class CategoriesApiService {
  private options = {
    headers: UtilityHelper.bearerToken(
      this.userService.user.token
    )
  };

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {}

  public getCategoriesList(): Observable<CategoryResponse> {
    const url = `categories/categories-with-weights`;
    return this.http.get<CategoryResponse>(
      `${environment.triviaRushApiUrl}${url}`,
      this.options
    );
  }

  public saveCategories(
    changedData: ChangedCategories
  ): Observable<{ message: string }> {
    const url = `categories/weights`;
    return this.http.patch<{ message: string }>(
      `${environment.triviaRushApiUrl}${url}`,
      changedData,
      this.options
    );
  }
}
