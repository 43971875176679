import { Injectable } from '@angular/core';
import { QuestionDataService } from '../question-data.service';
import { RandomRoundQuestionRequest } from '@core/models';
import { RoundDataService } from './round-data.service';

@Injectable({ providedIn: 'root' })
export class CtRoundService {
  constructor(
    private questionDataService: QuestionDataService,
    private roundDataService: RoundDataService
  ) {}

  /**
   * Prepares search form data to replace question
   */
  public getQueryData(
    questionForm: any,
    template_id: string,
    ctQuestionTypesIds: number[]
  ): Partial<RandomRoundQuestionRequest> {
    const query: Partial<RandomRoundQuestionRequest> = {};
    for (const key in questionForm) {
      if (questionForm[key]) {
        switch (key) {
          case 'question_type':
            query.question_type_id = questionForm[key]
              ? [questionForm[key]]
              : ctQuestionTypesIds;
            break;
          default:
            query[key] = questionForm[key];
            break;
        }
      }
    }
    return query;
  }
}
