import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Trivia_Round_Questions } from '@core/generated-gql/graphql';
import { DoAction } from '@core/models';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RemoveRoundQuestionService {
  constructor(private http: HttpClient) {}

  public deleteRoundQuestion(
    question_id: string,
    round_id: string
  ): Observable<Pick<Trivia_Round_Questions, 'question_id'>> {
    const body: DoAction<{ question_id: string; round_id: string }> = {
      data: { question_id, round_id },
      do_action: 'f_trivia_round_question_delete',
    };
    return this.http.post<Pick<Trivia_Round_Questions, 'question_id'>>(
      `${environment.dctapiUrl}`,
      body
    );
  }
}
