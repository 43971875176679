import { Injectable } from '@angular/core';
import { QuestionStatusFiler } from '@core/models';

@Injectable({ providedIn: 'root' })
export class GameFilterService {
  constructor() {}

  public getQuestionStatusValues(): QuestionStatusFiler[] {
    return [
      { id: 'used', value: 'Used' },
      { id: 'unused', value: 'Unused' },
    ];
  }
}
