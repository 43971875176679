export const tmStandardRoundForm = {
  answer: '',
  point_value: null,
  question_id: '',
  question_type: '',
  questions: '',
  round_question_id: '',
  answer_type_id: 2,
  image: '',
  position: '',
  wager: false,
};


