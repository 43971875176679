import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationConstants } from '@constants/validation.constants';
import { AnswerTypeIdEnum, QuestionRating, QuestionTypeIds } from '@core/enums';
import { Questions_Set_Input } from '@core/generated-gql/graphql';
import {
  BaseFormGroup,
  BonusRoundData,
  ChooseTenFormGroup,
  CommonJsonControls,
  MultipleChoiceFormGroup,
  MultipleChoiceFormType,
  QuestionJsonForm,
  RangeFormGroup,
  RangeFormType,
  StandardBlankRoundData,
  StandardRoundData,
} from '@core/models';
import { UserService } from '../../user.service';
import { v4 as uuidv4 } from 'uuid';
import { ChooseTenService } from '../../question-types';
import {CustomValidator} from "@core/validators";

@Injectable({ providedIn: 'root' })
export class FormQuestionJsonTMService {
  private constants = ValidationConstants;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private chooseTenService: ChooseTenService
  ) {}

  public MCAnswersForm(data?: MultipleChoiceFormType): MultipleChoiceFormGroup {
    const answersValidators = [
      Validators.required,
      Validators.pattern(ValidationConstants.NO_WHITE_SPACE),
      Validators.maxLength(this.constants.questionAnswerMaxLength),
    ];
    let {
      answer = '',
      wrongAnswer1 = null,
      wrongAnswer2 = null,
      wrongAnswer3 = null,
    } = { ...data };
    return {
      answer: [answer || '', answersValidators],
      wrongAnswer1: [wrongAnswer1 ? wrongAnswer1[0] : null, answersValidators],
      wrongAnswer2: [wrongAnswer2 ? wrongAnswer2[0] : null, answersValidators],
      wrongAnswer3: [wrongAnswer3 ? wrongAnswer3[0] : null, answersValidators],
    };
  }
  public MCAnswersArray(): { key: string; value: string; label: string }[] {
    return [
      { key: 'answer', value: '', label: 'Correct Answer' },
      { key: 'wrongAnswer1', value: '', label: 'Wrong Answer 1' },
      { key: 'wrongAnswer2', value: '', label: 'Wrong Answer 2' },
      { key: 'wrongAnswer3', value: '', label: 'Wrong Answer 3' },
    ];
  }

  public rangeForm(item?: RangeFormType): FormGroup {
    const answersValidators = [
      CustomValidator.required,
      Validators.pattern(this.constants.NUMBERS_REGEXP),
    ];
    let { range = 1, answer = '' } = { ...item };
    return this.fb.group({
      range: [range || 1, [Validators.min(1)]],
      answer: [answer || '', answersValidators],
    });
  }

  public commonJsonControls(
    question: StandardBlankRoundData | BonusRoundData
  ): CommonJsonControls {
    switch (question.question_type_id) {
      case QuestionTypeIds.CHOOSE_TEN:
        return {
          answers: this.chooseTenService.convertJsonAnswersObject(
            question.question_json as ChooseTenFormGroup & BaseFormGroup
          ),
        };

      default:
        return { answers: {} };
    }
  }

  public commonQuestionFormControls(
    question: StandardBlankRoundData | BonusRoundData,
    isInsert: boolean
  ): Questions_Set_Input {
    const question_json = question.question_json as BaseFormGroup;
    const question_text =
      question_json?.question_text_image?.question_text ||
      question_json?.question_text;

    const data: Questions_Set_Input = {
      writer_user_id: this.userService.user.user_id,
      created_datetime: new Date().toISOString(),
      question_type_id: question.question_type_id,
      question_id: question.question_id || uuidv4(),
      market_id: 'usa',
      point_value: question.point_value || null,
      question_rating: QuestionRating['all-ages'],
      question_text:
        (question as StandardRoundData).questions ||
        (question as BonusRoundData).question_text ||
        question_text ||
        '',
      question_image: (question as StandardBlankRoundData).image,
      question_json: {
        ...this.commonJsonControls(question),
        ...question.question_json,
      },
    };
    if (question.round_type === 'bonus') {
      this.addControlsForTMBonusRound(question, data);
    }

    if ((question as StandardBlankRoundData)?.image) {
      (data.question_image = (question as StandardBlankRoundData).image),
        (data.question_json.image = (question as StandardBlankRoundData).image);
    }

    if (!isInsert) {
      data['approved_user_id'] = this.userService.user.user_id;
      data['approved_datetime'] = new Date().toISOString();
    }
    return data;
  }

  public addControlsForTMBonusRound(
    question: StandardBlankRoundData | BonusRoundData,
    formattedQuestion: Questions_Set_Input
  ): void {
    if (question.category && question.sub_category) {
      formattedQuestion.category_id = question.category;
      formattedQuestion.sub_category_id = question.sub_category;
    }
  }

  public ChangeMCValidatorsByAnswerType(
    questionJsonForm: FormGroup,
    answerTypeId: number
  ): void {
    const wrongAnswers = this.MCAnswersArray().filter(
      (e) => e.key !== 'answer'
    );
    switch (answerTypeId) {
      case AnswerTypeIdEnum.input:
        wrongAnswers.forEach((e) => {
          questionJsonForm?.get(e.key)?.removeValidators([Validators.required]);
          questionJsonForm?.get(e.key)?.updateValueAndValidity();
        });
        break;

      case AnswerTypeIdEnum.same:
        wrongAnswers.forEach((e) => {
          questionJsonForm.get(e.key)?.addValidators([Validators.required]);
        });
        break;
    }
  }

  public formQuestionJsonByTypeId(
    question_type_id: number,
    item?: QuestionJsonForm
  ): QuestionJsonForm {
    let form: QuestionJsonForm;
    switch (question_type_id) {
      case QuestionTypeIds.MULTI_CHOICE:
        form = this.MCAnswersForm(item as MultipleChoiceFormType);
        break;
      case QuestionTypeIds.RANGE:
        form = this.rangeForm(item as RangeFormType).value;
        break;
    }
    return form;
  }
}
