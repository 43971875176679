import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MultiChoiceService } from '../question-types';

@Injectable({ providedIn: 'root' })
export class QuestionFormUtilityService {
  constructor(private multiChoiceService: MultiChoiceService) {}

  public getInvalidFormControls(
    form: FormGroup | FormArray,
    questionTypeId: number
  ): string[] {
    let invalidControls: string[] = [];
    if (typeof questionTypeId === 'number' && !questionTypeId) {
      return this.multiChoiceService.getInvalidFormControls(form as FormGroup);
    }

    if (form instanceof FormGroup) {
      for (let item in form.controls) {
        const ctrl = form.controls[item] as FormArray | FormGroup;
        if (ctrl instanceof FormControl) {
          if (ctrl.invalid) {
            ctrl.markAsTouched();
            invalidControls.push(form.getRawValue()?.uniqueId);
          }
        } else {
          invalidControls.push(
            ...this.getInvalidFormControls(ctrl, questionTypeId)
          );
        }
      }
    } else if (form instanceof FormArray) {
      for (let i = 0; i < form.controls.length; i++) {
        const ctrl = form.controls[i];
        if (ctrl instanceof FormControl) {
          if (ctrl.invalid) {
            ctrl.markAsTouched();
            invalidControls.push(i.toString());
          }
        } else {
          invalidControls.push(
            ...this.getInvalidFormControls(
              ctrl as FormArray | FormGroup,
              questionTypeId
            )
          );
        }
      }
    }

    return invalidControls;
  }
}
