import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {QuestionStatuses} from '@core/enums';
import {DoAction} from '@core/models';
import {UserService} from '@core/services/user.service';
import {environment} from '@env';
import {GetQuestionJsonQuery, QuestionByIdQuery} from '@gql/graphql';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GetQuestionDataService {
  constructor(private http: HttpClient, private userService: UserService) {
  }

  public getQuestionById(
    question_id: string
  ): Observable<QuestionByIdQuery['questions'][0]> {
    const body: DoAction<{ question_id: string }> = {
      do_action: 'f_question',
      data: {
        question_id,
      },
    };
    return this.http
      .post<QuestionByIdQuery>(`${environment.dctapiUrl}`, body)
      .pipe(map((e) => e.questions[0]));
  }

  public factCheckedQuestionsCount(): Observable<any> {
    if (this.userService?.user) return of()
    const body: DoAction<{
      writer_user_id: string;
      question_status_id: number;
    }> = {
      do_action: 'f_factchecked_questions_count',
      data: {
        writer_user_id: this.userService?.user?.user_id,
        question_status_id: QuestionStatuses.factchecked,
      },
    };
    return this.http.post(`${environment.dctapiUrl}`, body).pipe(
      map((e) => {
        console.log(e);

        return e;
      })
    );
  }

  public getQuestionJSONByQuestionId(
    question_id: string
  ): Observable<GetQuestionJsonQuery['questions'][0]> {
    const body: DoAction<{ question_id: string }> = {
      data: {question_id},
      do_action: 'f_question_json',
    };
    return this.http.post<GetQuestionJsonQuery['questions'][0]>(
      `${environment.dctapiUrl}`,
      body
    );
  }

  public getFirstApprovedQuestionDate(params: {
    question_status_id: number;
    writer_user_id: string;
  }): Observable<string> {
    const body: DoAction<any> = {
      do_action: 'f_first_factchecked_question',
      data: {...params},
    };
    return this.http.post<string>(`${environment.dctapiUrl}`, body);
  }
}
