import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormValidationConstants } from '@constants/form-validation.constants';
import { GameTemplatesEnum, Roles } from '@core/enums';
import { GameByPkQuery } from '@core/generated-gql/graphql';
import {
  BlankRoundData,
  BonusForm,
  GameByIdResponse,
  QuestionJsonForm,
  RoundForms,
} from '@core/models';
import { UserService } from '@core/services/user.service';
import { PopulateRoundsDataService } from '../../populate-rounds-datas.service';
import { FormQuestionJsonTMService } from '@core/services/question';
@Injectable({ providedIn: 'root' })
export class PopulateBlankRoundsService {
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private populateRoundsDataService: PopulateRoundsDataService,
    private formQuestionJsonTMService: FormQuestionJsonTMService
  ) {}

  public getStandardRoundQuestionsCount(params: {
    templateId: string;
    round: string;
  }) {}

  public async formBlankPlaceholders(
    gameByPk: GameByIdResponse
  ): Promise<RoundForms[][]> {
    if (gameByPk.game_template_id === GameTemplatesEnum.default) {
      const finalRound =
        gameByPk.trivia_game_rounds[gameByPk.trivia_game_rounds.length - 1];
      const standardRounds = gameByPk.trivia_game_rounds.slice(0, 4);
      const result = [
        ...(await this.populateRoundsData({
          gameData: gameByPk,
          triviaGameRounds: standardRounds,
          bonusRoundQuestionsCount: 0,
          standardRoundQuestionsCount: 8,
        })),
        ...(await this.populateRoundsData({
          gameData: gameByPk,
          triviaGameRounds: [finalRound],
          bonusRoundQuestionsCount: 0,
          standardRoundQuestionsCount: 1,
        })),
      ];
      return result;
    }
    if (gameByPk.game_template_id === GameTemplatesEnum.ct) {
      return await this.populateRoundsData({
        gameData: gameByPk,
        triviaGameRounds: gameByPk.trivia_game_rounds,
        bonusRoundQuestionsCount: 1,
        standardRoundQuestionsCount: 11,
      });
    }
  }

  private populateRoundsData(params: {
    gameData: GameByIdResponse;
    triviaGameRounds?: GameByPkQuery['trivia_games_by_pk']['trivia_game_rounds'];
    bonusRoundQuestionsCount: number;
    standardRoundQuestionsCount: number;
  }): Promise<RoundForms[][]> {
    const rounds = this._formTriviaGameRounds(
      params.triviaGameRounds || params.gameData.trivia_game_rounds
    );
    return this.populateRoundsDataService.getRoundsData({
      rounds: rounds,
      gameTemplateId: params.gameData.game_template_id,
      bonusRoundQuestionsCount: params.bonusRoundQuestionsCount,
      standardRoundQuestionsCount: params.standardRoundQuestionsCount,
      include_json: 0,
    });
  }

  private _formTriviaGameRounds(
    triviaGameRounds: GameByPkQuery['trivia_games_by_pk']['trivia_game_rounds']
  ): BlankRoundData[] {
    return triviaGameRounds.map((round) => {
      return {
        round_id: round.round_id,
        round_type: round.trivia_round.round_type,
        round_name: round.trivia_round.trivia_round_type.name,
        round_order: round.round_order,
      };
    });
  }

  public createBlankRoundFormArray<T extends BonusForm>(
    rounds: T[]
  ): FormArray {
    if (!rounds) {
      return;
    }
    const controls = rounds.map((r) => this.generateRoundForm(r));
    return this.fb.array(controls);
  }

  public createQuestionFormJson<T extends BonusForm>(
    question_type_id: number,
    item?: T
  ): QuestionJsonForm {
    if (this.userService.user.role === Roles.triviamaticManager) {
      return (
        this.formQuestionJsonTMService.formQuestionJsonByTypeId(
          question_type_id,
          item?.question_json as QuestionJsonForm
        ) || (item?.question_json as QuestionJsonForm)
      );
    }
  }

  private generateRoundForm<
    T extends BonusForm & { questions?: string | (string | ValidatorFn[])[] }
  >(item: T): FormGroup {
    if (this.userService.user.role === Roles.triviamaticManager) {
      if (typeof item?.questions === 'string') {
        item.questions = [
          item.questions,
          FormValidationConstants.questionTextValidator,
        ];
      }

      return this.fb.group({...item, question_type_id: [item.question_type_id, Validators.required]});

    }
    return this.fb.group(item)
  }
}
