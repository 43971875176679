export class DateConstants {
  static readonly DATE_FORMAT = 'dd MMM yyyy';
  static readonly MOMENT_DATE_FORMAT = 'DD MMM YYYY';
  static readonly CUSTOM_DATE_FORMAT = {
    parse: {
      dateInput: [DateConstants.MOMENT_DATE_FORMAT, 'DD-MM-YYYY']
    },
    display: {
      dateInput: DateConstants.MOMENT_DATE_FORMAT,
      monthYearLabel: DateConstants.MOMENT_DATE_FORMAT,
      dateA11yLabel: DateConstants.MOMENT_DATE_FORMAT,
      monthYearA11yLabel: DateConstants.MOMENT_DATE_FORMAT
    },
  };

}
