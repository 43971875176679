import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class QuestionDataService {
  constructor() {}

  public formSQLArrayObject(array: any): any {    
    array = '{' + array.join(',') + '}';
    return array;
  }
}
