import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, LoginGuard, TriviaMaticManagerGuard } from '@core/guards';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./view/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./view/auth/auth.module').then(m => m.AuthModule),
    canActivate: [LoginGuard]
  },
  {
   path: 'user-check',
   component: AppComponent,
   loadChildren: () => import('./view/dashboard/dashboard.module').then(mod => mod.DashboardModule),
   canActivate: [TriviaMaticManagerGuard],
  },
  {
    path: '**', redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
