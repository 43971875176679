import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private _snackBar: MatSnackBar
  ) {
  }

  public openSnackBar(message: any, actionName: string = '', type?: string, isCenter?: boolean): void {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    let verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    if (isCenter) {
      horizontalPosition = 'center'
      verticalPosition = 'top';
    }

    this._snackBar.open(message, actionName, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: 4000,
      panelClass: [type!]
    });
  }
}
