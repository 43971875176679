import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { RoleIds, UserTypes } from '../enums';
import { Observable } from 'rxjs';
import {
  Compiler,
  CompilerUser,
  Factchecker,
  FactcheckerUser,
  StaffTable,
  UserListResponse,
  Writer,
  WriterUser,
} from '@models/index';
import { UserListService } from './trivia-users';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  constructor(private userListService: UserListService) {}

  listService;
  public writerTable: StaffTable = {
    displayedOptions: {
      displayedColumns: [
        'Full Name',
        'Questions Submitted',
        'Questions Rejected',
        'Questions Published',
        'edit',
      ],
      listName: 'Writers List',
      btnName: 'Add Writer',
      role: 'writer',
    },
    table: [
      { key: 'fullName', title: 'Full Name', class: 'align-left' },
      {
        key: 'submitted_count',
        title: 'Questions Submitted',
        class: 'align-middle',
      },
      {
        key: 'rejected_count',
        title: 'Questions Rejected',
        class: 'align-middle',
      },
      {
        key: 'published_count',
        title: 'Questions Published',
        class: 'align-middle',
      },
      { key: '', title: 'edit', class: 'align-right' },
    ],
  };

  public factcheckerTable: StaffTable = {
    displayedOptions: {
      displayedColumns: [
        'Full Name',
        'Questions Factchecked',
        'Questions Rejected',
        'Questions Accepted',
        'edit',
      ],
      listName: 'Factchecker List',
      btnName: 'Add Factchecker',
      role: 'factchecker',
    },
    table: [
      { key: 'fullName', title: 'Full Name', class: 'align-left' },
      {
        key: 'factchecked_count',
        title: 'Questions Factchecked',
        class: 'align-middle',
      },
      {
        key: 'rejected_count',
        title: 'Questions Rejected',
        class: 'align-middle',
      },
      {
        key: 'accepted_count',
        title: 'Questions Accepted',
        class: 'align-middle',
      },
      { key: '', title: 'edit', class: 'align-right' },
    ],
  };

  public compilerTable: StaffTable = {
    displayedOptions: {
      displayedColumns: [
        'Full Name',
        'Rounds Created',
        'Games Created',
        'Games Paid',
        'Errors in Gameplay',
        'Compiler Rate',
        'edit',
      ],
      listName: 'Compiler List',
      btnName: 'Add Compiler',
      role: 'compiler',
    },
    table: [
      { key: 'fullName', title: 'Full Name', class: 'align-left' },
      { key: 'rounds_created', title: 'Rounds Created', class: 'align-middle' },
      { key: 'games_created', title: 'Games Created', class: 'align-middle' },
      { key: 'games_paid', title: 'Games Paid', class: 'align-middle' },
      {
        key: 'gameplay_errors',
        title: 'Errors in Gameplay',
        class: 'align-middle',
      },
      { key: 'compiler_rate', title: 'Compiler Rate', class: 'align-middle' },
      { key: '', title: 'edit', class: 'align-right' },
    ],
  };

  private static mapWriterFn(user: Writer): WriterUser {
    return {
      fullName: `${user.firstname} ${user.lastname}`,
      user_id: user.trivia_user_id,
      published_count: user.published.count,
      rejected_count: user.rejected.count,
      submitted_count: user.submitted.count,
    };
  }

  private static mapFactcheckerFn(user: Factchecker): FactcheckerUser {
    return {
      fullName: `${user.firstname} ${user.lastname}`,
      user_id: user.trivia_user_id,
      accepted_count: user.accepted.count,
      rejected_count: user.rejected.count,
      factchecked_count: user.question_factchecked.count,
    };
  }

  private static mapCompileFn(user: Compiler): CompilerUser {
    return {
      fullName: `${user.firstname} ${user.lastname}`,
      user_id: user.trivia_user_id,
      rounds_created: 0,
      games_created: 0,
      games_paid: 0,
      gameplay_errors: 0,
      compiler_rate: 0,
    };
  }

  private static prepareUser(userTypeId: number, data: UserListResponse): {} {
    switch (userTypeId) {
      case RoleIds.writer:
        return data.writers.map(StaffService.mapWriterFn);
      case RoleIds.factchecker:
        return data.factcheckers.map(StaffService.mapFactcheckerFn);
      default:
        return data.compilers.map(StaffService.mapCompileFn);
    }
  }

  getUsersList([userTypeId, pageSize, pageNumber]: [
    number,
    number,
    number
  ]): Observable<any> {
    const limitKey = userTypeId === UserTypes.compiler ? 'limit' : 'count';
    return this.userListService
      .getUserList({
        user_type_id: userTypeId,
        [limitKey]: pageSize,
        offset: pageSize * pageNumber,
      })
      .pipe(
        first(),
        map((data) => {
          if (!data) {
            return;
          }
          const modifiedUsers = StaffService.prepareUser(userTypeId, data);
          return {
            count: data.total_count.count,
            data: modifiedUsers,
          };
        })
      );
  }
}
