import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangeLogStatuses, GameTemplateNames, UserTypes } from '@core/enums';
import {
  RejectionReasonNameGQL,
  Rejection_Reasons,
  Report_Reasons,
} from '@core/generated-gql/graphql';
import {
  ActivityLog,
  ActivityLogUserData,
  DoAction,
  FormattedLogData,
  QuestionEditLog,
  QuestionLog,
  RoundQuestionLog,
} from '@core/models';
import { environment } from '@env';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserDataService } from '@core/services/user-data/user-data.service';
import { ReportedQuestionService } from './reported-question.service';
@Injectable({ providedIn: 'root' })
export class ActivityLogService {
  constructor(
    private http: HttpClient,
    private userDataService: UserDataService,
    private rejectionReasonNameGQL: RejectionReasonNameGQL,
    private reportedQuestionService: ReportedQuestionService
  ) {}

  /**
   * formats question edit log data
   * @param e QuestionLog
   * @returns FormattedLogData[]
   */
  private formatQuestionLogData(e: QuestionLog): FormattedLogData[] {
    return e.editLogs.map((log) => {
      return {
        actionText: this.actionNameByLogTypes(log.edit_log_type_name),
        sentenceEndText: this.sentenceEndTextByLogTypes(log.edit_log_type_name),
        userData: this.formUserData(log),
        date: log.edit_datetime,
        reason: this.getReason(
          ChangeLogStatuses[log.edit_log_type_name],
          log.rejection_reason_id,
          log.question_id,
          log.edit_datetime
        ),
      };
    });
  }

  private formatRoundQuestionData(e: QuestionLog): FormattedLogData[] {
    return e.roundQuestionLogs.map((log) => {
      let title: string = log.title || log?.game?.title;
      let game_template_id =
        log?.game_template_id || log?.game?.game_template_id;
      if (!title) {
        title = log.game?.game_id || log?.game_id;
      }
      return {
        date: log.round_question_date,
        game_template_id,
        title,
        userData: this.getUserData(log?.creator_id || log?.game?.creator_id),
        actionText: this.actionTextByRoundLogs(
          game_template_id,
          title,
          log.added
        ),
        sentenceEndText: this.sentenceEndTextByRoundLogTypes(log.added),
      };

    });
  }

  private sentenceEndTextByRoundLogTypes(actionType: number): string {
    switch (actionType) {
      case 1:
        return 'question is removed from active database once added to a game in any state: saved, sent to scheduler, etc.';

      default:
        break;
    }
  }

  private sentenceEndTextByLogTypes(logTypeName: string): string {
    let sentenceEndText = '';
    switch (ChangeLogStatuses[logTypeName]) {
      case ChangeLogStatuses.published:
        sentenceEndText = 'question added to database';
        break;
      case ChangeLogStatuses.reject:
        sentenceEndText = 'gets sent back to writer';
        break;
      case ChangeLogStatuses.reported:
        sentenceEndText =
          'Question is sent to reported question queue, and removed from active database';
        break;
      case ChangeLogStatuses.update:
        sentenceEndText = 'question added back to Factchecking queue';
        break;
    }
    return sentenceEndText;
  }

  private getReason(
    edit_log_type_name: string,
    rejection_reason_id: number,
    question_id: string,
    date: string
  ):
    | Observable<Pick<Rejection_Reasons, 'rejection_reason_name'>>
    | Observable<
        Pick<Report_Reasons, 'report_reason_id' | 'report_reason_name'>
      > {
    switch (edit_log_type_name) {
      case ChangeLogStatuses.reject:
        return this.getRejectReason(rejection_reason_id);
      case ChangeLogStatuses.reported:
        return this.getReportReason(question_id, date) as Observable<
          Pick<Report_Reasons, 'report_reason_id' | 'report_reason_name'>
        >;
    }
  }

  private getRejectReason(
    id: number
  ): Observable<Pick<Rejection_Reasons, 'rejection_reason_name'>> {
    const body: DoAction<{reason_id: number}> = {
      data: {reason_id: id},
      do_action: 'rejection_reason_name'
    }
    return this.rejectionReasonNameGQL
      .fetch({ reasonId: id }, { fetchPolicy: 'network-only' })
      .pipe(map((e) => e.data.rejection_reasons[0]));
  }

  private getReportReason(
    question_id: string,
    created_at: string
  ): Observable<
    Pick<Report_Reasons, 'report_reason_id' | 'report_reason_name'>
  > {
    const body: DoAction<{created_at: string, question_id: string}> = {
      data: {
        created_at, question_id
      },
      do_action: 'f_question_report_reason'
      
    }
    return this.http.post<Report_Reasons>(`${environment.dctapiUrl}`, body)
  
  }

  public getQuestionLogs(questionId: string): Observable<[QuestionLog]> {
    return forkJoin([
      this.http
        .get<QuestionLog>(
          `${environment.restApiUrl}games/get-question_logs?questionId=${questionId}
        `
        )
        .pipe(
          catchError((e) => {
            console.log(e);
            return [];
          })
        ),
      // this.reportedQuestionService.getReportedQuestionData(questionId).pipe(
      //   catchError((e) => {
      //     console.log(e);
      //     return [];
      //   })
      // ),
    ]);
  }

  public forActivityLogInputData(
    questionId: string,
    writerId: string,
    date: string
  ): ActivityLog {
    return {
      writer_user_id: writerId,
      question_id: questionId,
      date,
    };
  }

  public getUserData(userId: string): Observable<ActivityLogUserData> {
    return this.userDataService.formUserDataById(false, userId).pipe(
      map((e) => {
        return {
          firstName: e.firstname,
          lastName: e.lastname,
          type: e?.user_type?.user_type_name,
        };
      })
    );
  }

  /**
   * returns action text string based on log type name
   * @param logTypeName string
   * @returns string
   */
  public actionNameByLogTypes(logTypeName: string, reason?: string): string {
    let actionText = '';
    switch (ChangeLogStatuses[logTypeName]) {
      case ChangeLogStatuses.published:
        actionText = 'Question Accepted';
        break;
      case ChangeLogStatuses.reject:
        actionText = ' Question Rejected';
        break;
      case ChangeLogStatuses.reported:
        actionText = 'Reported the question for';
        break;
      case ChangeLogStatuses.update:
        actionText = 'Question Resubmitted';
        break;
      case ChangeLogStatuses.edit:
        actionText = 'Question Edited';
    }
    return actionText;
  }

  public actionTextByRoundLogs(
    templateId: string,
    gameTitle: string,
    added: number
  ): string {
    let actionText = '';
    switch (added) {
      case 1:
        actionText = `Question Added to ${gameTitle} -`;
        break;
      case 0:
        actionText = `Removed a question from ${GameTemplateNames[templateId]} -`;
      default:
        break;
    }

    return actionText;
  }

  public modifyRoundQuestionLog(
    data: {
      game_id: string;
      round_id: string;
      round_order: number;
    }[]
  ): Observable<any> {
    return this.http.post<Observable<any>>(
      `${environment.restApiUrl}games/round-question-log-game-add`,
      { data }
    );
  }

  public formatActivityLogData(e: [QuestionLog]): any {
    const data = {} as any;
    data.editLogs = this.formatQuestionLogData(e[0]);
    data.roundQuestionLogs = this.formatRoundQuestionData(e[0]);
    const mergedArray = [...data.editLogs, ...data.roundQuestionLogs];

    mergedArray.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    return mergedArray as FormattedLogData[];
  }

  /**
   * gets userData
   */
  public formUserData(
    log: RoundQuestionLog | QuestionEditLog
  ): ActivityLogUserData | Observable<ActivityLogUserData> {
    const questionEditLog = log as QuestionEditLog;

    switch (questionEditLog?.edit_log_type_name) {
      case ChangeLogStatuses.edit:
        return {
          firstName: questionEditLog.editorfirstname,
          lastName: questionEditLog.editorlastname,
          type: UserTypes[questionEditLog.editorusertypeid],
        };
      case ChangeLogStatuses.reject:
        return {
          firstName: questionEditLog.rejectorfirstname || log.firstname,
          lastName: questionEditLog.rejectorlastname || log.lastname,
          type: UserTypes[
            questionEditLog.rejectorusertypeid || log.user_type_id
          ],
        };
      case ChangeLogStatuses.update:
        return {
          firstName: questionEditLog.writerfirstname,
          lastName: questionEditLog.writerlastname,
          type: UserTypes[questionEditLog.writerusertypeid],
        };
      case ChangeLogStatuses.published:
        return {
          firstName: log.firstname,
          lastName: log.lastname,
          type: UserTypes[log.user_type_id],
        };
      default:
        return this.getUserData(questionEditLog.user_id);
    }
  }
}
