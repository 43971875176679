import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, lastValueFrom, map, of, switchMap } from 'rxjs';
import { environment } from '@env';
import { v4 as uuidv4 } from 'uuid';
import { GetGamesService } from './get-games.service';
import { GenerateGameService } from '@core/services/generate-game.service';
import {
  CopyGameResponse,
  DoAction,
  GameAddAction,
  GameByIdResponse,
} from '@core/models';
import { RoundRequestsService } from '../round';
import { MutateTriviaRoundQuestionService } from '../mutate-trivia-round-question.service';
import { Trivia_Game_Rounds } from '@core/generated-gql/graphql';

@Injectable({ providedIn: 'root' })
export class CopyGameService {
  constructor(
    private http: HttpClient,
    private getGamesService: GetGamesService,
    private roundRequestsService: RoundRequestsService,
    private generateGameService: GenerateGameService,
    private mutateTriviaRoundQuestionService: MutateTriviaRoundQuestionService
  ) {}

  public copyGame(gameId: string): Observable<CopyGameResponse> {
    const originalGameData = this.getGamesService.getGameById(gameId).pipe(
      switchMap((e) => {
        e.trivia_game_rounds.sort((a, b) => {
          return a.round_order - b.round_order;
        });
        return this.cloneGame(e);
      })
    );
    // return this.http.post(`${environment.restApiUrl}games/copy-game`, {
    //   gameId,
    // });
    return originalGameData;
  }

  // Promise<{
  //   game_id: string;
  //   title: string;
  //   game_status: number;
  //   game_template_id: number;
  //   created_at: Date | string;
  // }>

  public async cloneGame(
    originalGameData: GameByIdResponse
  ): Promise<CopyGameResponse> {
    try {
      const newGameId = uuidv4();
      const rounds: Partial<Trivia_Game_Rounds>[] = [];

      let originTitle = this.replaceApostropheV2(
        originalGameData.title == null ? 'No Title' : originalGameData.title
      );

      let title = await this.checkGameTitle(originTitle, originTitle, 1);

      await lastValueFrom(
        this.copyGameReq({
          new_game_id: newGameId,
          title,
          game_status: 1,
          originalGameData,
        })
      );
      for (let round of originalGameData.trivia_game_rounds) {
        let newRoundId = uuidv4();
        await lastValueFrom(this.duplicateRounds(newRoundId, round.round_id));

        rounds.push({ round_id: newRoundId, round_order: round.round_order });
        let getRoundQuestions = await lastValueFrom(
          this.roundRequestsService.roundQuestionByRoundId(round.round_id).pipe(
            map((round_questions) => {
              return round_questions.map((round_question) => {
                const {
                  question,
                  question_order,
                  answer_type_id,
                  wager,
                  wager_range_value,
                } = round_question;
                return this.mutateTriviaRoundQuestionService.addRoundQuestions({
                  round_id: newRoundId,
                  question_id: question.question_id,
                  question_order,
                  answer_type_id,
                  wager,
                  wager_range_value,
                });
              });
            })
          )
        );

        const addedRoundQuestions = await lastValueFrom(
          forkJoin(getRoundQuestions)
        );
      }

      const gameRounds = rounds.map((round, index) => ({
        game_id: newGameId,
        round_id: round.round_id,
        round_order: round.round_order,
      }));
      const newGame = await lastValueFrom(
        this.generateGameService.addRoundsToGame(gameRounds, newGameId)
      );
      return {
        game_id: newGameId,
        title: title,
        game_status: 1,
        game_template_id: originalGameData.game_template_id,
        created_at: new Date(),
      };
    } catch (e) {
      console.log('automated err : ', e);
    }
  }

  private replaceApostropheV2(str: string): string {
    return str.replace(/‘|’|`|'/g, '’');
  }

  private async checkGameTitle(title: string, newTitle: string, num: number) {
    let checkTitle: string;
    if (num === 1) {
      checkTitle = `COPY - ${newTitle}`;
    } else {
      checkTitle = `COPY ${newTitle}`;
    }
    let gameByTitle = await lastValueFrom(
      this.getGamesService.getGamesByTitle(checkTitle)
    );

    if (gameByTitle.data) {
      return await this.checkGameTitle(title, `(${num})-${title}`, num + 1);
    } else {
      return checkTitle;
    }
  }

  private copyGameReq(params: {
    originalGameData: GameByIdResponse;
    new_game_id: string;
    title: string;
    game_status: number;
  }): Observable<string> {
    const { new_game_id, title, game_status, originalGameData } = params;
    const {
      game_difficult_id,
      keywords,
      description,
      category,
      creator_id,
      rating,
      market,
      game_template_id,
    } = originalGameData;
    const body: DoAction<GameAddAction> = {
      do_action: 'f_game_add',
      data: {
        game_difficult_id,
        keywords,
        description,
        category,
        creator_id,
        rating,
        market,
        game_template_id,
        game_id: new_game_id,
        game_status,
        title,
      },
    };

    return this.http.post<string>(`${environment.dctapiUrl}`, body);
  }

  private duplicateRounds(
    new_round_id: string,
    original_id: string
  ): Observable<any> {
    const body: DoAction<{ new_round_id: string; round_id: string }> = {
      do_action: 'f_trivia_round_duplicate',
      data: {
        new_round_id,
        round_id: original_id,
      },
    };
    return this.http.post(`${environment.dctapiUrl}`, body);
  }
}
