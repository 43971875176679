import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { QuestionData } from '@models/question-data.interface';

@Injectable({ providedIn: 'root' })
export class MobileViewControlService {
  private activeQuestionPosition = 0;
  public selection = new SelectionModel<QuestionData>(true, []);
  private selectedElements: Partial<QuestionData>[] = [];
  public selectedQuestionIndex = 0;

  public get getActiveQuestionPosition(): number {
    return this.activeQuestionPosition;
  }

  public set setActiveQuestionPosition(newPosition: number) {
    this.activeQuestionPosition = newPosition - 1;
  }

  public get getSelectedElements(): Array<Partial<QuestionData>> {
    return this.selectedElements;
  }

  public set setSelectedElements(value: Array<Partial<QuestionData>>) {
    this.selectedElements = value;
  }
  constructor() {}

  public onQuestionCheck(
    element: QuestionData & { question_id: string },
    questionOrderInGame?: number
  ): void {
    const selectedElementsLength = this.selectedElements.length;
    const isSelected = !!this.selectedElements.find(
      (e) => e.question_id === element.question_id
    );
    if (!isSelected) {
      this.selectedElements.push(element);
    } else {
      const elementIndex = this.selectedElements.findIndex(
        (e) => e.position === element.position
      );
      this.selectedElements.splice(elementIndex, 1);
    }
    if (
      (element.position - 1 === this.selectedQuestionIndex && isSelected) ||
      !selectedElementsLength
    ) {
      this.selectedQuestionIndex =
        this.selectedElements.length === 0
          ? element.position - 1
          : this.selectedElements.sort((a, b) => a.position - b.position)[0]
              .position - 1;
    }
    // this.selectedElements[this.selectedElements.length - 1].position - 1

    if (questionOrderInGame) {
      this.selectedQuestionIndex = questionOrderInGame;
    }
  }
}
