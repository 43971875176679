import {Injectable} from '@angular/core';
import {Trivia_Games, InsertTriviaEventOneMutation, EventGameMutationMutation, Trivia_Events} from '@gql/graphql';
import {catchError, take, takeUntil} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {DoAction, InsertTriviaEventData, UpdateEventData} from '@core/models';
import {UserService} from '@core/services';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env';
import {GameStatuses} from '@core/enums';
import {UtilityHelper} from "@core/helpers";

@Injectable({providedIn: 'root'})
export class SchedulerService {
  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  getrandomThemeIndex(game: Trivia_Games): number | string {
    let index: number | string;
    if (game.trivia_game_themes.length === 0) {
      index = 'No theme';
    }
    if (game.trivia_game_themes.length > 1) {
      index = Math.floor(Math.random() * game.trivia_game_themes.length);
    }
    if (game.trivia_game_themes.length === 1) {
      index = 0;
    }
    return index;
  }

  public updateGameStatus(
    game_id: string,
    game_status: number,
    otherVars: object = {}
  ): void {
    const body: DoAction<{
      game_id: string;
      game_status: number;
      is_demo?: 0 | 1;
    }> = {
      data: {
        game_id,
        game_status,
        ...otherVars,
      },
      do_action: 'f_game_update_status',
    };
    this.http
      .post<{ game_status: number; game_id: string }>(
        `${environment.dctapiUrl}`,
        body
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  public deleteEvent(
    event_id: string,
    gameId: string,
    deleteFn: (arr?: any[]) => void
  ): void {
    const body: DoAction<{ event_id: string }> = {
      data: {event_id},
      do_action: 'f_trivia_event_delete',
    };
    this.http
      .post(`${environment.dctapiUrl}`, body)
      .pipe(take(1))
      .subscribe(
        () => {
          this.updateGameStatus(gameId, GameStatuses.submitted, {is_demo: 1});
          return deleteFn();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  public addEvent(
    data: InsertTriviaEventData
  ): Observable<InsertTriviaEventOneMutation> {
    const {trivia_user_id, event_type_id, game_id, template_id} = data;
    if (!trivia_user_id || !event_type_id || !game_id) {
      return;
    }
    const body: DoAction<InsertTriviaEventData> = {
      data: {
        trivia_user_id,
        event_type_id,
        game_id,
        date: UtilityHelper.YYYYMMDDFormat(new Date(data.date)),
        is_demo: data.is_demo,
        template_id,
      },
      do_action: 'f_trivia_event_add',
    };
    return this.http
      .post<InsertTriviaEventOneMutation>(`${environment.dctapiUrl}`, body)

      .pipe(takeUntil(this.unsubscribe$));
  }

  public updateEvent(
    data: UpdateEventData & { new_game_id: string }
  ): Observable<
    EventGameMutationMutation['update_trivia_events']['returning']
  > {
    const body: DoAction<UpdateEventData & { game_id: string }> = {
      data: {
        // TODO: HERE HAS TO BE TEMPLATE ID AND IS DEMO FOR UPDATE
        event_id: data.event_id,
        game_id: data.new_game_id,
        event_type_id: data.event_type_id,
        trivia_user_id: data.trivia_user_id,
      },
      do_action: 'f_trivia_event_update',
    };

    return this.http
      .post<EventGameMutationMutation['update_trivia_events']['returning']>(
        `${environment.dctapiUrl}`,
        body
      )
      .pipe(
        catchError((e) => {
          console.log(e);
          return of(null);
        })
      )
      .pipe(takeUntil(this.unsubscribe$));
  }

  public updateIsDemoValue(game_id: string, is_demo: 0 | 1): Observable<any> {
    const body: DoAction<{ game_id: string; is_demo: 0 | 1 }> = {
      data: {
        game_id,
        is_demo,
      },
      do_action: 'f_update_is_demo_value',
    };
    return this.http
      .post(`${environment.dctapiUrl}`, body)
      .pipe(takeUntil(this.unsubscribe$));
  }

  public getEventsByDate(date: string): Observable<Trivia_Events[]> {
    const body: DoAction<{ date: string; user_id: string }> = {
      do_action: 'f_get_scheduled_games_by_date',
      data: {date, user_id: this.userService.user.user_id},
    };
    return this.http.post<Trivia_Events[]>(`${environment.dctapiUrl}`, body);
  }
}
