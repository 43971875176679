import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidationConstants} from '@constants/form-validation.constants';
import {ValidationConstants} from '@constants/validation.constants';
import {
  BaseFormGroup,
  ChooseTenFormGroup,
  CTCorrectAnswerGroup,
  CTWrongAnswerGroup,
  FormatQuestionJsonOptionsObj,
  QuestionFromGroupsUnion,
} from '@core/models';
import {QuestionTypeUtilityService} from './question-type.utility.service';

@Injectable({providedIn: 'root'})
export class ChooseTenService {
  constructor(
    private fb: FormBuilder,
    private questionTypeUtilityService: QuestionTypeUtilityService
  ) {
  }

  public chooseTenForm(
    questionDataHasItems: boolean,
    questionData: ChooseTenFormGroup
  ): FormGroup {
    const formGroup = this.fb.group({
      question_text_image: this.fb.group({
        question_text: ['', FormValidationConstants.questionTextValidator],
      }),
      correctAnswersNumber: [10],
      correctAnswers: this.fb.array([], Validators.required),
      wrongAnswersNumber: [10],
      wrongAnswers: this.fb.array([], Validators.required),
      hint: [
        '',
        [Validators.maxLength(ValidationConstants.questionHintMaxLength)],
      ]
    });
    if (questionData) {
      const question_text =
        questionData.question_text ||
        questionData.question_text_image.question_text;
      formGroup.patchValue({
        ...questionData,
        question_text_image: {question_text},
      });
      if (!questionDataHasItems) {
        return formGroup;
      }

      this.questionTypeUtilityService.patchValuesToFormArray(
        formGroup.get('wrongAnswers') as FormArray,
        questionData.wrongAnswers,
        (item: CTWrongAnswerGroup) =>
          this.generateNewWrongAnswer(item?.wrong_answer?.[0])
      );
      this.questionTypeUtilityService.patchValuesToFormArray(
        formGroup.get('correctAnswers') as FormArray,
        questionData.correctAnswers,
        (item: CTCorrectAnswerGroup) =>
          this.generateNewCorrectAnswer(item?.correct_answer?.[0])
      );

      let wrongAnswers = []
      let correctAnswers = []
      questionData.wrongAnswers.forEach(value => {
        wrongAnswers.push({wrong_answer: value.wrong_answer})
      })
      questionData.correctAnswers.forEach(value => {
        correctAnswers.push({correct_answer: value.correct_answer})
      })

      formGroup.get('wrongAnswers').setValue(wrongAnswers);
      formGroup.get('correctAnswers').setValue(correctAnswers);
    }
    return formGroup;
  }

  public generateNewWrongAnswer(wrong_answer: string): CTWrongAnswerGroup {
    return {
      wrong_answer: [wrong_answer, FormValidationConstants.answerValidator],
    };
  }

  public generateNewCorrectAnswer(
    correct_answer: string
  ): CTCorrectAnswerGroup {
    return {
      correct_answer: [correct_answer, FormValidationConstants.answerValidator],
    };
  }

  public convertJsonAnswersObject(
    json: BaseFormGroup & QuestionFromGroupsUnion
  ): object {
    const correctAnswerParams = {
      questionJson: json,
      key: 'correctAnswers',
      innerObjectKey: 'correct_answer',
      newValue: true,
    };
    const wrongAnswerParams = {
      questionJson: json,
      key: 'wrongAnswers',
      innerObjectKey: 'wrong_answer',
      newValue: false,
    };
    const correctAnswers = this.formatQuestionJson(correctAnswerParams);
    const wrongAnswers = this.formatQuestionJson(wrongAnswerParams);
    return {
      ...correctAnswers,
      ...wrongAnswers,
    };
  }

  public formatQuestionJson({
                              questionJson,
                              key,
                              innerObjectKey,
                              newValue,
                            }: FormatQuestionJsonOptionsObj): object {
    const newObj = {};
    const answersObjectsArray = questionJson[key];
    if (!answersObjectsArray) {
      return newObj;
    }
    answersObjectsArray.forEach((answerObject) => {
      const answerKey = (answerObject[innerObjectKey] as string)?.trim();
      newObj[answerKey] = newValue; // sets the key of newObj from value of answerObject;
    });
    return newObj;
  }
}
