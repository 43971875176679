import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckTriviaUserService {
  constructor(private http: HttpClient) {}

  public checkUser(options: HttpHeaders): Observable<any> {    
    return this.http.post<boolean>(
      `${environment.restApiUrl}auth/trivia-login`,
      {},
      {
        headers: options,
      }
    );
  }
}
