import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MatSnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  success(message: string): void {
    this.snackBar.open(message, null, {
      duration: 4400,
      panelClass: 'success-snack',
    });
  }
  error(message: string): void {
    this.snackBar.open(message, null, {
      duration: 4400,
      panelClass: 'error-snack',
    });
  }

  info(message: string): void {
    this.snackBar.open(message, null, {
      duration: 4400,
      panelClass: 'info-snack',
    });
  }
}
