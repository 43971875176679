import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';




@Injectable({
    providedIn: 'root'
})
export class MatCheckboxSelectionService<T> {
    public selection = new SelectionModel<T>(true, []);
    private selectionData = [];

    public get getSelectionData() {
        return this.selectionData;
    }

    public set setSelectionData(data) {
        this.selectionData = data;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.selectionData.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        this.isAllSelected() ?
            this.selection.clear() :
            this.selectionData.forEach(row => this.selection.select(row));
    }
}
