import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {map, startWith, switchMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {Observable, of, Subscription} from 'rxjs';
import {ConfirmDialogComponent} from '@shared/components/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogResponse, ConfirmModalOptions, GenerateFormArrayArgs} from '@core/models';
import {QuestionTypeIds} from '@core/enums';
import {PopulateBlankRoundsService} from '../compiler';
import {QuestionTypeConstants} from '@constants/question-types.constants';
import {DoActionRequestsService} from '@core/services';
import {Question_Types} from '@core/generated-gql/graphql';
import {QuestionTypeValidatorsService} from '../validation';
import {ObjectHelper} from "@core/helpers";

interface FormData<T> {
  value: number;
  formArray: FormArray;
  group?: T;
  validators?: any;
  newAnswer?: object;
  originalFormArray?: T[];
  nested?: T;
}

@Injectable({providedIn: 'root'})
export class QuestionTypeService {
  constructor(
    private matDialog: MatDialog,
    private fb: FormBuilder,
    private populateBlankRoundsService: PopulateBlankRoundsService,
    private questionTypeValidatorsService: QuestionTypeValidatorsService,
    private doActionRequestsService: DoActionRequestsService
  ) {
  }

  public bonusPointQuestionTypeIds: number[] = [
    QuestionTypeIds.PUZ_MATCHING,
    QuestionTypeIds.CHOOSE_TEN,
    QuestionTypeIds.PICTURE_ROUND,
    QuestionTypeIds.CHOOSE_FROM_LIST,
    QuestionTypeIds.TWO_COLUMN,
    QuestionTypeIds.ONE_COLUMN,
    QuestionTypeIds.FILL_IN_BLANK,
  ];

  public activeBonusPointQuestionTypeIds: number[] = QuestionTypeConstants.bonusQuestionTypeIds;
  public disabledQuestionTypeIds = QuestionTypeConstants.disabledQuestionTypeIds;
  public bonusPointQuestionTypes: Question_Types[] = [];

  callConfirmModal(
    modalData: ConfirmModalOptions
  ): Observable<ConfirmDialogResponse> {
    return this.matDialog
      .open(ConfirmDialogComponent, {
        data: modalData,
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        switchMap((choice: ConfirmDialogResponse) => {
          if (!choice || !choice?.choice) {
            return of(null);
          }
          if (modalData.formArray && modalData.formArray.length) {
            modalData.formArray.removeAt(modalData.index);
          }
          if (modalData.fn) {
            modalData.fn();
          }
          return of(choice);
        })
      );
  }

  public initCleanFormArray(count: number, formArray: FormArray, group: Object) {
    if (formArray.controls.length) return

    for (let i = 0; i < count; i++) {
      const formGroup = this.fb.group(group)
      this.addControl(formArray, formGroup)
    }
  }

  /**
   *
   * @param formData FormData
   * @returns number
   */
  public add_remove_items<T>(formData?: FormData<T>): number {
    const arrayLimit = 25
    let endPoint = formData.value - formData.formArray.length;
    let i = 0;
    let isRemove = false;
    if (formData.value <= 0 || formData.value === formData.formArray.length) {
      return;
    }

    if (formData.formArray.length > +formData.value) {
      endPoint = formData.formArray.length - formData.value;
      isRemove = true;
    }
    if (
      formData.formArray.length < +formData.value &&
      +formData.value > arrayLimit
    ) {
      endPoint = arrayLimit - formData.formArray.length;
      formData.value = arrayLimit;
    }
    while (i < endPoint) {
      if (isRemove) {
        formData.formArray.removeAt(formData.formArray.length - (endPoint - i));
      } else if (formData?.nested && !isRemove) {
        const formArrayLength = formData.formArray.length;
        const originalFormArrayLength = formData?.originalFormArray?.length;
        let nestedObject: T = formData.nested;
        if (!isRemove && formArrayLength < originalFormArrayLength) {
          nestedObject = formData.originalFormArray[i + 1];
        }
        const newNestedFormGroup = this.setObjectAsFormGroup(
          nestedObject,
          this.fb.group({}),
          formData.validators
        );
        formData.formArray.push(newNestedFormGroup);
      } else if (!isRemove) {
        if (
          formData.formArray.length < formData?.originalFormArray?.length &&
          formData?.originalFormArray[i + 1]
        ) {
          formData.formArray.push(
            this.fb.group(
              formData.originalFormArray[i + 1],
              formData.validators
            )
          );
        } else {
          formData.formArray.push(
            this.fb.group(formData.group, formData?.validators)
          );
        }
      }

      i++;
    }

    return formData.formArray.length;
  }

  public addControl(formArray: FormArray, group: FormGroup) {
    formArray.push(group)
  }

  public removeControl(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  private setValidatorsToNewControl(params: {
    validator: { validators: object; validatorKey: string };
    controlType: 'group' | 'control' | 'array';
    controlValue: any;
  }): AbstractControl {
    let newControl;
    switch (params.controlType) {
      case 'group':
        newControl = this.fb.group({});
        break;
      case 'array':
        newControl = this.fb.array([]);
        break;
      case 'control':
        newControl = this.fb.control(params.controlValue);
        break;
    }
    if (
      params.validator.validators &&
      params.validator.validatorKey &&
      params.validator.validators[params.validator.validatorKey]
    ) {
      newControl.setValidators(
        params.validator.validators[params.validator.validatorKey]
      );
    }
    return newControl;
  }

  generateFormArray<T>(params: GenerateFormArrayArgs<T>): FormArray {
    if (!params.array) {
      return params.parent;
    }
    let validation = null;
    Object.entries(params.array).forEach((item) => {
      this.questionTypeValidatorsService.addCommonFieldValidators(validation);
      if (ObjectHelper.isObject(item[1])) {
        params.parent.insert(
          +item[0],
          this.fb.group({}, validation?.validators)
        );

        if (
          params.add_q_json_group &&
          item[1] &&
          item[1].question_type_id >= 0 &&
          !item[1]?.question_json
        ) {
          const jsonForm =
            this.populateBlankRoundsService.createQuestionFormJson(
              item[1]?.question_type_id
            );
          if (jsonForm) {
            (params.parent.get(item[0]) as FormGroup).addControl(
              'question_json',
              this.fb.group(jsonForm)
            );
          }
        }

        return this.setObjectAsFormGroup(
          item[1],
          params.parent.controls[item[0]],
          validation?.questionTypeId === item[1].question_type_id
            ? validation?.validators
            : null
        );
      }
      if (Array.isArray(item[1])) {
        params.parent.insert(
          +item[0],
          this.fb.array([], validation?.validators)
        );
        this.generateFormArray<T>({
          array: item[1],
          parent: params.parent?.controls[item[0]].controls[item[0]],
        });
      }
      if (typeof item[1] === 'string') {
        params.parent.push(this.fb.control(item[1]));
      }
    });
    return params.parent;
  }

  setObjectAsFormGroup<T>(
    object: T,
    parentControl: any,
    validators?: object
  ): FormGroup {
    if (!object || !parentControl) {
      return parentControl;
    }
    Object.entries(object).forEach((item) => {
      if (typeof item[1] !== 'object' || item[1] === null) {
        const newControl = this.setValidatorsToNewControl({
          validator: {validators, validatorKey: item[0]},
          controlType: 'control',
          controlValue: item[1],
        });
        parentControl.addControl(item[0], newControl);
      }
      if (ObjectHelper.isObject(item[1])) {
        const newControl = this.setValidatorsToNewControl({
          validator: {validators, validatorKey: item[0]},
          controlType: 'group',
          controlValue: item[1],
        });
        parentControl.addControl(item[0], newControl);

        this.setObjectAsFormGroup(
          item[1],
          parentControl.controls[item[0]],
          validators
        );
      }
      if (Array.isArray(item[1])) {
        const newControl = this.setValidatorsToNewControl({
          validator: {validators, validatorKey: item[0]},
          controlType: 'array',
          controlValue: item[1],
        });
        parentControl.addControl(item[0], this.fb.array([], newControl));
        this.generateFormArray({
          array: item[1],
          parent: parentControl.get(item[0]),
        });
      }
    });

    return parentControl;
  }

  createForm<T>(formData: T, baseFormGroup: FormGroup): FormGroup {
    return this.setObjectAsFormGroup<T>(formData, baseFormGroup);
  }

  getFormSubscription<T>(
    questionForm: FormGroup,
    questionValues
  ): Subscription {
    let formSubscription: Subscription;
    if (questionForm) {
      formSubscription = questionForm.valueChanges
        .pipe(startWith(questionForm.getRawValue()))
        .subscribe((data: { question: T; valid: boolean }) => {
          questionValues.emit({
            question: data,
            valid: questionForm.valid,
          });
        });
    }
    return formSubscription;
  }

  public filterQuestionTypesOfDropdown(): Observable<Question_Types[]> {
    this.bonusPointQuestionTypes = [];
    return this.doActionRequestsService.questionTypes().pipe(
      map((response) => {
        const questionTypes = [];
        const e = response.filter((type) => {
          if (this.disabledQuestionTypeIds.includes(type.question_type_id)) {
            return;
          }
          if (!this.bonusPointQuestionTypeIds.includes(type.question_type_id)) {
            questionTypes.push(type);
            return type;
          } else if (
            this.bonusPointQuestionTypeIds.includes(type.question_type_id)
          ) {
            this.bonusPointQuestionTypes.push(type);
          }
        });
        console.log(e);
        return e;
      })
    );
  }

  public bonusQuestionTypes(): Observable<Question_Types[]> {
    return this.doActionRequestsService.questionTypes().pipe(
      map((question_types) => {
        return question_types.filter(
          (e) =>
            this.bonusPointQuestionTypeIds.includes(e.question_type_id) &&
            !this.disabledQuestionTypeIds.includes(e.question_type_id)
        );
      })
    );
  }
}
