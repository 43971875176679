import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { RangeDatePickerTab } from '@core/enums';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DateRangeService {
  public currentDate = moment();
  public selectedMonth: string;
  public selectedDateRange: Subject<DateRange<Date>> = new Subject();
  public day = moment();
  public week = moment();
  public year = moment();
  public range = moment();
  constructor() {}

  /**
   * converts date fact-check-range's start and end dates from moment type to js Date
   * @param data DateRange<moment.Moment>
   * @param selectedMode string
   * @returns DateRange<Date>
   */
  public setSelectedDateRange(
    data: DateRange<moment.Moment>,
  ): DateRange<Date> {
    if (data?.start && data?.end) {
      return new DateRange(data.start.toDate(), data.end.toDate());
    } else {
      return new DateRange(null, null);
    }
  }

  public areDatesEqual(dateRange: DateRange<Date>): boolean {
    return moment(dateRange.start).isSame(dateRange.end);
  }
}
